import React, {
    ReactNode
} from "react";
import {
    CircularProgress,
    makeStyles,
    Tab,
    Tabs,
} from "@material-ui/core";
import Section from "../../../DataObjects/Section";
import QuestionnaireDto from "../../../DataObjects/Questionnaire";
import {Check, ErrorOutline} from "@material-ui/icons";
import Question from "../../../DataObjects/Question";
import QuestionnaireService from "../../../Services/QuestionnaireService";
import QuestionnaireItemInterface from "../../../DataObjects/QuestionnaireItemInterface";
import IntroQuestionGroup from "../../../DataObjects/IntroQuestionGroup";
import FinalisePage from "../../../DataObjects/FinalisePage";
import SectionReview from "../../../DataObjects/SectionReview";
import QuestionObj from "../../../DataObjects/Question";
import {Page} from "../../../DataObjects/Page";

interface Props {
    questionnaire: QuestionnaireDto,
    selectedItemId: number;
    items: QuestionnaireItemInterface[],
    savingQuestions: Set<number>,
    onSectionChange?: (section: Section) => void;
    onItemChange?: (item: QuestionnaireItemInterface) => void;
    onNavigateToSectionReviewClick?: (section: Section) => void;
}

const useStyles = makeStyles({
    tabsFlexContainer: {
        alignItems: 'flex-start',
    },
    tabWrapper: {
        alignItems: 'flex-start',
    },
});

const SectionNav = (props: Props): JSX.Element => {
    const questionnaireService = new QuestionnaireService();
    const classes = useStyles();

    const items: ReactNode[] = [];

    props.questionnaire
        .getSections()
        .forEach((section: Section, index: number): void => {
            items.push(
                <Tab
                    key={`sectionNav-${index}`}
                    classes={{
                        wrapper: classes.tabWrapper,
                    }}
                    style={{
                        fontWeight: 'bold',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                    }}
                    label={section.present().getName()}
                    onClick={(e: MouseEvent): void => {onSectionClick(section);}}
                />
            );

            getSectionItemTabs(section)?.forEach((node: ReactNode): void => {
                items.push(node);
            });
        });

    function onSectionClick(section: Section): void {
        props.onSectionChange?.(section);
    }

    function onQuestionClick(question: Question): void {
        props.onItemChange?.(question);
    }

    function getSectionItemTabs(section: Section): ReactNode[]|null {
        const activePageItem = props.items[props.selectedItemId];
        if (activePageItem.getSection().getId() !== section.getId()) {
            return null;
        }

        let i=0;

        return props.items
            .filter((item: QuestionnaireItemInterface): boolean => item.getSection().getId() === section.getId())
            .filter((item: QuestionnaireItemInterface): boolean => questionnaireService.itemShouldBeShown(item, props.items, props.questionnaire))
            .map((item: QuestionnaireItemInterface): ReactNode => {
                let isAnswered = false;
                let isValid = false;
                let isLoading = false;
                let name = null;
                let icon = null;
                let clickHandler = (e: MouseEvent): void => {};

                if (item instanceof IntroQuestionGroup) {
                    name = item.present().getShortTitle();
                    const relevantItems = item.getFields()
                        .filter((question: QuestionObj): boolean => questionnaireService.introFieldShouldShowBasedOnSection(question, item.getSection()));

                    if (relevantItems.length === 0) {
                        return null;
                    }

                    isLoading = relevantItems.some((question: QuestionObj): boolean => props.savingQuestions.has(question.getId()));
                    isAnswered = relevantItems.some((question: Question): boolean => questionnaireService.checkAnswer(question).isAnswered());
                    isValid = relevantItems.every((question: Question): boolean => questionnaireService.checkAnswer(question).isValid());
                    clickHandler = (e: MouseEvent): void => {onQuestionClick(relevantItems[0]);};
                } else if (item instanceof Question) {
                    name = item.present().getTitle();
                    isAnswered = questionnaireService.checkAnswer(item).isAnswered();
                    isValid = questionnaireService.checkAnswer(item).isValid();
                    isLoading = props.savingQuestions.has(item.getId());
                    clickHandler = (e: MouseEvent): void => {onQuestionClick(item);};
                } else if (item instanceof SectionReview) {
                    name = item.present().getShortTitle();
                    isAnswered = false;
                    isValid = false;
                    clickHandler = (e: MouseEvent): void => {props.onNavigateToSectionReviewClick?.(section);};
                } else if (item instanceof FinalisePage) {
                    name = 'Overview';
                    isAnswered = false;
                    isValid = false;
                    clickHandler = (e: MouseEvent): void => {props.onNavigateToSectionReviewClick?.(section);};
                } else if (item instanceof Page) {
                    name = item.getNavTitle();
                    isAnswered = false;
                    isValid = false;
                    clickHandler = (e: MouseEvent): void => {props.onItemChange?.(item);};
                } else {
                    throw new Error(`Attempted to add unknown item to the section nav`);
                }

                if (isLoading) {
                    icon = <CircularProgress size={'16px'} />;
                } else if (isAnswered) {
                    if (isValid) {
                        icon = <Check fontSize="small" style={{color: '#ccc'}} />;
                    } else {
                        icon = <ErrorOutline fontSize="small" style={{color: '#f44336'}} />;
                    }
                }

                return (
                    <Tab
                        key={`question-nav-${i++}`}
                        classes={{
                            wrapper: classes.tabWrapper,
                        }}
                        style={{
                            fontWeight: (props.selectedItemId === props.items.indexOf(item) ? 'bold':'normal'),
                            fontSize: '80%',
                            minHeight: '30px',
                            width: '100%',
                        }}
                        onClick={clickHandler}
                        value={props.items.indexOf(item)}
                        label={(
                            <div style={{
                                display: 'flex',
                                justifyContent: 'start',
                                width: '100%',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 16,
                                    height: 16,
                                    marginRight: 8,
                                    paddingTop: 3,
                                }}>
                                    { icon }
                                </div>
                                <div style={{
                                    width: '100%',
                                    textAlign: 'left',
                                }}>
                                    { name }
                                </div>
                            </div>
                        )}
                    />
                );
            });
    }

    return (
        <div>
            <Tabs
                orientation="vertical"
                value={props.selectedItemId}
                aria-label="Section navigation"
                classes={{
                    flexContainer: classes.tabsFlexContainer,
                }}
            >
                {items}
            </Tabs>
        </div>
    );
};

export default SectionNav;
