import QuestionnaireItemInterface from "./QuestionnaireItemInterface";
import Section from "./Section";
import SectionReport from "./SectionReport";

export default class FinalisePage implements QuestionnaireItemInterface {
    protected sectionReports: SectionReport[];
    protected section: Section;

    constructor(
        sectionReports: SectionReport[],
        section: Section,
    ) {
        this.sectionReports = sectionReports;
        this.section = section;
    }

    getSectionReports(): SectionReport[] {
        return this.sectionReports;
    }

    getSection(): Section {
        return this.section;
    }

    getSort(): number {
        return 0;
    }
}
