import './App.css';
import {Component} from "react";
import {BrowserRouter, Route, RouteComponentProps, Switch} from 'react-router-dom';
import Header from "./Components/Header";
import Dashboard from "./Components/Dashboard";
import Login from "./Components/Account/Login";
import Register from "./Components/Account/Register";
import UpdateAccount from "./Components/Account/UpdateAccount";
import CloseAccount from "./Components/Account/CloseAccount";
import PrivateRoute from "./Components/PrivateRoute";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import routes from "./routes";
import Logout from "./Components/Account/Logout";
import AuthProvider from "./Components/AuthProvider";
import Feedback from "./Components/Feedback";
import {Color} from "@material-ui/lab";
import Questionnaire from "./Components/Questionnaire/Questionnaire";
import ForgotPassword from "./Components/Account/ForgotPassword";
import ResetPassword from "./Components/Account/ResetPassword";
import VerifyEmail from "./Components/Account/VerifyEmail";
import Index from "./Components/Resources/Index";
import QuestionnaireSummary from "./Components/Questionnaire/Summary";
import Footer from "./Components/Footer";

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1024, // 1280 default
            xl: 1920,
        }
    },
    palette: {
        primary: {
            main: '#2B3051', // PSA Certified Peacoat Blue
            contrastText: '#fff',
        },
        secondary: {
            main: '#083d4b', // PSA Certified Dark Blue
            contrastText: '#E0FF4F', // PSA Certified Lime Punch
        },
    },
    typography: {
        fontFamily: `Nunito,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Cantarell,Helvetica Neue,Helvetica,Arial,sans-serif;`,
        h1: {
            fontSize: '2rem',
        },
        h2: {
            fontSize: '2rem',
        },
        h3: {
            fontSize: '1.5rem',
        },
        h4: {
            fontSize: '1.2rem',
        },
        h5: {
            fontSize: '1.1rem',
        },
        h6: {
            fontSize: '1rem',
        },
        button: {
            textTransform: "capitalize",
            fontSize: "1rem",
        },
    },
});

interface Flash {
    type: Color,
    messages: string[],
}

interface State {
    flash: Flash|null;
}

class App extends Component<{}, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            flash: null,
        };
    }

    showFlash(type: Color, messages: string[]): void {
        this.setState({
            flash: {
                type: type,
                messages: messages,
            }
        });
    }

    render(): JSX.Element {
        return (
            <AuthProvider>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <Header/>
                        <Feedback currentFlash={this.state.flash} onClose={(): void => this.setState({flash: null})}/>
                        <main style={{
                            position: 'relative',
                            paddingTop: 50,
                            paddingBottom: 50,
                            overflow: 'hidden',
                        }}>
                            <Switch>
                                <Route path={routes.LOGIN} exact>
                                    <Login
                                        onError={(errors: string[]): void => this.showFlash('error', errors)}
                                        onSuccess={(message: string): void => this.showFlash('success', [message])}
                                        />
                                </Route>
                                <Route path={routes.LOGOUT} exact>
                                    <Logout onSuccess={(message: string): void => this.showFlash('success', [message])}/>
                                </Route>
                                <Route path={routes.REGISTER} exact>
                                    <Register
                                        onError={(errors: string[]): void => this.showFlash('error', errors)}
                                        onSuccess={(message: string): void => this.showFlash('success', [message])}
                                        />
                                </Route>
                                <Route path={routes.FORGOT_PASSWORD} exact>
                                    <ForgotPassword
                                        onError={(errors: string[]): void => this.showFlash('error', errors)}
                                        onSuccess={(message: string): void => this.showFlash('success', [message])}
                                    />
                                </Route>
                                <Route path={routes.RESET_PASSWORD} render={(routeProps: RouteComponentProps<any>): JSX.Element => (
                                    <ResetPassword
                                        onError={(errors: string[]): void => this.showFlash('error', errors)}
                                        onSuccess={(message: string): void => this.showFlash('success', [message])}
                                        token={routeProps.match?.params.token}
                                    />
                                )}/>
                                <PrivateRoute path={routes.VERIFY_EMAIL} exact allowUnverified>
                                    <VerifyEmail
                                        onError={(errors: string[]): void => this.showFlash('error', errors)}
                                        onSuccess={(message: string): void => this.showFlash('success', [message])}
                                    />
                                </PrivateRoute>
                                <Route
                                    path={routes.VERIFY_EMAIL_CONFIRM}
                                    render={(routeProps: RouteComponentProps<any>): JSX.Element => (
                                        <VerifyEmail
                                            onError={(errors: string[]): void => this.showFlash('error', errors)}
                                            onSuccess={(message: string): void => this.showFlash('success', [message])}
                                            data={routeProps.match?.params.data}
                                        />
                                    )}/>
                                <PrivateRoute path={routes.HOME} exact render={(routeProps: RouteComponentProps<any>): JSX.Element => (
                                    <Dashboard
                                        history={routeProps.history}
                                        onError={(errors: string[]): void => this.showFlash('error', errors)}
                                    />
                                )}/>
                                <PrivateRoute path={routes.RESOURCE_INDEX} exact>
                                    <Index
                                        onError={(errors: string[]): void => this.showFlash('error', errors)}
                                    />
                                </PrivateRoute>
                                <PrivateRoute path={routes.ACCOUNT_UPDATE} exact allowUnverified>
                                    <UpdateAccount
                                        onError={(errors: string[]): void => this.showFlash('error', errors)}
                                        onSuccess={(message: string): void => this.showFlash('success', [message])}
                                        />
                                </PrivateRoute>
                                <PrivateRoute path={routes.ACCOUNT_CLOSE} exact allowUnverified>
                                    <CloseAccount
                                        onError={(errors: string[]): void => this.showFlash('error', errors)}
                                        onSuccess={(message: string): void => this.showFlash('success', [message])}
                                        />
                                </PrivateRoute>
                                <PrivateRoute
                                    path={routes.QUESTIONNAIRE}
                                    render={(routeProps: RouteComponentProps<any>): JSX.Element => (
                                        <Questionnaire
                                            id={routeProps.match?.params.id}
                                            itemId={routeProps.match?.params.itemId}
                                            history={routeProps.history}
                                            onError={(errors: string[]): void => this.showFlash('error', errors)}
                                        />
                                    )}/>
                                <PrivateRoute
                                    path={routes.QUESTIONNAIRE_SUMMARY}
                                    render={(routeProps: RouteComponentProps<any>): JSX.Element => (
                                        <QuestionnaireSummary
                                            id={routeProps.match?.params.id}
                                            onError={(errors: string[]): void => this.showFlash('error', errors)}
                                        />
                                    )}/>
                            </Switch>
                        </main>
                        <Footer/>
                    </ThemeProvider>
                </BrowserRouter>
            </AuthProvider>
        );
    }
}

export default App;
