import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Icon,
    Typography,
} from "@material-ui/core";
import React, {
    useEffect,
    useState,
    MouseEvent,
} from "react";
import {LoadingState} from "../../Enums/LoadingState";
import {AxiosError} from "axios";
import Questionnaire from "../../DataObjects/Questionnaire";
import QuestionnaireService from "../../Services/QuestionnaireService";
import ExportButton from "./ExportButton";
import useAxiosErrorChecks from "../../Hooks/useAxiosErrorChecks";
import QuestionCertification from "../../DataObjects/QuestionCertification";
import Question from "../../DataObjects/Question";
import {getIntEnv} from "../../Config/ConfigUtils";
import QuestionnaireItemInterface from "../../DataObjects/QuestionnaireItemInterface";
import {ArrowBack} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import routes, {makeUri} from "../../routes";

interface Props {
    id: number,
    onError: (errors: string[]) => void,
    handleAxiosErrors?: (error: AxiosError) => void,
}

interface certGroup {
    certName: string,
    validQuestions: Question[],
    invalidQuestions: Question[],
}

export default function QuestionnaireSummary(props: Props): JSX.Element|null {
    const [questionnaire, setQuestionnaire] = useState<Questionnaire|null>(null);
    const [otherCertifications, setOtherCertifications] = useState<certGroup[]>([]);
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
    const {handleAxiosErrors} = useAxiosErrorChecks(props.onError);
    const history = useHistory();

    useEffect(() => {
        const questionnaireService = new QuestionnaireService();

        (async (): Promise<void> => {
            let questionnaire;

            try {
                questionnaire = await questionnaireService.loadData(props.id);
            } catch (error: any) {
                setLoadingState(LoadingState.FAILED);
                if (error.isAxiosError) {
                    handleAxiosErrors(error);
                    return;
                }

                throw error;
            }

            setLoadingState(LoadingState.COMPLETE);
            setQuestionnaire(questionnaire);
            const items = questionnaireService.generatePageItems(questionnaire);
            setOtherCertifications(generateCertificationsList(questionnaire, items));
        })();
    }, [props.id, handleAxiosErrors]);

    if (loadingState === LoadingState.LOADING) {
        return (
            <div style={{ display: 'grid', placeContent: 'center', height: '100vh' }}>
                <CircularProgress color="inherit"/>
            </div>
        );
    }

    if (questionnaire === null) {
        return null;
    }

    return (
        <Container>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h1">
                        {questionnaire.getSubmission().present().getName()}
                    </Typography>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography variant="subtitle1">
                            PSA Certified Level 1 - Version {questionnaire.getVersion().getVersionNumber()} -
                            Type: {questionnaire.getSubmission().present().getType()}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} container justify="flex-end" alignItems="flex-end">
                    <Grid item>
                        <Button
                            variant="text"
                            color="primary"
                            aria-label="Back to Questionnaire"
                            startIcon={<ArrowBack />}
                            href={
                                makeUri(routes.QUESTIONNAIRE, { id: props.id })
                            }
                            onClick={(event: MouseEvent): void => {
                                event.preventDefault();
                                history.push(
                                    makeUri(routes.QUESTIONNAIRE, { id: props.id })
                                );
                            }}
                            >
                            Back to Questionnaire
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Divider/>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card raised={true} style={{marginTop: '10px'}}>
                        <CardHeader
                            title="Summary"
                            titleTypographyProps={{variant: 'h1'}}
                        />
                        <CardContent>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <p>
                                    You have submitted enough information to satisfy the PSA Certified standard (subject to independent assessment by a lab)
                                </p>

                                <ExportButton
                                    submission={questionnaire.getSubmission()}
                                    onError={props.onError}
                                    disabled={false}
                                />
                            </div>

                            <Typography variant="h2">
                                Other Standards
                            </Typography>
                            {
                                otherCertifications.map((certMap: certGroup): JSX.Element => (
                                    <Accordion key={certMap.certName}>
                                        <AccordionSummary
                                            expandIcon={<Icon>expand_more</Icon>}
                                        >
                                            <Typography style={{flexBasis: '33%'}}>{certMap.certName}</Typography>
                                            <Typography style={{flexBasis: '33%'}}>{certMap.validQuestions.length} pass, {certMap.invalidQuestions.length} do not</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{flexBasis: '50%'}}>
                                                <h4>Passed</h4>
                                                <div>
                                                    {
                                                        certMap.validQuestions
                                                            .map((question: Question): JSX.Element => (
                                                                <div key={certMap.certName + question.getNumber()}>{question.getNumber()} - {question.present().getTitle()}</div>
                                                            ))
                                                    }
                                                </div>
                                            </div>
                                            <div style={{flexBasis: '50%'}}>
                                                <h4>Incorrect</h4>
                                                <div>
                                                    {
                                                        certMap.invalidQuestions
                                                            .map((question: Question): JSX.Element => (
                                                                <div key={certMap.certName + question.getNumber()}>{question.getNumber()} - {question.present().getTitle()}</div>
                                                            ))
                                                    }
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

function generateCertificationsList(questionnaire: Questionnaire, items: QuestionnaireItemInterface[]): certGroup[] {
    const questionnaireService = new QuestionnaireService();
    let otherCertifications: {[key: string]: certGroup} = {};

    questionnaireService
        .getApplicableQuestions(items, questionnaire)
        .forEach((question: Question): void => {
            const certs = question.getCertifications();
            certs.forEach((questionCert: QuestionCertification): void => {
                if (typeof otherCertifications[questionCert.getCertId().toString()] === 'undefined') {
                    otherCertifications[questionCert.getCertId().toString()] = {
                        certName: questionCert.getCertName(),
                        validQuestions: [],
                        invalidQuestions: [],
                    };
                }

                if(question.hasAnswer() === false) {
                    otherCertifications[questionCert.getCertId()].invalidQuestions.push(question);
                    return;
                }

                if (questionnaireService.checkPassRequirement(questionCert, question.getAnswer()).isValid()) {
                    otherCertifications[questionCert.getCertId()].validQuestions.push(question);
                } else {
                    otherCertifications[questionCert.getCertId()].invalidQuestions.push(question);
                }
            });
        });

    // Don't include PSA L1 standard
    delete otherCertifications[getIntEnv('REACT_APP_CERTIFICATION_PSA_L1')];

    return Object.values(otherCertifications);
}
