import Question, {QuestionType} from "./Question";
import Section from "./Section";

export enum SectionReportStatus {
    Incomplete = 0,
    Allowable = 1,
    Complete = 2,
}

export default class SectionReport {
    protected section: Section;
    protected unansweredQuestions: Question[];
    protected invalidQuestions: Question[];

    constructor(
        section: Section,
        unansweredQuestions: Question[],
        invalidQuestions: Question[],
    ) {
        this.section = section;
        this.unansweredQuestions = unansweredQuestions;
        this.invalidQuestions = invalidQuestions;
    }

    getSection(): Section {
        return this.section;
    }

    getStatus(): SectionReportStatus {
        if (this.unansweredQuestions.length === 0 && this.invalidQuestions.length === 1) {
            return SectionReportStatus.Allowable;
        } else if (this.unansweredQuestions.length > 0 || this.invalidQuestions.length > 0) {
            return SectionReportStatus.Incomplete;
        }

        return SectionReportStatus.Complete;
    }

    getStatusText(): string {
        const unansweredIntroQuestions = this.unansweredQuestions
            .filter((question: Question): boolean => question.getType() === QuestionType.Text);
        const invalidMainQuestions = this.invalidQuestions
            .filter((question: Question): boolean => question.getType() === QuestionType.Multi);

        if (this.unansweredQuestions.length === 0 && this.invalidQuestions.length === 1) {
            return '1 allowable incorrect answer supplied';
        } else if (invalidMainQuestions.length > 0) {
            return invalidMainQuestions.length + ' question(s) need reviewing';
        } else if (unansweredIntroQuestions.length > 0) {
            return 'Section intro questions need reviewing';
        }

        return '';
    }
}
