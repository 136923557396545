import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";
import QuestionExamplePresenter from "../Presenters/QuestionExamplePresenter";

interface FactoryProps {
    body?: string;
}

export default class QuestionExample {
    protected presenter: QuestionExamplePresenter;
    protected body?: string;

    constructor() {
        this.presenter = new QuestionExamplePresenter(this);
    }

    present(): QuestionExamplePresenter {
        return this.presenter;
    }

    static make(exampleData: FactoryProps): QuestionExample {
        const example = new this();

        example.body = exampleData.body;

        return example;
    }

    getBody(): string {
        if (typeof this.body === 'undefined') {
            throw new FieldNotDefinedError('body');
        }

        return this.body;
    }
}
