import React from 'react';
import {
    AppBar,
    Drawer,
    Grid,
    makeStyles,
    Paper,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import {questionSupplementWidth} from "../../../Config/UIConfig";

interface Props {
    children: React.ReactNode;
}

const useStyles = makeStyles({
    drawerPaper: {
        position: 'absolute',
        width: questionSupplementWidth,
    },
    drawerPaperTablet: {
        position: 'fixed',
        width: '100%',
    },
    paragraph: {
        margin: '1em 0',
    },
});

export default function Supplement(props: Props): JSX.Element {
    const theme = useTheme();
    const tabletMatch = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    if (tabletMatch) {
        return (
            <Grid item xs={12}>
                <Paper elevation={10}>
                    <AppBar position="static" color="default" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6">
                                Additional Information
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={{
                        padding: 24,
                    }}>
                        {props.children}
                    </div>
                </Paper>
            </Grid>
        );
    }

    return (
        <Drawer
            anchor="right"
            variant='permanent'
            classes={{
                paper: tabletMatch ? classes.drawerPaperTablet : classes.drawerPaper,
            }}
        >
            <AppBar position="static" color="default" elevation={0}>
                <Toolbar>
                    <Typography variant="h6">
                        Additional Information
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{
                padding: 24,
            }}>
                {props.children}
            </div>
        </Drawer>
    );
}
