import {compile} from 'path-to-regexp';

const routes: {[key: string]: string} = {
    HOME: '/',
    LOGIN: '/login',
    LOGOUT: '/logout',
    REGISTER: '/register',
    ACCOUNT: '/account',
    ACCOUNT_UPDATE: '/account',
    ACCOUNT_CLOSE: '/account/close',
    QUESTIONNAIRE: '/questionnaire/:id/item/:itemId?',
    QUESTIONNAIRE_SUMMARY: '/questionnaire/:id/summary',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/forgot-password/reset/:token',
    RESOURCE_INDEX: '/resources',
    RESOURCE: '/resources/:slug/:version?',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_EMAIL_CONFIRM: '/verify-email/:data',
};

export function makeUri(uri: string, urlParams?: Object): string {
    return compile(uri)(urlParams);
}

export default routes;
