import SectionReview from "../DataObjects/SectionReview";

export default class SectionReviewPresenter {
    protected sectionReview: SectionReview;

    constructor(sectionReview: SectionReview) {
        this.sectionReview = sectionReview;
    }

    getTitle(): string {
        return `${this.sectionReview.getSection().present().getTitle()} Summary`;
    }

    getShortTitle(): string {
        return 'Section Summary';
    }
}
