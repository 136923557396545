import React from 'react';
import { Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";

interface Props {
    onNextClick: () => void;
    onPrevClick: () => void;
}

const ChipPreamblePage = (props: Props): JSX.Element => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card raised={true} style={{marginTop: '10px'}}>
                    <CardHeader
                        title='Chip Evaluation'
                        titleTypographyProps={{variant: 'h1'}}
                    />
                    <CardContent>
                        <p style={{marginBottom: '1em'}}>
                            This section applies to the hardware and firmware that comprise the PSA-RoT that forms the Secure Processing Environment. When this section is filled by the system software provider or OEM, the answers apply only to the context in which the chip is used. For example, the developer only needs to list the cryptographic algorithms used, not all the algorithms supported by the chip.
                        </p>
                        <p style={{marginBottom: '1em'}}>
                            All questions require a justification.
                        </p>
                        <p style={{marginBottom: '1em'}}>
                            Details of alignment to security requirements from NIST and ETSI, notes to provide additional information, and examples are provided.
                        </p>

                        <Grid container direction="row-reverse" spacing={3} style={{marginTop: '15px'}}>
                            <Grid item style={{flexGrow: 1}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{float: 'right'}}
                                    onClick={props.onNextClick}
                                >
                                    Begin Chip Assessment
                                </Button>
                            </Grid>
                            <Grid item style={{flexGrow: 1}}>
                                <Button
                                    variant="contained"
                                    onClick={props.onPrevClick}
                                >
                                    Previous
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ChipPreamblePage;
