import Question, {QuestionType} from "../DataObjects/Question";

export default class QuestionPresenter {
    protected question: Question;

    constructor(question: Question) {
        this.question = question;
    }

    getTitle(): string {
        const name = this.question.getName() ?? '';
        const number = this.question.getType() === QuestionType.Multi ? this.question.getNumber() + ' ' : '';

        return number + name;
    }
}
