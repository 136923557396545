import React, {ChangeEvent, Component, FocusEvent, FormEvent, InvalidEvent} from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import userContext from '../../userContext';
import {User} from "../../userState";
import {Redirect, Link} from "react-router-dom";
import routes from "../../routes";
import {apiPost, endpoints} from "../../api";
import {withAxiosErrorChecks} from "../../Hooks/useAxiosErrorChecks";
import {AxiosError} from "axios";

interface Props {
    onError: (errors: string[]) => void,
    onSuccess: (message: string) => void,
    handleAxiosErrors?: (error: AxiosError) => void,
}

interface LoginState {
    title: string,
    email: string,
    password: string,
    submitLoading: boolean,
    emailError: string,
    passwordError: string,
}

class Login extends Component<Props, LoginState> {
    static contextType = userContext;

    constructor(props: Props) {
        super(props);

        this.state = {
            title: 'Login',
            email: '',
            password: '',
            submitLoading: false,
            emailError: '',
            passwordError: '',
        };

        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onInvalid = this.onInvalid.bind(this);
    }

    async login(): Promise<any> {
        this.setState({ submitLoading: true });

        let response;

        try {
            response = await apiPost(endpoints.LOGIN, undefined, {
                'email': this.state.email,
                'password': this.state.password,
            });
        } catch (error: any) {
            if (this.props.handleAxiosErrors) {
                this.props.handleAxiosErrors(error);
            }
            return;
        } finally {
            this.setState({ submitLoading: false });
        }

        const user: User = {
            ...this.context.user,
            email: response.data.user.email,
            firstName: response.data.user.first_name,
            lastName: response.data.user.last_name,
            token: response.data.user.apiToken,
            verified: response.data.verified,
        };
        this.context.setUser(user);

        this.props.onSuccess('Successfully logged in');
    }

    onChange(event: ChangeEvent<HTMLInputElement>): void {
        const id = event.currentTarget.id,
            value = event.currentTarget.value;

        if (id === 'email') {
            this.setState({
                'email': value,
                'emailError': '',
            });
        }

        if (id === 'password') {
            this.setState({
                'password': value,
                'passwordError': '',
            });
        }

        event.currentTarget.checkValidity();
    }

    onBlur(event: FocusEvent<HTMLInputElement>): void {
        event.currentTarget.checkValidity();
    }

    onInvalid(e: InvalidEvent<HTMLInputElement>): void {
        e.preventDefault();

        const id = e.target.id,
            validationMessage = e.target.validationMessage;

        if (id === 'email') {
            this.setState({
                'emailError': validationMessage,
            });
        }
        if (id === 'password') {
            this.setState({
                'passwordError': validationMessage,
            });
        }
    }

    render(): JSX.Element {
        if (this.context.loggedIn()) {
            return (
                <Redirect to={routes.HOME}></Redirect>
            );
        }

        const loginButton = (this.state.submitLoading) ? (
            <Button type="submit" variant="contained" color="secondary" size="medium" disabled>
                Logging in..
                <CircularProgress size={20} color="inherit" style={{marginLeft: 10}}></CircularProgress>
            </Button>
        ) : (
            <Button type="submit" variant="contained" color="secondary" size="medium">
                Login
            </Button>
        );

        return (
            <Container style={{maxWidth: 'calc(76.5625rem + 48px)'}}>
                <Grid container style={{marginTop: '35px'}}>
                    <Grid item xs={6} style={{paddingRight: '35px'}}>
                        <p style={{marginTop: '0'}}>
                            PSA Certified is a global partnership of security-conscious companies proactively building security best practices into devices at scale. Our security framework and independent third-party evaluation scheme set a new baseline for IoT and connected device security.
                        </p>
                        <p>
                            <a href="https://www.psacertified.org/getting-certified/device-manufacturer/level-1/">PSA Certified Level 1</a> is the entry-level for PSA Certified and has been adopted by over 120 products and over 70 companies. To achieve PSA Certified Level 1, you will have an independent security assessment of your PSA Certified Level 1 Questionnaire (which contains 50 security questions covering the security credentials of your silicon chip, software platform or device). You can do this offline using the PDF version of the questionnaire. Still, this online questionnaire offers more interactive features, answer examples, mapping to worldwide regulation and a helpful glossary.
                        </p>

                        <p>To get started, sign up or log in.</p>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={7} style={{padding: '25px'}}>
                            <form onSubmit={(e: FormEvent<HTMLFormElement>): void => { e.preventDefault(); this.login();}}>
                                <Typography variant="h2">Login</Typography>

                                <TextField
                                    id="email"
                                    required
                                    type="email"
                                    label="Business Email"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    onBlur={this.onBlur}
                                    onInvalid={this.onInvalid}
                                    error={this.state.emailError !== ''}
                                    helperText={this.state.emailError}
                                />

                                <TextField
                                    id="password"
                                    required
                                    type="password"
                                    label="Password"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    onBlur={this.onBlur}
                                    onInvalid={this.onInvalid}
                                    error={this.state.passwordError !== ''}
                                    helperText={this.state.passwordError}
                                />

                                { loginButton }
                            </form>
                            <Grid container>
                                <Grid item xs={6}>
                                    <p>
                                        Not registered? <Link to={routes.REGISTER}>Sign up</Link>
                                    </p>
                                    <p>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.psacertified.org/about/legal/privacy-policy/"
                                        >
                                            Privacy Policy
                                        </a>
                                    </p>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                    <p>
                                        <Link to={routes.FORGOT_PASSWORD}>Forgot password?</Link>
                                    </p>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withAxiosErrorChecks(Login);
