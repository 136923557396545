import React, {Component} from 'react';
import {
    CircularProgress,
    Container,
    Divider,
    Grid,
    isWidthDown, Snackbar, Theme,
    Typography, withStyles,
    withWidth,
} from "@material-ui/core";
import {RouteComponentProps} from "react-router-dom";
import routes, {makeUri} from "../../routes";
import SectionNav from "./partials/SectionNav";
import QuestionnaireItem from "./QuestionnaireItem";
import Section, {AlreadyCertifiedState} from "../../DataObjects/Section";
import QuestionnaireService from "../../Services/QuestionnaireService";
import Submission from "../../DataObjects/Submission";
import QuestionnaireDto from "../../DataObjects/Questionnaire";
import {AxiosError, AxiosResponse} from "axios";
import {withAxiosErrorChecks} from "../../Hooks/useAxiosErrorChecks";
import userContext from "../../userContext";
import Question from "../../DataObjects/Question";
import Version from "../../DataObjects/Version";
import QuestionnaireItemInterface from "../../DataObjects/QuestionnaireItemInterface";
import QuestionObj from "../../DataObjects/Question";
import {apiPut, endpoints} from "../../api";
import "./Questionnaire.scss";
import SectionProgress from "./partials/SectionProgress";
import {Alert} from "@material-ui/lab";
import {questionSupplementWidth} from "../../Config/UIConfig";
import {Breakpoint} from "@material-ui/core/styles/createBreakpoints";
import {getIntEnv} from "../../Config/ConfigUtils";
import {CloudDone} from "@material-ui/icons";

interface Props {
    id: number;
    itemId?: number,
    history: RouteComponentProps["history"],
    onError: (errors: string[]) => void,
    handleAxiosErrors?: (error: AxiosError) => void,
    width: Breakpoint;
    classes: any;
}

interface State {
    loading: boolean;
    currentItemIndex: number;
    savingQuestions: Set<number>;
    lastSaveTime: Date|null;
    savingQuestionState: string|null;
    questionnaire: QuestionnaireDto;
    items: QuestionnaireItemInterface[];
    showQuestionSupplement: boolean;
}

const styles = (theme: Theme): {} => ({
    loadingState: {
        backgroundColor: theme.palette.primary.main,
    },
});

class Questionnaire extends Component<Props, State> {
    static contextType = userContext;
    protected questionnaireService: QuestionnaireService;

    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            loading: true,
            currentItemIndex: (this.props.itemId ?? 1) - 1,
            savingQuestions: new Set(),
            savingQuestionState: null,
            lastSaveTime: null,
            questionnaire: new QuestionnaireDto(new Submission(), new Version(), [], []),
            items: [],
            showQuestionSupplement: (isWidthDown('md', this.props.width)) ? false : true,
        };

        this.questionnaireService = new QuestionnaireService();

        this.onNextClick = this.onNextClick.bind(this);
        this.onPrevClick = this.onPrevClick.bind(this);
        this.onQuestionsSave = this.onQuestionsSave.bind(this);
        this.onAjaxSave = this.onAjaxSave.bind(this);
        this.onAlreadyCertifiedChange = this.onAlreadyCertifiedChange.bind(this);
        this.onNavigateToItemClick = this.onNavigateToItemClick.bind(this);
        this.onNavigateToSectionClick = this.onNavigateToSectionClick.bind(this);
        this.onNavigateToSectionReviewClick = this.onNavigateToSectionReviewClick.bind(this);
        this.onFinalise = this.onFinalise.bind(this);
    }

    async componentDidMount(): Promise<void> {
        await this.loadQuestionnaireData();

        setInterval(() => {
            if (this.state.lastSaveTime === null) {
                return;
            }

            const units: {[key: string]: number} = {
                year  : 24 * 60 * 60 * 1000 * 365,
                month : 24 * 60 * 60 * 1000 * 365/12,
                day   : 24 * 60 * 60 * 1000,
                hour  : 60 * 60 * 1000,
                minute: 60 * 1000
            };

            const dateDiff = this.state.lastSaveTime?.getTime() - (new Date()).getTime();

            if (Math.abs(dateDiff) < units['minute']) {
                this.setState({
                    savingQuestionState: 'Saved just now',
                });

                return;
            }

            let relativeTimeFormat = new Intl.RelativeTimeFormat('en', {numeric: 'auto'});

            for (const unit in units) {
                if (Math.abs(dateDiff) > units[unit] || unit === 'minute') {
                    this.setState({
                        savingQuestionState: `Saved ${relativeTimeFormat.format(Math.round(dateDiff/units[unit]), unit as any)}`,
                    });

                    return;
                }
            }
        }, 1000);
    }

    // async componentWillUnmount(): void {
    //     clearInterval(this.saveInterval);
    // }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): Promise<void> {
        // Questionnaire id prop changes, load new data
        if (this.props.id !== prevProps.id) {
            await this.loadQuestionnaireData();
            return;
        }

        // Page item id prop changes, update page item index in state
        if (this.props.itemId !== prevProps.itemId) {
            this.setState({
                currentItemIndex: (this.props.itemId ?? 1) - 1,
            }, () => {
                this.checkCurrentPageShouldShow();
            });
            return;
        }

        if (this.props.width !== prevProps.width) {
            this.setState({
                showQuestionSupplement: isWidthDown('md', this.props.width) === false,
            });
        }
    }

    async loadQuestionnaireData(): Promise<void> {
        let questionnaire;

        try {
            questionnaire = await this.questionnaireService.loadData(this.props.id);
        } catch (error: any) {
            if (error.isAxiosError && this.props.handleAxiosErrors) {
                this.props.handleAxiosErrors(error);
                return;
            }

            throw error;
        }

        if (questionnaire.getSubmission().isFinalised()) {
            throw new Error(`Finalised submissions can't be edited`);
        }

        this.setState({
            loading: false,
            questionnaire: questionnaire,
            items: this.questionnaireService.generatePageItems(questionnaire),
        }, () => {
            this.checkCurrentPageShouldShow();
        });
    }

    checkCurrentPageShouldShow(): void {
        if (!this.questionnaireService.canNavToPageIndex(this.state.currentItemIndex, this.state.items, this.state.questionnaire)) {
            const newIndex = this.questionnaireService.getNextPageIndex(this.state.currentItemIndex, this.state.items, this.state.questionnaire);

            if (newIndex > this.state.items.length - 1) {
                this.navigateToPageIndex(0);
                return;
            }

            this.navigateToPageIndex(newIndex, true);
        }
    }

    onNextClick(): void {
        const newIndex = this.questionnaireService.getNextPageIndex(this.state.currentItemIndex, this.state.items, this.state.questionnaire);

        if (newIndex > this.state.items.length - 1) {
            return;
        }

        this.navigateToPageIndex(newIndex);
    }

    onPrevClick(): void {
        const newIndex = this.questionnaireService.getPreviousPageIndex(this.state.currentItemIndex, this.state.items, this.state.questionnaire);

        if (newIndex < 0) {
            return;
        }

        this.navigateToPageIndex(newIndex);
    }

    async onQuestionsSave(newQuestions: Question[]): Promise<any> {
        if (typeof newQuestions !== 'undefined') {
            let newQuestionnaire = this.questionnaireService.updateQuestionsData(this.state.questionnaire, newQuestions);

            const productNameQuestion = newQuestions.find((question: Question): boolean => question.getId() === getIntEnv('REACT_APP_VERSION_2.1_QUESTION_PRODUCT_NAME'));
            if (productNameQuestion !== undefined) {
                newQuestionnaire = this.questionnaireService.updateSubmissionName(newQuestionnaire, productNameQuestion.getAnswer().getResponse());
            }

            this.setState({
                questionnaire: newQuestionnaire,
                items: this.questionnaireService.generatePageItems(newQuestionnaire),
            }, () => {
                this.checkCurrentPageShouldShow();
            });
        }

        const answersData: {}[] = newQuestions.map((question: QuestionObj): {} => {
            return {
                question_id: question.getId(),
                ...question.getAnswer().toObject(),
            };
        });

        const questionIds = newQuestions.map((question: QuestionObj): number => question.getId());

        questionIds.forEach((questionId: number): void => {
            this.setState((oldState: State) => ({savingQuestions: oldState.savingQuestions.add(questionId)}));
        });

        const applicableQuestionIds = this.questionnaireService
            .getApplicableQuestions(this.state.items, this.state.questionnaire)
            .filter((question: Question): boolean => this.questionnaireService.checkAnswer(question).isAnswered())
            .map((question: Question): number => question.getId());

        const savePromise = apiPut(endpoints.ANSWER_SAVE, {
            submissionId: this.state.questionnaire.getSubmission().getId(),
        }, {
            answers: answersData,
            applicableQuestionIds: applicableQuestionIds,
        });

        this.onAjaxSave(savePromise, questionIds);
    }

    async onAjaxSave(savePromise: Promise<AxiosResponse>, questionIds: number[]): Promise<any> {
        this.setState({
            savingQuestionState: 'Saving...',
        });

        try {
            await savePromise;
        } catch (error: any) {
            if (this.props.handleAxiosErrors) {
                this.props.handleAxiosErrors(error);
            }
        } finally {
            const set = this.state.savingQuestions;

            questionIds.forEach((questionId: number): void => {
                set.delete(questionId);
            });

            this.setState({
                savingQuestions: set,
                savingQuestionState: 'Saved just now',
                lastSaveTime: new Date()
            });
        }
    }

    onFinalise(): void {
        this.props.history.push(makeUri(routes.QUESTIONNAIRE_SUMMARY, {id: this.state.questionnaire.getSubmission().getId()}));
    }

    onAlreadyCertifiedChange(section: Section, alreadyCertified: boolean): void {
        if (typeof section === 'undefined') {
            return;
        }

        const newSection = Section.make({
            id: section.getId(),
            type: section.getType(),
            description: section.getDescription(),
            sort: section.getSort(),
            alreadyCertifiedState: (alreadyCertified) ? AlreadyCertifiedState.Yes : AlreadyCertifiedState.No,
        });

        const sections = this.state.questionnaire.getSections(),
            sectionIndex = sections.findIndex((i: Section): boolean => i.getId() === section.getId());

        sections.splice(sectionIndex, 1, newSection);

        const newQuestionnaire = new QuestionnaireDto(
            this.state.questionnaire.getSubmission(),
            this.state.questionnaire.getVersion(),
            sections,
            this.state.questionnaire.getQuestions(),
        );

        this.setState({
            questionnaire: newQuestionnaire,
            items: this.questionnaireService.generatePageItems(newQuestionnaire),
        }, () => {
            this.checkCurrentPageShouldShow();
        });
    }

    onNavigateToItemClick(item: QuestionnaireItemInterface): void {
        const newIndex = this.questionnaireService.getPageIndex(item, this.state.items, this.state.questionnaire);
        this.navigateToPageIndex(newIndex);
    }

    onNavigateToSectionClick(section: Section): void {
        const newIndex = this.questionnaireService.getPageIndexBySection(section, this.state.items, this.state.questionnaire);
        this.navigateToPageIndex(newIndex);
    }

    onNavigateToSectionReviewClick(section: Section): void {
        const newIndex = this.questionnaireService.getReviewPageIndexBySection(section, this.state.items, this.state.questionnaire);
        this.navigateToPageIndex(newIndex);
    }

    navigateToPageIndex(newIndex: number, replace: boolean = false): void {
        const path = makeUri(routes.QUESTIONNAIRE, {
            id: this.props.id,
            itemId: (newIndex + 1)
        });

        if (replace) {
            this.props.history.replace(path);
        } else {
            this.props.history.push(path);
        }
    }

    render(): JSX.Element {
        if (this.state.loading === true || this.state.items.length === 0) {
            return (
                <div style={{ display: 'grid', placeContent: 'center', height: '100vh' }}>
                    <CircularProgress color="inherit"></CircularProgress>
                </div>
            );
        }

        return (
            <Container maxWidth={false} style={{
                paddingRight: (this.state.showQuestionSupplement) ? 'calc(' + questionSupplementWidth + ' + 24px)' : 24,
            }}>
                <Snackbar
                    open={this.state.savingQuestionState !== null}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    <Alert
                        severity='info'
                        variant='filled'
                        className={this.props.classes.loadingState}
                        icon={this.state.savingQuestions.size > 0 ? <CircularProgress size={20} color="inherit"/>:<CloudDone color="inherit"/>}
                    >
                        {this.state.savingQuestions.size > 0 ? 'Saving...':`${this.state.savingQuestionState}`}
                    </Alert>
                </Snackbar>
                <Grid container spacing={3}>
                    <Grid item xs={5} style={{paddingTop: 23}}>
                        <Typography variant="h1">
                            {this.state.questionnaire.getSubmission().present().getName()}
                        </Typography>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography variant="subtitle2">
                                PSA Certified Level 1 - Version {this.state.questionnaire.getVersion().getVersionNumber()} - Type: {this.state.questionnaire.getSubmission().present().getType()}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={7}>
                        <SectionProgress
                            questionnaire={this.state.questionnaire}
                            items={this.state.items}
                            currentItemIndex={this.state.currentItemIndex}
                            onNavigateToSectionReviewClick={this.onNavigateToSectionReviewClick}
                        />
                    </Grid>
                </Grid>
                {this.state.questionnaire.getVersion().isLatest() === false && (
                    <Grid>
                        <Grid item>
                            <Alert severity="warning">
                                This version of the questionnaire is now out of date.
                                Please complete your questionnaire as soon as possible
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <SectionNav
                            questionnaire={this.state.questionnaire}
                            selectedItemId={this.state.currentItemIndex}
                            items={this.state.items}
                            savingQuestions={this.state.savingQuestions}
                            onSectionChange={this.onNavigateToSectionClick}
                            onItemChange={this.onNavigateToItemClick}
                            onNavigateToSectionReviewClick={this.onNavigateToSectionReviewClick}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <QuestionnaireItem
                            questionnaire={this.state.questionnaire}
                            item={this.state.items[this.state.currentItemIndex]}
                            items={this.state.items}
                            firstItem={this.state.currentItemIndex === 0}
                            onQuestionsSave={this.onQuestionsSave}
                            onNextClick={this.onNextClick}
                            onPrevClick={this.onPrevClick}
                            onAlreadyCertifiedChange={this.onAlreadyCertifiedChange}
                            onNavigateToQuestionClick={this.onNavigateToItemClick}
                            onNavigateToSectionReviewClick={this.onNavigateToSectionReviewClick}
                            onFinalise={this.onFinalise}
                            onError={this.props.onError}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withWidth()(
    withStyles(styles)(
        withAxiosErrorChecks(Questionnaire)
    )
);
