import Question from "./Question";
import QuestionnaireItemInterface from "./QuestionnaireItemInterface";
import Section from "./Section";
import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";
import IntroQuestionGroupPresenter from "../Presenters/IntroQuestionGroupPresenter";

export default class IntroQuestionGroup implements QuestionnaireItemInterface{
    protected presenter: IntroQuestionGroupPresenter;
    protected fields?: Question[];
    protected section?: Section;
    protected sort?: number;

    constructor(section: Section, introFields: Question[] = []) {
        this.section = section;
        this.fields = introFields;
        this.sort = 0; // Always first

        this.presenter = new IntroQuestionGroupPresenter(this);
    }

    present(): IntroQuestionGroupPresenter {
        return this.presenter;
    }

    getFields(): Question[] {
        if (typeof this.fields === 'undefined') {
            throw new FieldNotDefinedError('fields');
        }

        return this.fields;
    }

    getSection(): Section {
        if (typeof this.section === 'undefined') {
            throw new FieldNotDefinedError('section');
        }

        return this.section;
    }

    getSort(): number {
        if (typeof this.sort === 'undefined') {
            throw new FieldNotDefinedError('sort');
        }

        return this.sort;
    }
}
