import QuestionCertification, {PassRequirementType} from "../DataObjects/QuestionCertification";
import {InvalidCertificationPassRequirementError} from "../Errors/InvalidCertificationPassRequirementError";
import React from "react";

export default class QuestionCertificationPresenter {
    protected questionCertification: QuestionCertification;

    constructor(questionCertification: QuestionCertification) {
        this.questionCertification = questionCertification;
    }

    getNotes(): JSX.Element[]|JSX.Element {
        const notes = this.questionCertification.getNotes();

        if (notes === null) {
           return (
               <span>No additional notes</span>
           );
        }

        return notes.split("\n").map((paragraph: string) => {
            const lines = paragraph
                .split("\n")
                .map((line: string): JSX.Element => (<>{line}<br/></>));

            return <p>{lines}</p>;
        });
    }

    getPassRequirement(): string {
        const passRequirement = this.questionCertification.getPassRequirement();

        switch (passRequirement) {
            case PassRequirementType.Yes:
                return 'Yes';
            case PassRequirementType.NotPartial:
                return 'Yes or N/A';
            case PassRequirementType.Any:
                return 'Any answer';
            default:
                throw new InvalidCertificationPassRequirementError(passRequirement);
        }
    }
}
