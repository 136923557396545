import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";
import InvalidArgumentError from "../Errors/InvalidArgumentError";

export default class AnswerCheck {
    protected answered: boolean;
    protected valid: boolean;
    protected problemMessage?: string;

    constructor(
        answered: boolean,
        valid: boolean,
        problemMessage?: string,
    ) {
        if (!valid && typeof problemMessage === 'undefined') {
            throw new InvalidArgumentError('Must provide a message if the answer is invalid');
        }

        this.answered = answered;
        this.valid = valid;
        this.problemMessage = problemMessage;
    }

    isAnswered(): boolean {
        return this.answered;
    }

    isValid(): boolean {
        return this.valid;
    }

    getProblemMessage(): string {
        if (typeof this.problemMessage === 'undefined') {
            throw new FieldNotDefinedError('problemMessage');
        }

        return this.problemMessage;
    }
}
