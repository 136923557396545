import React, {
    MouseEvent,
    useEffect,
} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Icon,
    IconButton,
} from "@material-ui/core";
import {
    Alert,
    AlertTitle
} from "@material-ui/lab";
import SectionReviewObj from "../../DataObjects/SectionReview";
import Question from "../../DataObjects/Question";
import QuestionnaireService from "../../Services/QuestionnaireService";
import routes, {makeUri} from "../../routes";
import QuestionnaireItemInterface from "../../DataObjects/QuestionnaireItemInterface";
import Questionnaire from "../../DataObjects/Questionnaire";
import Supplement from "./partials/Supplement";
import IntroQuestionGroup from "../../DataObjects/IntroQuestionGroup";

interface Props {
    sectionReview: SectionReviewObj;
    onNextClick: () => void;
    onPrevClick: () => void;
    onNavigateToQuestionClick: (question: Question) => void;
    questionnaire: Questionnaire;
    items: QuestionnaireItemInterface[];
}

export default function SectionReview(props: Props): JSX.Element {
    const firstErrorRef = React.createRef<HTMLAnchorElement>();
    const nextButtonRef = React.createRef<HTMLButtonElement>();

    const questionnaireService = new QuestionnaireService();

    const items = props.sectionReview.getItems();
    const questions: Question[] = items
        .filter((item: QuestionnaireItemInterface): boolean => item instanceof Question) as Question[];
    const introGroups: IntroQuestionGroup[] = items
        .filter((item: QuestionnaireItemInterface): boolean => item instanceof IntroQuestionGroup) as IntroQuestionGroup[];

    function onEditClick(question: Question): void {
        props.onNavigateToQuestionClick(question);
    }

    useEffect(() => {
        if (firstErrorRef.current !== null) {
            firstErrorRef.current.focus();
        }
        if (nextButtonRef.current !== null) {
            nextButtonRef.current.focus();
        }
    }, [
        props.sectionReview,
        firstErrorRef,
        nextButtonRef,
    ]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card raised={true} style={{marginTop: '10px'}}>
                    <CardHeader
                        title={`${props.sectionReview.present().getTitle()}`}
                        titleTypographyProps={{variant: 'h1'}}
                    />
                    <CardContent>
                        {(questions.length === 0 && introGroups.length === 0) && (
                            <Alert severity="success" style={{marginBottom: 10}}>
                                All questions answered without issue
                            </Alert>
                        )}

                        {(questions.length === 1) && (<h2>{ questions.length } Question Needs Reviewing:</h2>)}
                        {(questions.length > 1) && (<h2>{ questions.length } Questions Need Reviewing:</h2>)}
                        {questions.map((question: Question, index: number): JSX.Element => (
                            <Alert
                                severity="error"
                                style={{marginBottom: 10}}
                                key={question.getId()}
                                action={
                                    <IconButton
                                        style={{marginRight: 16}}
                                        color="inherit"
                                        size="small"
                                        href={makeUri(routes.QUESTIONNAIRE, {
                                            id: props.questionnaire.getSubmission().getId(),
                                            itemId: questionnaireService.getPageIndex(question, props.items, props.questionnaire) + 1,
                                        })}
                                        ref={(index === 0) ? firstErrorRef : undefined}
                                        onClick={(e: MouseEvent<HTMLAnchorElement>): void => {
                                            e.preventDefault();
                                            onEditClick(question);
                                        }}
                                    >
                                        <Icon>edit</Icon>
                                    </IconButton>
                                }
                            >
                                <AlertTitle>{question.present().getTitle()}</AlertTitle>
                                Problem: {questionnaireService.checkAnswer(question).getProblemMessage()}
                            </Alert>
                        ))}

                        {introGroups.map((introGroup: IntroQuestionGroup, groupIndex: number): JSX.Element => (
                            <>
                                <h2>'{introGroup.present().getShortTitle()}' Needs Reviewing:</h2>
                                {introGroup.getFields().map((question: Question, index: number): JSX.Element => (
                                    <Alert
                                        severity="error"
                                        style={{marginBottom: 10}}
                                        key={question.getId()}
                                        action={
                                            <IconButton
                                                style={{marginRight: 16}}
                                                color="inherit"
                                                size="small"
                                                href={makeUri(routes.QUESTIONNAIRE, {
                                                    id: props.questionnaire.getSubmission().getId(),
                                                    itemId: questionnaireService.getPageIndex(question, props.items, props.questionnaire) + 1,
                                                })}
                                                ref={(questions.length === 0 && groupIndex === 0 && index === 0) ? firstErrorRef : undefined}
                                                onClick={(e: MouseEvent<HTMLAnchorElement>): void => {
                                                    e.preventDefault();
                                                    onEditClick(question);
                                                }}
                                            >
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        }
                                    >
                                        <AlertTitle>{question.present().getTitle()}</AlertTitle>
                                        Problem: {questionnaireService.checkAnswer(question).getProblemMessage()}
                                    </Alert>
                                ))}
                            </>
                        ))}

                        <Grid container direction="row-reverse" spacing={3} style={{marginTop: '15px'}}>
                            <Grid item style={{flexGrow: 1}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{float: 'right'}}
                                    onClick={props.onNextClick}
                                    ref={(questions.length === 0 && introGroups.length === 0) ? nextButtonRef : undefined}
                                >
                                    Next Section
                                </Button>
                            </Grid>
                            <Grid item style={{flexGrow: 1}}>
                                <Button
                                    variant="contained"
                                    onClick={props.onPrevClick}
                                >
                                    Previous Question
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Supplement>
                <p>Here is a summary of all questions that still need reviewing</p>
                <p>All intro questions are required</p>
                <p>One unexpected answer (according to the PSA Certified Level 1 standard) is allowed per section</p>
            </Supplement>
        </Grid>
    );
}
