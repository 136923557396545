import React, {useState} from 'react';
import {Button, CircularProgress, Menu, MenuItem,} from "@material-ui/core";
import {apiGet, endpoints} from "../../api";
import Submission from "../../DataObjects/Submission";
import useAxiosErrorChecks from "../../Hooks/useAxiosErrorChecks";
import {CloudDownloadOutlined, ErrorOutline} from "@material-ui/icons";
import {LoadingState} from "../../Enums/LoadingState";

interface Props {
    submission: Submission,
    onError: (errors: string[]) => void,
    disabled: boolean,
}

const ExportButton = function(props: Props): JSX.Element {
    const [exportLinksLoadingState, setExportLinksLoadingState] = useState(LoadingState.LOADING as LoadingState);
    const [exportLinks, setExportLinks] = useState({} as {[format: string]: string});
    const [exportLinksMenuTarget, setExportLinksMenuTarget] = useState(null as HTMLElement|null);
    const {handleAxiosErrors} = useAxiosErrorChecks(props.onError);

    async function fetchExportLinks(): Promise<void> {
        if (props.disabled) {
            return;
        }

        setExportLinksLoadingState(LoadingState.LOADING);
        let response;

        try {
            response = await apiGet(endpoints.SUBMISSION_EXPORTS, {
                id: props.submission.getId(),
            });
        } catch (error: any) {
            setExportLinksLoadingState(LoadingState.FAILED);
            handleAxiosErrors(error);
            return;
        }

        setExportLinksLoadingState(LoadingState.COMPLETE);
        setExportLinks(response.data);
    }

    function renderDownloadLinks(): JSX.Element[] {
        return Object.entries(exportLinks).map(([format, url]): JSX.Element => {
            return (
                <MenuItem>
                    <a href={url} download>
                        Format: { format.toUpperCase() }
                    </a>
                </MenuItem>
            );
        });
    }

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                disabled={props.disabled}
                aria-label="download"
                startIcon={<CloudDownloadOutlined />}
                onClick={(event): void => {fetchExportLinks(); setExportLinksMenuTarget(event.currentTarget);}}
            >
                Download Questionnaire
            </Button>
            <Menu
                id="download-format-menu"
                keepMounted
                open={exportLinksMenuTarget !== null}
                onClose={(): void => {setExportLinksMenuTarget(null);}}
                anchorEl={exportLinksMenuTarget}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                { exportLinksLoadingState === LoadingState.LOADING &&
                <div style={{ display: 'grid', placeContent: 'center', margin: '10px' }}>
                    <CircularProgress color="inherit" size={20} />
                </div>
                }
                { exportLinksLoadingState === LoadingState.FAILED &&
                <div style={{ display: 'grid', placeContent: 'center', margin: '10px' }}>
                    <ErrorOutline color="error" style={{ fontSize: 20 }} />
                </div>
                }
                { exportLinksLoadingState === LoadingState.COMPLETE && renderDownloadLinks() }
            </Menu>
        </>
    );
};

export default ExportButton;
