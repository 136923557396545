import React from 'react';
import { Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";

interface Props {
    onNextClick: () => void;
    onPrevClick: () => void;
}

const BestPracticePreamblePage = (props: Props): JSX.Element => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card raised={true} style={{marginTop: '10px'}}>
                    <CardHeader
                        title='Organizational Best Practice'
                        titleTypographyProps={{variant: 'h1'}}
                    />
                    <CardContent>
                        <p style={{marginBottom: '1em'}}>
                            In addition to the technical security measures that are in the scope of PSA Certified Level 1, there are two organizational best practices that contribute to comprehensive device security. These are collated from EN 303 645, NIST 8259A, SB-327 and UK DCMS.
                        </p>
                        <p style={{marginBottom: '1em'}}>
                            The organizational best practices in this section reflect common requirements that appear in many standards and are, or are likely to become, legal requirements in many territories. Verification of compliance to these organizational best practices by the evaluation laboratory during a PSA Certified Level 1 evaluation is optional but recommended.
                        </p>

                        <Grid container direction="row-reverse" spacing={3} style={{marginTop: '15px'}}>
                            <Grid item style={{flexGrow: 1}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{float: 'right'}}
                                    onClick={props.onNextClick}
                                >
                                    Begin Best Practice Assessment
                                </Button>
                            </Grid>
                            <Grid item style={{flexGrow: 1}}>
                                <Button
                                    variant="contained"
                                    onClick={props.onPrevClick}
                                >
                                    Previous
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default BestPracticePreamblePage;
