import React, {Component, ReactNode} from 'react';
import {Chip, Tooltip} from "@material-ui/core";
import QuestionObj from "../../../DataObjects/Question";
import QuestionCertificationObj from "../../../DataObjects/QuestionCertification";

interface Props {
    question: QuestionObj;
}

interface State {
}

class QuestionCertifications extends Component<Props, State> {
    render(): JSX.Element {
        const certifications = this.props.question
            .getCertifications()
            .map((cert: QuestionCertificationObj, index: number): ReactNode => {
                return (
                    <Tooltip
                        title={<span style={{fontSize: '1.3em'}}>{cert.present().getNotes()}</span>}
                        key={index}
                    >
                        <Chip
                            size="small"
                            label={cert.getCertName()}
                            color="secondary"
                            style={{marginLeft: '10px'}}
                        />
                    </Tooltip>
                );
            });

        return (
            <div style={{marginBottom: '15px'}}>
                Alignment to: {certifications}
            </div>
        );
    }
}

export default QuestionCertifications;
