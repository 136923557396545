import React, {Component} from "react";
import {Box} from "@material-ui/core";

interface Props {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface State {
}

class TabPanel extends Component<Props, State> {
    render(): JSX.Element {
        const {children, value, index, ...other} = this.props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
}

export default TabPanel;