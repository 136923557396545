import React from 'react';
import { Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";

interface Props {
    onNextClick: () => void;
    onPrevClick: () => void;
}

const SoftwarePreamblePage = (props: Props): JSX.Element => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card raised={true} style={{marginTop: '10px'}}>
                    <CardHeader
                        title='System Software Evaluation'
                        titleTypographyProps={{variant: 'h1'}}
                    />
                    <CardContent>
                        <p style={{marginBottom: '1em'}}>
                            This section applies to the software executing in the Non-secure Processing Environment (NSPE).
                        </p>
                        <p style={{marginBottom: '1em'}}>
                            When this section is filled by the system software provider, it is acceptable to answer ‘Yes’ to those requirements where the vendor provides the ability for the OEM to configure the device to meet the requirement. This situation arises where the system OEM, and not the software vendor, is responsible for the deployed configuration. The system software vendor should state that this is the case as the answer to the requirement.
                        </p>
                        <p style={{marginBottom: '1em'}}>
                            When this section is filled in by the OEM, the provided answers apply only to the context in which the system software is used. For instance, the OEM may only provide the cryptographic algorithms that are used, not all the algorithms supported by the system software.
                        </p>
                        <p style={{marginBottom: '1em'}}>
                            All questions require a justification.
                        </p>
                        <p style={{marginBottom: '1em'}}>
                            Details of alignment to security requirements from NIST and ETSI, notes to provide additional information, and example answers are provided.
                        </p>

                        <Grid container direction="row-reverse" spacing={3} style={{marginTop: '15px'}}>
                            <Grid item style={{flexGrow: 1}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{float: 'right'}}
                                    onClick={props.onNextClick}
                                >
                                    Begin System Software Assessment
                                </Button>
                            </Grid>
                            <Grid item style={{flexGrow: 1}}>
                                <Button
                                    variant="contained"
                                    onClick={props.onPrevClick}
                                >
                                    Previous
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default SoftwarePreamblePage;
