import {
    Button,
    Card,
    CardContent, Checkbox,
    CircularProgress,
    Container, FormControl, FormControlLabel,
    TextField,
    Typography
} from "@material-ui/core";
import React, {ChangeEvent, Component, FocusEvent, FormEvent} from "react";
import AccountNav from "./partials/AccountNav";
import {apiGet, apiPost, endpoints} from "../../api";
import {User} from "../../userState";
import userContext from "../../userContext";
import zxcvbn from "zxcvbn";
import {withAxiosErrorChecks} from "../../Hooks/useAxiosErrorChecks";
import {AxiosError} from "axios";

interface Props {
    onError: (errors: string[]) => void,
    onSuccess: (message: string) => void,
    handleAxiosErrors?: (error: AxiosError) => void,
}

interface Errors {
    firstName: string,
    lastName: string,
    password: string,
    confirmPassword: string,
}

interface State {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmPassword: string,
    marketingOptIn: boolean,
    loading: boolean,
    submitLoading: boolean,
    errors: Errors,
}

class UpdateAccount extends Component<Props, State> {
    static contextType = userContext;
    private readonly confirmPasswordRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            marketingOptIn: false,
            loading: true,
            submitLoading: false,
            errors: {
                firstName: '',
                lastName: '',
                password: '',
                confirmPassword: '',
            },
        };

        this.confirmPasswordRef = React.createRef();

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onFieldBlur = this.onFieldBlur.bind(this);
        this.onFieldInvalid = this.onFieldInvalid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount(): Promise<any> {
        let response;

        try {
            response = await apiGet(endpoints.USER_DETAILS);
        } catch (error: any) {
            if (this.props.handleAxiosErrors) {
                this.props.handleAxiosErrors(error);
            }
            return;
        } finally {
            this.setState({ loading: false });
        }

        this.setState({
            firstName: response.data.user.first_name,
            lastName: response.data.user.last_name,
            email: response.data.user.email,
            marketingOptIn: response.data.user.marketing_opt_in,
        });
    }

    async onSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();

        this.setState({ submitLoading: true });

        let data: any = {
            'firstName': this.state.firstName,
            'lastName': this.state.lastName,
            'marketing_opt_in': this.state.marketingOptIn,
        };

        if (this.state.password !== '') {
            data.password = this.state.password;
            data.password_confirmation = this.state.confirmPassword;
        }

        let response;

        try {
            response = await apiPost(endpoints.USER_DETAILS, undefined, data);
        } catch (error: any) {
            this.setState({submitLoading: false});

            if (this.props.handleAxiosErrors) {
                this.props.handleAxiosErrors(error);
            }
            return;
        }

        const user: User = {
            ...this.context.user,
            email: response.data.email,
            firstName: response.data.first_name,
            lastName: response.data.last_name,
        };
        this.context.setUser(user);

        this.props.onSuccess('Account details updated');

        this.setState({
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            marketingOptIn: response.data.marketing_opt_in,
            submitLoading: false,
        });
    }

    onFieldChange(e: ChangeEvent<HTMLInputElement>): void {
        const element = e.currentTarget;
        const newState: State = {
            ...this.state,
        };

        switch (element.id) {
            case 'firstName':
                newState.firstName = element.value;
                newState.errors.firstName = '';
                break;
            case 'lastName':
                newState.lastName = element.value;
                newState.errors.lastName = '';
                break;
            case 'password':
                newState.password = element.value;
                newState.errors.password = '';
                newState.errors.confirmPassword = '';
                break;
            case 'confirmPassword':
                newState.confirmPassword = element.value;
                newState.errors.confirmPassword = '';
                break;
            case 'marketingOptIn':
                newState.marketingOptIn = element.checked;
                break;
            default:
                throw new Error(`Unknown field: ${element.id}`);
        }

        this.setState(newState, () => {
            this.validateField(element);
        });
    }

    onFieldBlur(e: FocusEvent<HTMLInputElement>): void {
        this.validateField(e.target);
    }

    validateField(inputField: HTMLInputElement): void {
        if (inputField.id === 'password') {
            this.validatePasswordStrength(inputField);

            const confirmField = this.confirmPasswordRef.current?.querySelector('input[type=password]') as HTMLInputElement;
            if (confirmField && this.state.confirmPassword !== '') {
                this.validateField(confirmField);
            }
        }

        if (inputField.id === 'confirmPassword') {
            this.validatePasswordsMatch(inputField);
        }

        inputField.checkValidity();
    }

    validatePasswordStrength(inputField: HTMLInputElement): void {
        if (this.state.password === '') {
            inputField.setCustomValidity('');
            return;
        }

        const result = zxcvbn(this.state.password);
        if (result.score < 3) {
            let error = "Password too weak";
            error += (result.feedback.warning) ? ': ' + result.feedback.warning : '';
            inputField.setCustomValidity(error);
        } else {
            inputField.setCustomValidity('');
        }
    }

    validatePasswordsMatch(confirmField: HTMLInputElement): void {
        if (this.state.confirmPassword !== this.state.password) {
            confirmField.setCustomValidity("Passwords don't match");
        } else {
            confirmField.setCustomValidity('');
        }
    }

    onFieldInvalid(e: FormEvent<HTMLInputElement>): void {
        e.preventDefault();

        const errors = this.state.errors;

        // @ts-ignore
        errors[e.target.id] = e.target.validationMessage;

        this.setState(oldState => {
            return {
                ...oldState,
                errors: errors,
            };
        });
    }

    render(): JSX.Element {
        if (this.state.loading === true) {
            return (
                <Container>
                    <AccountNav/>
                    <Card>
                        <CardContent>
                            <div style={{display: 'grid', placeContent: 'center'}}>
                                <CircularProgress />
                            </div>
                        </CardContent>
                    </Card>
                </Container>
            );
        }

        const submitButton = (this.state.submitLoading) ? (
            <Button type="submit" variant="contained" color="secondary" size="medium" disabled>
                Updating..
                <CircularProgress size={20} color="inherit" style={{marginLeft: 10}}></CircularProgress>
            </Button>
        ) : (
            <Button type="submit" variant="contained" color="secondary" size="medium">
                Update
            </Button>
        );

        return (
            <Container>
                <AccountNav/>
                <Card>
                    <CardContent>
                        <form onSubmit={this.onSubmit}>
                            <Typography variant="h2">Update Account</Typography>

                            <TextField
                                required
                                id="firstName"
                                label="First Name"
                                type="text"
                                fullWidth
                                margin="normal"
                                value={this.state.firstName}
                                onChange={this.onFieldChange}
                                onBlur={this.onFieldBlur}
                                onInvalid={this.onFieldInvalid}
                                error={this.state.errors.firstName !== ''}
                                helperText={this.state.errors.firstName}
                            />

                            <TextField
                                required
                                id="lastName"
                                label="Last Name"
                                type="text"
                                fullWidth
                                margin="normal"
                                value={this.state.lastName}
                                onChange={this.onFieldChange}
                                onBlur={this.onFieldBlur}
                                onInvalid={this.onFieldInvalid}
                                error={this.state.errors.lastName !== ''}
                                helperText={this.state.errors.lastName}
                            />

                            <TextField
                                label="Business Email"
                                type="text"
                                fullWidth
                                margin="normal"
                                value={this.state.email}
                                disabled
                            />

                            <TextField
                                id="password"
                                label="Change Password"
                                type="password"
                                fullWidth
                                margin="normal"
                                value={this.state.password}
                                onChange={this.onFieldChange}
                                onBlur={this.onFieldBlur}
                                onInvalid={this.onFieldInvalid}
                                error={this.state.errors.password !== ''}
                                helperText={this.state.errors.password}
                            />

                            <TextField
                                id="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                fullWidth
                                margin="normal"
                                ref={this.confirmPasswordRef}
                                value={this.state.confirmPassword}
                                onChange={this.onFieldChange}
                                onBlur={this.onFieldBlur}
                                onInvalid={this.onFieldInvalid}
                                error={this.state.errors.confirmPassword !== ''}
                                helperText={this.state.errors.confirmPassword}
                            />

                            <FormControl
                                fullWidth
                                margin="normal"
                                onBlur={this.onFieldBlur}
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                        id="marketingOptIn"
                                        checked={this.state.marketingOptIn}
                                        onChange={this.onFieldChange}
                                    />}
                                    label={(
                                        <span>
                                    By ticking this box you indicate your consent to receiving marketing
                                    communications from PSA Certified and you confirm you have read and accept
                                    our <a
                                            target="_blank"
                                            rel="noreferrer"
                                            onBlur={(e: FocusEvent): void => e.stopPropagation()}
                                            href="https://www.psacertified.org/about/legal/privacy-policy/"
                                        >
                                        Privacy Policy</a>.
                                    The data captured by PSA Certified is currently owned by Arm Ltd.
                                </span>
                                    )}
                                />
                            </FormControl>

                            {submitButton}
                        </form>
                    </CardContent>
                </Card>
            </Container>
        );
    }
}

export default withAxiosErrorChecks(UpdateAccount);
