import React from "react";
import {Step, StepButton, Stepper} from "@material-ui/core";
import Section from "../../../DataObjects/Section";
import QuestionnaireDto from "../../../DataObjects/Questionnaire";
import {Check, ErrorOutline} from "@material-ui/icons";
import Question, {QuestionType} from "../../../DataObjects/Question";
import QuestionnaireService from "../../../Services/QuestionnaireService";
import QuestionnaireItemInterface from "../../../DataObjects/QuestionnaireItemInterface";

interface Props {
    questionnaire: QuestionnaireDto,
    items: QuestionnaireItemInterface[],
    currentItemIndex: number,
    onNavigateToSectionReviewClick: (section: Section) => void,
}

const SectionProgress = (props: Props): JSX.Element => {
    const questionnaireService = new QuestionnaireService();

    function renderItems(): JSX.Element[] {
        return props.questionnaire.getSections()
            .map((section: Section): JSX.Element => {
                const sectionQuestions = questionnaireService
                    .getApplicableQuestions(props.items, props.questionnaire)
                    .filter((question: Question): boolean => question.getSection().getId() === section.getId());
                const answeredQuestions = sectionQuestions
                    .filter((question: Question): boolean => questionnaireService.checkAnswer(question).isAnswered());
                const invalidQuestions = sectionQuestions
                    .filter((question: Question): boolean => !questionnaireService.checkAnswer(question).isValid());
                const validQuestions = sectionQuestions
                    .filter((question: Question): boolean => questionnaireService.checkAnswer(question).isValid());

                const validMultiQuestionsCount = validQuestions
                    .filter((question: Question): boolean => question.getType() === QuestionType.Multi)
                    .length;
                const totalMultiQuestionsCount = sectionQuestions
                    .filter((question: Question): boolean => question.getType() === QuestionType.Multi)
                    .length;

                let icon = <div className="sectionProgressIcon" style={{color: 'rgba(0,0,0,0.12)'}}>•</div>;

                const sectionIsTouched = answeredQuestions.length > 0;
                const sectionIsActive = props.items[props.currentItemIndex].getSection().getId() === section.getId();
                const sectionHasProblems = invalidQuestions.length > 0;

                if (sectionIsTouched) {
                    if (!sectionHasProblems) {
                        icon = <Check classes={{root: 'sectionProgressIcon'}}
                                      style={{fontSize: '1.3em', color: '#083d4b'}}/>;
                    } else {
                        icon = <ErrorOutline classes={{root: 'sectionProgressIcon'}} fontSize="small"
                                             style={{fontSize: '1.3em', color: '#f44336'}}/>;
                    }
                }

                if (sectionIsActive) {
                    icon = <div className="sectionProgressIcon" style={{color: '#083d4b'}}>•</div>;
                }

                return <Step key={`sectionProgress-${section.getId()}`} active={sectionIsActive} disabled={false}>
                    <StepButton
                        icon={icon}
                        onClick={(e): void => {
                            props.onNavigateToSectionReviewClick(section);
                        }}
                        disableTouchRipple
                    >
                        {section.present().getName()}<br/>
                        {(totalMultiQuestionsCount > 0) && `${validMultiQuestionsCount}/${totalMultiQuestionsCount}`}
                    </StepButton>
                </Step>;
            });
    }

    return (
        <Stepper alternativeLabel style={{padding: 0, backgroundColor: 'transparent'}}>
            { renderItems() }
        </Stepper>
    );
};

export default SectionProgress;
