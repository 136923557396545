import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography
} from "@material-ui/core";
import React, {ChangeEvent, Component, FormEvent} from "react";
import AccountNav from "./partials/AccountNav";
import {apiPost, endpoints} from "../../api";
import userContext from "../../userContext";
import {withAxiosErrorChecks} from "../../Hooks/useAxiosErrorChecks";
import {AxiosError} from "axios";

interface Props {
    onError: (errors: string[]) => void,
    onSuccess: (message: string) => void,
    handleAxiosErrors?: (error: AxiosError) => void,
}

interface State {
    dialogOpen: boolean,
    password: string,
    submitLoading: boolean,
}

class CloseAccount extends Component<Props, State> {
    static contextType = userContext;

    constructor(props: any) {
        super(props);

        this.state = {
            dialogOpen: false,
            password: '',
            submitLoading: false,
        };

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    openDialog(): void {
        this.setState({dialogOpen: true});
    }

    closeDialog(): void {
        this.setState({dialogOpen: false});
    }

    async onSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();

        this.setState({submitLoading: true});

        try {
            await apiPost(endpoints.ACCOUNT_CLOSE, undefined, {
                password: this.state.password,
            });
        } catch (error: any) {
            if (this.props.handleAxiosErrors) {
                this.props.handleAxiosErrors(error);
            }
            return;
        } finally {
            this.setState({ submitLoading: false});
        }

        this.context.logOut();
        this.props.onSuccess('Account Closed');
    }

    render(): JSX.Element {
        let submitButton;

        if (this.state.password === '') {
            submitButton = (
                <Button type="submit" variant="contained" color="secondary" size="medium" disabled>
                    Close Account
                </Button>
            );
        } else if (this.state.submitLoading) {
            submitButton = (
                <Button type="submit" variant="contained" color="secondary" size="medium" disabled>
                    Closing Account..
                    <CircularProgress size={20} color="inherit" style={{marginLeft: 10}}></CircularProgress>
                </Button>
            );
        } else {
            submitButton = (
                <Button type="submit" variant="contained" color="secondary" size="medium">
                    Close Account
                </Button>
            );
        }

        return (
            <Container>
                <AccountNav/>
                <Card>
                    <CardContent>
                        <Typography variant="h2">Close Account</Typography>

                        <p>To completely delete your account, including all questionnaires click below</p>

                        <Button variant="contained" color="secondary" size="medium" onClick={this.openDialog}>
                            Close Account
                        </Button>
                    </CardContent>
                </Card>

                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <form onSubmit={this.onSubmit}>
                        <DialogTitle>{"Close Account"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to close your account? <br/>
                                This will remove all your questionnaires
                            </DialogContentText>

                            <TextField
                                id="password"
                                type="password"
                                label="Type your password to confirm"
                                fullWidth
                                margin="normal"
                                value={this.state.password}
                                onChange={(event: ChangeEvent<HTMLInputElement>): void => this.setState({password: event.currentTarget.value})}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button type="button" onClick={this.closeDialog} color="secondary">
                                Cancel
                            </Button>
                            {submitButton}
                        </DialogActions>
                    </form>
                </Dialog>
            </Container>
        );
    }
}

export default withAxiosErrorChecks(CloseAccount);
