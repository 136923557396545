import {Snackbar} from "@material-ui/core";
import {Alert, Color} from "@material-ui/lab";
import React, {Component} from "react";

interface Flash {
    type: Color,
    messages: string[],
}

interface Props {
    currentFlash: Flash|null,
    onClose: () => void,
}

class Feedback extends Component<Props, {}> {
    render(): JSX.Element | null {
        if (this.props.currentFlash === null) {
            return null;
        }

        const errors = this.props.currentFlash.messages.map((message, i) => (<div key={i}>{message}</div>));

        return (
            <Snackbar
                open={true}
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                onClose={this.props.onClose}
                >
                <Alert
                    severity={this.props.currentFlash.type}
                    onClose={this.props.onClose}
                    >
                    {errors}
                </Alert>
            </Snackbar>
        );
    }
}

export default Feedback;
