import React, {useState} from 'react';
import {Button, CircularProgress} from "@material-ui/core";
import {apiPost, endpoints} from "../../../api";
import useAxiosErrorChecks from "../../../Hooks/useAxiosErrorChecks";
import {AxiosError} from "axios";
import {SubmissionType} from "../../../DataObjects/Submission";

interface Props {
    type: SubmissionType|null,
    onError: (errors: string[]) => void,
    onSuccess: (submissionId: number) => void,
    handleAxiosErrors?: (error: AxiosError) => void,
    children?: React.ReactNode,
}

const CreateQuestionnaireButton = (props: Props): JSX.Element => {
    const {handleAxiosErrors} = useAxiosErrorChecks(props.onError);
    const [loading, setLoading] = useState<boolean>(false);

    async function createSubmission(): Promise<any> {
        setLoading(true);

        let response;

        try {
            response = await apiPost(endpoints.SUBMISSION_CREATE, undefined, {
                type: props.type,
            });
        } catch (error: any) {
            handleAxiosErrors(error);
            return;
        } finally {
            setLoading(false);
        }

        props.onSuccess(response.data.id);
    }

    const loadingSpinner = (loading) ? (
        <CircularProgress size={20} color="inherit" style={{marginLeft: 10}}></CircularProgress>
    ) : null;

    return (
        <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={createSubmission}
            disabled={loading || props.type === null}
        >
            {props.children}
            {loadingSpinner}
        </Button>
    );
};

export default CreateQuestionnaireButton;
