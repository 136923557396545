import React, {Component, ReactPropTypes} from 'react';
import {UserState} from "../userState";
import {defaults, User} from "../userState";
import userContext from "../userContext";
import {apiGet, endpoints} from "../api";
import {CircularProgress} from "@material-ui/core";

interface State extends UserState {
    loadingInit: boolean,
}

class AuthProvider extends Component<{}, State> {
    constructor(props: ReactPropTypes) {
        super(props);

        this.state = {
            ...defaults as UserState,
            setUser: (user: User): void => {
                if (user.token) {
                    localStorage.setItem('token', user.token);
                }

                this.setState({
                    user: user,
                });
            },
            logOut: (): void => {
                this.setState({
                    user: null,
                });
                localStorage.clear();
            },
            loadingInit: true,
        };
    }

    async componentDidMount(): Promise<any> {
        const token = localStorage.getItem('token');
        if (token === null) {
            this.setState({ loadingInit: false });
            return;
        }

        try {
            const response = await apiGet(endpoints.USER_DETAILS);

            const user: User = {
                ...this.context.user,
                email: response.data.user.email,
                firstName: response.data.user.first_name,
                lastName: response.data.user.last_name,
                verified: response.data.verified,
                token: token,
            };

            this.setState({
                user: user,
            });
        } finally {
            this.setState({ loadingInit: false });
        }
    }

    render(): JSX.Element {
        if (this.state.loadingInit === true) {
            return (
                <div style={{ display: 'grid', placeContent: 'center', height: '100vh' }}>
                    <CircularProgress color="inherit"></CircularProgress>
                </div>
            );
        }

        return (
            <userContext.Provider value={this.state}>
                {this.props.children}
            </userContext.Provider>
        );
    }
}

export default AuthProvider;
