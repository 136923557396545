import React, {ComponentType, useCallback, useContext} from "react";
import {AxiosError} from "axios";
import userContext from "../userContext";

interface HookReturnType {
    handleAxiosErrors: (error: AxiosError) => void;
}

export default function useAxiosErrorChecks(onError: (errors: string[]) => void): HookReturnType {
    const context = useContext(userContext);

    const handleAxiosErrors = useCallback((error: AxiosError): void => {
        if (error.response?.status === 401) {
            onError(["Authentication failed. Please log in again"]);
            context.logOut();
            return;
        }

        if (error.response?.status === 422) {
            onError(Object.values(error.response.data));
            return;
        }

        if (error.response?.status === 404) {
            onError(["Data not found"]);
            return;
        }

        if (error.response?.data?.message) {
            onError([
                error.response.data.message,
            ]);
            return;
        }

        onError([
            'An unknown error occurred',
        ]);

        if(process.env.REACT_APP_ENV === 'local') {
            throw error;
        }
    }, [context, onError]);

    return {
        handleAxiosErrors: handleAxiosErrors,
    };
}

export function withAxiosErrorChecks<T extends {onError: (errors: string[]) => void}>(WrappedComponent: ComponentType<T>) {
    return (props: T): JSX.Element => {
        const {handleAxiosErrors} = useAxiosErrorChecks(props.onError);

        const newProps = {...props, handleAxiosErrors};

        return <WrappedComponent {...newProps as T}/>;
    };
}