import {Component} from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';
import routes from "../routes";
import userContext from "../userContext";

interface Props extends RouteProps {
    allowUnverified?: boolean,
}
interface State {}

class PrivateRoute extends Component<Props, State> {
    static contextType = userContext;

    render(): JSX.Element {
        const {children, component, ...rest}: any = this.props;

        const authed = this.context.loggedIn();
        const verified = this.context.isEmailVerified();

        const allowUnverified = this.props.allowUnverified || false;

        if (!authed) {
            return (
                <Route
                    {...rest}
                    render={({ location }): JSX.Element => (
                        <Redirect
                            to={{
                                pathname: routes.LOGIN,
                                state: {from: location}
                            }}
                        />
                    )}
                />
            );
        }

        if (!verified && !allowUnverified) {
            return (
                <Route
                    {...rest}
                    render={({location}): JSX.Element => (
                            <Redirect
                                to={{
                                    pathname: routes.VERIFY_EMAIL,
                                    state: {from: location}
                                }}
                            />
                    )}
                />
            );
        }

        return (
            <Route component={component} {...rest}>
                {children}
            </Route>
        );
    }
}

export default PrivateRoute;