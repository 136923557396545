import Section, {SectionType} from "../DataObjects/Section";
import {InvalidSectionTypeError} from "../Errors/InvalidSectionTypeError";

export default class SectionPresenter {
    protected section: Section;

    constructor(section: Section) {
        this.section = section;
    }

    getName(): string {
        switch (this.section.getType()) {
            case SectionType.Intro:
                return 'General';
            case SectionType.Device:
                return 'Device';
            case SectionType.Software:
                return 'Software';
            case SectionType.Chip:
                return 'Chip';
            case SectionType.BestPractice:
                return 'Best Practice';
            case SectionType.Finalise:
                return 'Overview';
        }

        throw new InvalidSectionTypeError(this.section.getType());
    }

    getTitle(): string {
        switch (this.section.getType()) {
            case SectionType.Intro:
                return 'Assessment Information';
            case SectionType.Chip:
                return 'Chip Evaluation';
            case SectionType.Software:
                return 'System Software Evaluation';
        }

        return this.getName();
    }
}
