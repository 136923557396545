import React, {Component} from 'react';
import QuestionObj from '../../DataObjects/Question';
import QuestionnaireItemInterface from "../../DataObjects/QuestionnaireItemInterface";
import IntroQuestionGroupObj from "../../DataObjects/IntroQuestionGroup";
import Question from "./Question";
import IntroQuestions from "./IntroQuestions";
import Section from "../../DataObjects/Section";
import SectionReviewObj from "../../DataObjects/SectionReview";
import SectionReview from "./SectionReview";
import FinalisePage from "../../DataObjects/FinalisePage";
import Finalise from "./Finalise";
import Questionnaire from "../../DataObjects/Questionnaire";
import ChipPreamblePageObj from "../../DataObjects/ChipPreamblePage";
import SoftwarePreamblePageObj from "../../DataObjects/SoftwarePreamblePage";
import DevicePreamblePageObj from "../../DataObjects/DevicePreamblePage";
import BestPracticePreamblePageObj from "../../DataObjects/BestPracticePreamblePage";
import ChipPreamblePage from "./Pages/ChipPreamblePage";
import SoftwareEvaluationPage from "./Pages/SoftwarePreamblePage";
import DeviceEvaluationPage from "./Pages/DevicePreamblePage";
import BestPracticePreamblePage from "./Pages/BestPracticePreamblePage";

interface Props {
    item: QuestionnaireItemInterface;
    questionnaire: Questionnaire;
    items: QuestionnaireItemInterface[];
    firstItem?: boolean;
    onQuestionsSave: (newQuestions: QuestionObj[]) => void;
    onNextClick: () => void;
    onPrevClick: () => void;
    onAlreadyCertifiedChange: (section: Section, alreadyCertified: boolean) => void;
    onNavigateToQuestionClick: (question: QuestionObj) => void;
    onNavigateToSectionReviewClick: (section: Section) => void;
    onFinalise: () => void;
    onError: (errors: string[]) => void,
}

interface State {
}

class QuestionnaireItem extends Component<Props, State> {
    render(): JSX.Element | null {
        const item = this.props.item;

        switch (item.constructor) {
            case IntroQuestionGroupObj:
                return (
                    <IntroQuestions
                        questionGroup={item as IntroQuestionGroupObj}
                        submission={this.props.questionnaire.getSubmission()}
                        firstQuestion={this.props.firstItem}
                        onSave={this.props.onQuestionsSave}
                        onNextClick={this.props.onNextClick}
                        onPrevClick={this.props.onPrevClick}
                        onAlreadyCertifiedChange={this.props.onAlreadyCertifiedChange}
                    />
                );
            case ChipPreamblePageObj:
                return (
                    <ChipPreamblePage
                        onNextClick={this.props.onNextClick}
                        onPrevClick={this.props.onPrevClick}
                    />
                );
            case SoftwarePreamblePageObj:
                return (
                    <SoftwareEvaluationPage
                        onNextClick={this.props.onNextClick}
                        onPrevClick={this.props.onPrevClick}
                    />
                );
            case DevicePreamblePageObj:
                return (
                    <DeviceEvaluationPage
                        onNextClick={this.props.onNextClick}
                        onPrevClick={this.props.onPrevClick}
                    />
                );
            case BestPracticePreamblePageObj:
                return (
                    <BestPracticePreamblePage
                        onNextClick={this.props.onNextClick}
                        onPrevClick={this.props.onPrevClick}
                    />
                );
            case QuestionObj:
                return (
                    <Question
                        question={item as QuestionObj}
                        submission={this.props.questionnaire.getSubmission()}
                        questionnaire={this.props.questionnaire}
                        items={this.props.items}
                        firstQuestion={this.props.firstItem}
                        onSave={this.props.onQuestionsSave}
                        onNextClick={this.props.onNextClick}
                        onPrevClick={this.props.onPrevClick}
                        onNavigateToQuestionClick={this.props.onNavigateToQuestionClick}
                    />
                );
            case SectionReviewObj:
                return (
                    <SectionReview
                        sectionReview={item as SectionReviewObj}
                        onNextClick={this.props.onNextClick}
                        onPrevClick={this.props.onPrevClick}
                        onNavigateToQuestionClick={this.props.onNavigateToQuestionClick}
                        questionnaire={this.props.questionnaire}
                        items={this.props.items}
                    />
                );
            case FinalisePage:
                return (
                    <Finalise
                        finalisePage={item as FinalisePage}
                        submission={this.props.questionnaire.getSubmission()}
                        onNavigateToSectionReviewClick={this.props.onNavigateToSectionReviewClick}
                        onFinalise={this.props.onFinalise}
                        onError={this.props.onError}
                        questionnaire={this.props.questionnaire}
                        items={this.props.items}
                    />
                );
        }

        return null;
    }
}

export default QuestionnaireItem;
