import Question from "../../../DataObjects/Question";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import React, {ChangeEventHandler} from "react";

interface Props {
    question: Question,
    id: string,
    value: string,
    onChange: ChangeEventHandler,
    tabFocusRef?: React.RefObject<HTMLInputElement>,
}

const IntroQuestion = (props: Props): JSX.Element => {
    const question = props.question;

    return (
        <FormControl
            component="fieldset"
            fullWidth
            margin="normal"
        >
            <FormLabel component="legend">{question.present().getTitle()}</FormLabel>
            <RadioGroup
                row
                name={props.id}
                value={props.value}
                onChange={props.onChange}
            >
                <FormControlLabel value="YES" control={<Radio/>} label="Yes" inputRef={props.tabFocusRef}/>
                <FormControlLabel value="NO" control={<Radio/>} label="No"/>
            </RadioGroup>
        </FormControl>
    );
};

export default IntroQuestion;