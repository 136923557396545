import React from 'react';
import {
    Button,
    Container,
    Grid
} from "@material-ui/core";

export default function Footer(): JSX.Element|null {
    const currentYear = (new Date()).getFullYear();

    return (
        <footer style={{
            fontSize: '.8rem',
            background: 'rgb(224 224 224)',
        }}>
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{paddingTop: 36}}>
                        <Button
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.psacertified.org/"
                            size="small"
                            color="primary"
                            style={{marginLeft: "-5px"}}
                            >
                            PSA Certified
                        </Button>
                        <Button
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.psacertified.org/about/contact/"
                            size="small"
                            color="primary"
                        >
                            Contact Us
                        </Button>
                        <Button
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.psacertified.org/about/legal/privacy-policy/"
                            size="small"
                            color="primary"
                        >
                            Privacy Policy
                        </Button>
                        <p>The PSA Certified name, PSA Certified logos, PSA Functional API Certified logo featured on this website are registered trademarks or trademarks of Arm Limited (or its subsidiaries) in the US and/or elsewhere. All rights reserved. Other brands and names mentioned on this website may be the trademarks of their respective owners.</p>

                        <p>Copyright © { currentYear } Arm Limited (or its affiliates). All rights reserved.</p>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
}
