import Answer from "./Answer";
import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";

export enum ResponseType {
    Yes = 'YES',
    Partial = 'PARTIAL',
    NA = 'N/A',
}

interface FactoryProps {
    response?: ResponseType;
    body?: string;
}

export default class AnswerMulti extends Answer {
    protected response?: ResponseType;
    protected body?: string;

    static make(data: FactoryProps): AnswerMulti {
        let answer = new this();

        answer.response = data.response;
        answer.body = data.body;

        return answer;
    }

    getResponse(): ResponseType {
        if (typeof this.response === 'undefined') {
            throw new FieldNotDefinedError('response');
        }

        return this.response;
    }

    hasResponse(): boolean {
        return typeof this.response !== 'undefined';
    }

    getBody(): string {
        if (typeof this.body === 'undefined') {
            throw new FieldNotDefinedError('body');
        }

        return this.body;
    }

    hasBody(): boolean {
        return typeof this.body !== 'undefined';
    }

    toObject(): { [key: string]: any } {
        return {
            ...(typeof this.response !== 'undefined' && {response: this.getResponse()}),
            ...(typeof this.body !== 'undefined' && {body: this.getBody()}),
        };
    }
}
