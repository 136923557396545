import {getIntEnv} from "./ConfigUtils";

export const emailFieldIds = [
    getIntEnv('REACT_APP_QUESTION_CONTACT_EMAIL'),
];

export const urlFieldIds = [
    getIntEnv('REACT_APP_QUESTION_VULNERABILITY_DISCLOSURE_PUBLIC_URL'),
];

export const alreadyCertifiedFieldIds = [
    getIntEnv('REACT_APP_QUESTION_SOFTWARE_ALREADY_CERTIFIED'),
    getIntEnv('REACT_APP_QUESTION_CHIP_ALREADY_CERTIFIED'),
];

export const showIfAlreadyCertifiedFieldIds = [
    getIntEnv('REACT_APP_QUESTION_SOFTWARE_EAN'),
    getIntEnv('REACT_APP_QUESTION_CHIP_EAN'),
];

export const eanFieldIds = [
    getIntEnv('REACT_APP_QUESTION_SOFTWARE_EAN'),
    getIntEnv('REACT_APP_QUESTION_CHIP_EAN'),
];

export const yesNoFieldIds = [
    ...alreadyCertifiedFieldIds,
    getIntEnv('REACT_APP_VERSION_2.1_QUESTION_ROT_REPORTS'),
    getIntEnv('REACT_APP_VERSION_2.1_QUESTION_ROT_ISOLATED'),
    getIntEnv('REACT_APP_VERSION_2.1_QUESTION_ROT_ISOLATED_EACH_OTHER'),
];

export const textareaFieldIds = [
    getIntEnv('REACT_APP_QUESTION_CONTACT_ADDRESS'),
    getIntEnv('REACT_APP_VERSION_2.1_QUESTION_ROT_SERVICES_IMPLEMENTATION'),
    getIntEnv('REACT_APP_VERSION_2.1_QUESTION_ROT_TRUSTED_SUBSYSTEM'),
    getIntEnv('REACT_APP_VERSION_2.1_QUESTION_ROT_ENTROPY_SOURCE'),
];
