import Submission from "../../DataObjects/Submission";
import React from "react";
import {
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import routes, {makeUri} from "../../routes";
import {
    CheckCircleOutline,
    DonutLarge,
} from "@material-ui/icons";
import DestroyQuestionnaireButton from "../Questionnaire/partials/DestroyQuestionnaireButton";

interface Props {
    onError: (errors: string[]) => void,
    submission: Submission,
    onDelete: (submission: Submission) => void,
}

export default function SubmissionListItem(props: Props): JSX.Element {
    if (props.submission.isInProgress()) {
        return (
            <ListItem
                key={`incomplete-${props.submission.getId()}`}
                button
                component={Link}
                to={makeUri(routes.QUESTIONNAIRE, {id: props.submission.getId()})}
            >
                <ListItemIcon><DonutLarge/></ListItemIcon>
                <ListItemText
                    primary={props.submission.present().getName()}
                    secondary={`v${props.submission.getVersion().getVersionNumber()} | Type: ${props.submission.present().getType()}`}
                />
                <ListItemSecondaryAction>
                    {props.submission.isInProgress() && (
                        <DestroyQuestionnaireButton
                            id={props.submission.getId()}
                            onError={(): void => {}}
                            onSuccess={(): void => props.onDelete(props.submission)}
                        />
                    )}
                </ListItemSecondaryAction>
            </ListItem>
        );
    }

    if (props.submission.isFinalised()) {
        return (
            <ListItem
                key={`incomplete-${props.submission.getId()}`}
                button
                component={Link}
                to={makeUri(routes.QUESTIONNAIRE_SUMMARY, {id: props.submission.getId()})}
            >
                <ListItemIcon><CheckCircleOutline/></ListItemIcon>
                <ListItemText
                    primary={props.submission.present().getName()}
                    secondary={`v${props.submission.getVersion().getVersionNumber()} | Type: ${props.submission.present().getType()}`}
                />
            </ListItem>
        );
    }

    throw new Error('Submission must be either finalised or not');
}
