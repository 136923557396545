import https from "https";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {compile} from "path-to-regexp";

let baseUrl = process.env.REACT_APP_API_URL,
    axiosConfig = {} as AxiosRequestConfig,
    globalQueryParams = new URLSearchParams();

axiosConfig.headers = {};

if (process.env.REACT_APP_ENV === 'local') {
    axiosConfig.httpsAgent = new https.Agent({
        rejectUnauthorized: false
    });
    globalQueryParams.append('XDEBUG_SESSION', 'phpstorm');
}

axiosConfig.withCredentials = true;

const endpoints: any = {
    REGISTER: '/register',
    LOGIN: '/login',
    USER_DETAILS: '/user',
    ACCOUNT_CLOSE: '/close-account',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/forgot-password/reset',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_EMAIL_RESEND: '/verify-email/resend',
    SUBMISSIONS: '/submissions',
    SUBMISSION_CREATE: '/submission',
    SUBMISSION_DESTROY: '/submission/:id',
    SUBMISSION_FINALISE: '/submission/:id/finalise',
    SUBMISSION_EXPORTS: '/submission/:id/exportUrls',
    QUESTIONNAIRE_SINGLE: '/submission/:id',
    ANSWER_SAVE: '/submission/:submissionId/answers',
};

const apiPost = function(endpoint: string, urlParams?: Object, data?: any): Promise<AxiosResponse<any>> {
    const queryParams = new URLSearchParams(globalQueryParams);

    return axios.post(
        baseUrl + makeUri(endpoint, urlParams) + '?' + queryParams.toString(),
        data,
        axiosConfig,
    );
};

const apiGet = function(endpoint:string, urlParams?: Object, data?: Object): Promise<AxiosResponse<any>> {
    const queryParams = new URLSearchParams(globalQueryParams);
    if (data) {
        Object.entries(data).forEach(([key, value]: any[]) => {
            queryParams.append(key, value);
        });
    }

    return axios.get(
        baseUrl + makeUri(endpoint, urlParams) + '?' + queryParams.toString(),
        axiosConfig,
    );
};

const apiPut = function (endpoint: string, urlParams?: Object, data?: Object): Promise<AxiosResponse<any>> {
    const queryParams = new URLSearchParams(globalQueryParams);

    return axios.put(
        baseUrl + makeUri(endpoint, urlParams) + '?' + queryParams.toString(),
        data,
        axiosConfig,
    );
};

const apiDelete = function(endpoint: string, urlParams?: Object): Promise<AxiosResponse<any>> {
    const queryParams = new URLSearchParams(globalQueryParams);

    return axios.delete(
        baseUrl + makeUri(endpoint, urlParams) + '?' + queryParams.toString(),
        axiosConfig,
    );
};

export function makeUri(uri: string, urlParams?: Object): string {
    return compile(uri)(urlParams);
}

export {
    endpoints,
    apiPost,
    apiGet,
    apiPut,
    apiDelete,
};
