import React, {Component} from 'react';
import {
    Card, CardContent,
    Container,
    Grid, IconButton,
    List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText,
    Paper,
    Typography
} from "@material-ui/core";
import userContext from "../../userContext";
import {CloudDownload, Description} from "@material-ui/icons";


interface Props {
    onError: (errors: string[]) => void,
}

interface State {
    selected: number|null;
}

class Index extends Component<Props, State> {
    static contextType = userContext;

    constructor(props: Props) {
        super(props);

        this.state = {
            selected: null,
        };
    }

    render(): JSX.Element {
        return (
            <Container>
                <Paper elevation={3} style={{marginBottom: '55px', padding: '25px'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h2">Resources</Typography>
                            <p>
                                Download guidance to complete PSA Certified Level 1, learn more about the composition of
                                certifications, and download the glossary of terminology to support your evaluation.
                            </p>
                        </Grid>
                    </Grid>
                </Paper>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <List component="nav">
                                    <ListItem button component="a" href={'/assets/documents/v2.1/Overview.pdf'} download={true}>
                                        <ListItemIcon><Description/></ListItemIcon>
                                        <ListItemText primary={'PSA Certified Level 1 Questionnaire Overview'} />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="download">
                                                <CloudDownload/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem button component="a" href={'/assets/documents/v2.1/Glossary-of-Terminology.pdf'} download={true}>
                                        <ListItemIcon><Description/></ListItemIcon>
                                        <ListItemText primary={'PSA Certified Level 1 Questionnaire Glossary of Terminology'} />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="download">
                                                <CloudDownload/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        );
    }

}

export default Index;
