import React, {ChangeEvent, Component, FocusEvent, FormEvent} from 'react';
import {
    Button,
    CircularProgress,
    Container,
    TextField,
    Typography
} from "@material-ui/core";
import {apiPost, endpoints} from "../../api";
import {Link, Redirect} from "react-router-dom";
import routes from "../../routes";
import userContext from "../../userContext";
import {withAxiosErrorChecks} from "../../Hooks/useAxiosErrorChecks";
import {AxiosError} from "axios";

interface Props {
    onError: (errors: string[]) => void,
    onSuccess: (message: string) => void,
    handleAxiosErrors?: (error: AxiosError) => void,
}

interface Errors {
    email: string,
}

interface State {
    email: string,
    errors: Errors,
    submitLoading: boolean,
}

class ForgotPassword extends Component<Props, State> {
    static contextType = userContext;

    constructor(props: Props) {
        super(props);

        this.state = {
            email: '',
            errors: {
                email: '',
            },
            submitLoading: false,
        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onFieldBlur = this.onFieldBlur.bind(this);
        this.onFieldInvalid = this.onFieldInvalid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();

        this.setState({ submitLoading: true });

        try {
            await apiPost(endpoints.FORGOT_PASSWORD, undefined, {
                'email': this.state.email,
            });

        } catch (error: any) {
            if (this.props.handleAxiosErrors) {
                this.props.handleAxiosErrors(error);
            }
            return;
        } finally {
            this.setState({ submitLoading: false });
        }

        this.props.onSuccess('Reset password link sent to email');
    }

    onFieldChange(e: ChangeEvent<HTMLInputElement>): void {
        const element = e.currentTarget;
        const newState: State = {
            ...this.state
        };

        switch (element.id) {
            case 'email':
                newState.email = element.value;
                newState.errors.email = '';
                break;
            default:
                throw new Error(`Unknown field: ${element.id}`);
        }

        this.setState(
            newState,
            () => {
                this.validateField(element);
            });
    }

    onFieldBlur(e: FocusEvent<HTMLInputElement>): void {
        this.validateField(e.target);
    }

    validateField(inputField: HTMLInputElement): void {
        inputField.checkValidity();
    }

    onFieldInvalid(e: FormEvent<HTMLInputElement>): void {
        e.preventDefault();

        const errors = this.state.errors;

        // @ts-ignore
        errors[e.target.id] = e.target.validationMessage;

        this.setState(oldState => {
            return {
                ...oldState,
                errors: errors,
            };
        });
    }

    render(): JSX.Element {
        if (this.context.loggedIn()) {
            return (
                <Redirect to={routes.HOME}></Redirect>
            );
        }
        const submitButton = (this.state.submitLoading) ? (
            <Button type="submit" variant="contained" color="secondary" size="medium" disabled>
                Sending..
                <CircularProgress size={20} color="inherit" style={{marginLeft: 10}}></CircularProgress>
            </Button>
        ) : (
            <Button type="submit" variant="contained" color="secondary" size="medium">
                Send
            </Button>
        );

        return (
            <Container maxWidth="sm">
                <form onSubmit={this.onSubmit}>
                    <Typography variant="h2">Forgot Password</Typography>

                    <p>Please enter your email to receive a reset password link</p>

                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        id="email"
                        label="Business Email"
                        type="email"
                        value={this.state.email}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        onInvalid={this.onFieldInvalid}
                        error={this.state.errors.email !== ''}
                        helperText={this.state.errors.email}
                    />

                    { submitButton }
                </form>

                <p>
                    <Link to={routes.LOGIN}>Back to login</Link>
                </p>
            </Container>
        );
    }
}

export default withAxiosErrorChecks(ForgotPassword);
