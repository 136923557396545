import Section from "./Section";
import Version from "./Version";
import Submission from "./Submission";
import Question from "./Question";

export default class Questionnaire {
    protected submission: Submission;
    protected version: Version;
    protected sections: Section[];
    protected questions: Question[];

    constructor(
        submission: Submission,
        version: Version,
        sections: Section[],
        questions: Question[],
    ) {
        this.submission = submission;
        this.version = version;
        this.sections = sections;
        this.questions = questions;
    }

    getSubmission(): Submission {
        return this.submission;
    }

    getVersion(): Version {
        return this.version;
    }

    getSections(): Section[] {
        return this.sections;
    }

    getQuestions(): Question[] {
        return this.questions;
    }
}
