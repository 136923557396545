import "./AccountNav.css";
import {Button} from "@material-ui/core";
import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import routes from "../../../routes";

class AccountNav extends Component<{}, {}> {
    render(): JSX.Element {
        return (
            <nav style={{marginBottom: 10}} className="account-nav">
                <Button component={NavLink} to={routes.ACCOUNT_UPDATE} exact>
                    Update Account
                </Button>
                <Button component={NavLink} to={routes.ACCOUNT_CLOSE} exact>
                    Close Account
                </Button>
            </nav>
        );
    }
}

export default AccountNav;
