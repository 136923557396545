import React, {
    useContext,
    useState
} from 'react';
import {
    AppBar,
    Button,
    Icon,
    IconButton, makeStyles,
    Menu,
    MenuItem,
    Theme,
    Toolbar, Typography,
    useMediaQuery, useTheme
} from "@material-ui/core";
import userContext from "../userContext";
import {Link} from "react-router-dom";
import routes from "../routes";
import './Header.scss';

const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    appBarTablet: {
        zIndex: theme.zIndex.appBar,
    },
}));

export default function Header(): JSX.Element {
    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const context = useContext(userContext);
    const theme = useTheme();
    const tabletMatch = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    const onAccountClick = (event: any): void => {
        const menuAnchor = event.currentTarget instanceof HTMLElement ? event.currentTarget : null;

        setAccountMenuAnchorEl(menuAnchor);
        setAccountMenuOpen(true);
    };

    const onAccountCloseClick = (): void => {
        setAccountMenuOpen(false);
    };

    const mainMenu = (context.loggedIn()) ? (
        <div>
            <Button
                color="inherit"
                className={"limeHover"}
                style={{fontWeight: 'bold'}}
                component={Link}
                to={routes.HOME}
            >
                Dashboard
            </Button>
            <Button
                color="inherit"
                className={"limeHover"}
                style={{fontWeight: 'bold'}}
                component={Link}
                to={routes.RESOURCE_INDEX}
            >
                Resources
            </Button>
        </div>
    ) : null;

    const accountMenu = context.loggedIn() ? (
        <div>
            <IconButton
                className={"limeHover"}
                color="inherit"
                aria-haspopup="true"
                onClick={onAccountClick}
            >
                <Icon>account_circle</Icon>
            </IconButton>
            <Menu
                anchorEl={accountMenuAnchorEl}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                getContentAnchorEl={null}
                open={accountMenuOpen}
                onClick={onAccountCloseClick}
            >
                <MenuItem component={Link} to={routes.ACCOUNT}>
                    <Icon>account_circle</Icon>
                    My Account
                </MenuItem>
                <MenuItem component={Link} to={routes.LOGOUT}>
                    <Icon>arrow_right_alt</Icon>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    ) : null;

    return (
        <AppBar
            color="primary"
            position="static"
            className="app-bar"
            classes={{
                root: tabletMatch ? classes.appBarTablet : classes.appBar,
            }}
        >
            <Toolbar style={{width: '76.5625rem', margin: '0 auto'}}>
                <div style={{flexGrow: 1, padding: "18px 0 10px"}}>
                    <a href="/">
                        <img src="/assets/psa-certified-logo.svg" alt="PSA Certified Logo" style={{
                            maxHeight: 103,
                            marginBottom: 18,
                        }}/>
                    </a>
                    <Typography variant="h3" component="h1">PSA Certified Level 1 Questionnaire</Typography>
                    <Typography variant="h4">Security Evaluation for IoT and Connected Devices</Typography>
                </div>
                {mainMenu}
                {accountMenu}
            </Toolbar>
        </AppBar>
    );
}
