import React from "react";
import Question from "../../../DataObjects/Question";
import {makeStyles, Typography} from "@material-ui/core";
import QuestionLink from "./QuestionLink";
import Questionnaire from "../../../DataObjects/Questionnaire";
import QuestionnaireItemInterface from "../../../DataObjects/QuestionnaireItemInterface";

interface Props {
    question: Question;
    questionnaire: Questionnaire;
    items: QuestionnaireItemInterface[];
    onNavigateToQuestionClick: (question: Question) => void;
}

export default function QuestionBody(props: Props): JSX.Element {
    const useStyles = makeStyles({
        root: {
            margin: '1em 0',
        },
    });

    const classes = useStyles(props);

    const processQuestionLinks = (paragraph: string, paragraphIndex: number): JSX.Element[] => {
        const questions = props.questionnaire.getQuestions();
        const questionNumbers = questions
            .filter((question: Question): boolean => {
                // Filter out questions that share numbers
                return questions
                    .filter((question2: Question): boolean => question2.getNumber() === question.getNumber())
                    .length === 1;
            })
            .map((question: Question): string => question.getNumber())
            .sort((a: string, b: string) => b.length - a.length);

        const regex = new RegExp('(' + questionNumbers.join('|') + ')', 'g');

        return paragraph.split(regex)
            .map((part: string, index: number): JSX.Element => {
                const key = `paragraph-part-${paragraphIndex}-${index}`;
                const question = questions.find((question: Question): boolean => question.getNumber() === part);

                let partElements: JSX.Element[] = [
                    <React.Fragment>{part}</React.Fragment>
                ];

                if (part.search(/• /g) !== -1) {
                    let bulletLabels = part.split('• ');

                    if (bulletLabels.length > 1) {
                        let firstItem = bulletLabels.shift();

                        if (firstItem !== undefined) {
                            partElements.length = 0;
                            partElements.push(<React.Fragment>{firstItem}</React.Fragment>);

                            let bulletElements = bulletLabels.map((bullet: string): JSX.Element => {
                                return <li>{bullet}</li>;
                            });

                            partElements.push(<ul>{bulletElements}</ul>);
                        }
                    }
                }

                if (typeof question === 'undefined') {
                    return (
                        <React.Fragment key={key}>
                            {partElements}
                        </React.Fragment>
                    );
                }

                return (
                    <QuestionLink
                        key={key}
                        question={question}
                        questionnaire={props.questionnaire}
                        items={props.items}
                        onNavigateToQuestionClick={props.onNavigateToQuestionClick}
                    >
                        {partElements}
                    </QuestionLink>
                );
            });
    };

    const body = props.question.getBody();

    const paragraphs = body
        .split("\n\n")
        .map((paragraph: string, index: number): JSX.Element => (
            <Typography component="p" key={index} className={classes.root}>
                {processQuestionLinks(paragraph, index)}
            </Typography>
        ));

    return (
        <div>
            {paragraphs}
        </div>
    );
}
