import Question from "../../../DataObjects/Question";
import {yesNoFieldIds} from "../../../Config/IntroFieldTypes";
import React, {ChangeEventHandler} from "react";
import YesNoQuestion from "./YesNoQuestion";
import TextQuestion from "./TextQuestion";

interface Props {
    question: Question,
    id: string,
    value: string,
    onChange: ChangeEventHandler,
    tabFocusRef?: React.RefObject<HTMLInputElement>,
}

const IntroQuestion = (props: Props): JSX.Element => {
    if (yesNoFieldIds.includes(props.question.getId())) {
        return (
            <YesNoQuestion
                question={props.question}
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                tabFocusRef={props.tabFocusRef}
            />
        );
    }

    return (
        <TextQuestion
            question={props.question}
            id={props.id}
            value={props.value}
            onChange={props.onChange}
            tabFocusRef={props.tabFocusRef}
        />
    );
};

export default IntroQuestion;