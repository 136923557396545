import React from "react";
import QuestionExample from "../DataObjects/QuestionExample";

export default class QuestionExamplePresenter {
    protected example: QuestionExample;

    constructor(example: QuestionExample) {
        this.example = example;
    }

    getBody(): JSX.Element[] {
        const body = this.example.getBody();

        return body.split("\n\n").map((paragraph: string) => {
            const lines = paragraph
                .split("\n")
                .map((line: string): JSX.Element => {
                    if (line.search(/^Example answer/) !== -1) {
                        return <><strong>{line}</strong><br/></>;
                    }

                    return <>{line}<br/></>;
                });

            return <p>{lines}</p>;
        });
    }
}
