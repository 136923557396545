import React, {
    ReactNode,
    useState
} from 'react';
import {
    AppBar, Divider,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Paper,
    Tab,
    Tabs,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import TabPanel from "../../partials/TabPanel";
import QuestionObj from "../../../DataObjects/Question";
import QuestionNote from "../../../DataObjects/QuestionNote";
import QuestionExample from "../../../DataObjects/QuestionExample";
import QuestionCertification from "../../../DataObjects/QuestionCertification";
import {questionSupplementWidth} from "../../../Config/UIConfig";

interface Props {
    question: QuestionObj;
}

const useStyles = makeStyles({
    drawerPaper: {
        position: 'absolute',
        width: questionSupplementWidth,
    },
    drawerPaperTablet: {
        position: 'fixed',
        width: '100%',
    },
});

export default function QuestionSupplement(props: Props): JSX.Element {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const theme = useTheme();
    const tabletMatch = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    const renderNotes = (): ReactNode => {
        const notes = props.question.getNotes();

        if (notes.length === 0) {
            return (
                <ListItem>
                    <ListItemText primary="No available notes"/>
                </ListItem>
            );
        }

        return notes.map((note: QuestionNote, index: number): ReactNode => {
            return (
                <>
                    <ListItem key={index}>
                        <ListItemText
                            primary={note.present().getBody()}
                        />
                    </ListItem>
                    <Divider component="li" />
                </>
            );
        });
    };

    const renderExamples = (): ReactNode => {
        const examples = props.question.getExamples();

        if (examples.length === 0) {
            return (
                <>
                    <ListItem>
                        <ListItemText primary="No available examples"/>
                    </ListItem>
                    <Divider component="li" />
                </>
            );
        }

        return examples.map((example: QuestionExample, index: number): ReactNode => {
            return (
                <>
                    <ListItem key={index}>
                        <ListItemText
                            primary={example.present().getBody()}
                        />
                    </ListItem>
                    <Divider component="li" />
                </>
            );
        });
    };

    const renderCertifications = (): ReactNode => {
        const certifications = props.question.getCertifications();

        if (certifications.length === 0) {
            return (
                <>
                    <ListItem>
                        <ListItemText primary="No information available"/>
                    </ListItem>
                    <Divider component="li" />
                </>
            );
        }

        return certifications.map((certification: QuestionCertification, index: number): ReactNode => {
            return (
                <>
                    <ListItem key={index}>
                        <ListItemText>
                            <div>
                                <strong>
                                    {certification.getCertName()}
                                </strong>
                            </div>
                            <div style={{marginTop: 5}}>
                                {certification.present().getNotes()}
                            </div>
                            <div style={{marginTop: 5}}>
                                Expected Answer: {certification.present().getPassRequirement()}
                            </div>
                        </ListItemText>
                    </ListItem>
                    <Divider component="li" />
                </>
            );
        });
    };

    const tabs = (
        <Tabs
            value={currentTabIndex}
            onChange={(event, newIndex): void => {
                setCurrentTabIndex(newIndex);
            }}
        >
            <Tab label="Notes"/>
            <Tab label="Examples"/>
            <Tab label="NIST/ETSI Mapping"/>
        </Tabs>
    );
    const tabPanels = (
        <>
            <TabPanel value={currentTabIndex} index={0}>
                <List>{renderNotes()}</List>
            </TabPanel>
            <TabPanel value={currentTabIndex} index={1}>
                <List>{renderExamples()}</List>
            </TabPanel>
            <TabPanel value={currentTabIndex} index={2}>
                <List>{renderCertifications()}</List>
            </TabPanel>
        </>
    );

    if (tabletMatch) {
        return (
            <Grid item xs={12}>
                <Paper elevation={10}>
                    <AppBar position="static" color="default" elevation={0}>
                        {tabs}
                    </AppBar>
                    {tabPanels}
                </Paper>
            </Grid>
        );
    }

    return (
        <Drawer
            anchor="right"
            variant='permanent'
            classes={{
                paper: tabletMatch ? classes.drawerPaperTablet : classes.drawerPaper,
            }}
        >
            <AppBar position="static" color="default" elevation={0}>
                <Toolbar>
                    <Typography variant="h6">
                        Additional Information
                    </Typography>
                </Toolbar>
            </AppBar>
            {tabs}
            {tabPanels}
        </Drawer>
    );
}
