import React, {
    MouseEvent,
    useEffect,
} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Icon,
    IconButton,
} from "@material-ui/core";
import FinalisePage from "../../DataObjects/FinalisePage";
import Submission from "../../DataObjects/Submission";
import Section from "../../DataObjects/Section";
import {
    Alert,
    AlertTitle,
    Color,
} from "@material-ui/lab";
import SectionReport, {SectionReportStatus} from "../../DataObjects/SectionReport";
import routes, {makeUri} from "../../routes";
import Questionnaire from "../../DataObjects/Questionnaire";
import QuestionnaireItemInterface from "../../DataObjects/QuestionnaireItemInterface";
import QuestionnaireService from "../../Services/QuestionnaireService";
import Supplement from "./partials/Supplement";

interface Props {
    finalisePage: FinalisePage;
    submission: Submission;
    onFinalise: () => void;
    onNavigateToSectionReviewClick: (section: Section) => void;
    onError: (errors: string[]) => void;
    questionnaire: Questionnaire;
    items: QuestionnaireItemInterface[];
}

const Finalise = function(props: Props): JSX.Element {
    const firstSectionRef = React.createRef<HTMLAnchorElement>();

    const sectionReports = props.finalisePage.getSectionReports();
    const ready = sectionReports.every((sectionReport: SectionReport): boolean => {
        return sectionReport.getStatus() !== SectionReportStatus.Incomplete;
    });

    const questionnaireService = new QuestionnaireService();

    useEffect(() => {
        if (firstSectionRef.current !== null) {
            firstSectionRef.current.focus();
        }
    }, [
        firstSectionRef,
        ready,
    ]);

    async function onFinalise(): Promise<void> {
        props.onFinalise();
    }

    function onSectionEditClick(section: Section): void {
        props.onNavigateToSectionReviewClick(section);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card raised={true} style={{marginTop: '10px'}}>
                    <CardHeader
                        title='Overview'
                        titleTypographyProps={{variant: 'h1'}}
                    />
                    <CardContent>
                        {sectionReports.map((sectionReport: SectionReport, index: number): JSX.Element => (
                            <Alert
                                severity={((): Color|undefined => {
                                    switch (sectionReport.getStatus()) {
                                        case SectionReportStatus.Complete:
                                        case SectionReportStatus.Allowable:
                                            return 'success';
                                        case SectionReportStatus.Incomplete:
                                            return 'error';
                                    }
                                })()}
                                style={{marginBottom: 10}}
                                key={sectionReport.getSection().getId()}
                                action={
                                    <IconButton
                                        style={{marginRight: 16}}
                                        color="inherit"
                                        size="small"
                                        ref={(index === 0) ? firstSectionRef : undefined}
                                        onClick={(e: MouseEvent<HTMLAnchorElement>): void => {
                                            e.preventDefault();
                                            onSectionEditClick(sectionReport.getSection());
                                        }}
                                        href={makeUri(routes.QUESTIONNAIRE, {
                                            id: props.submission.getId(),
                                            itemId: questionnaireService.getPageIndexBySection(sectionReport.getSection(), props.items, props.questionnaire) + 1,
                                        })}
                                    >
                                        <Icon>edit</Icon>
                                    </IconButton>
                                }
                            >
                                <AlertTitle>{sectionReport.getSection().present().getName()} Section</AlertTitle>
                                {sectionReport.getStatusText() ?? ''}
                            </Alert>
                        ))}

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{float: 'right'}}
                                onClick={onFinalise}
                                disabled={!ready}
                            >
                                Next
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Supplement>
                <p>Here is an overview of all the sections of the questionnaire</p>
                <p>Please adjust any section that has incorrect questions</p>
                <p>One unexpected answer (according to the PSA Certified Level 1 standard) is allowed per section</p>

                <p>Once you are satisfied with your responses, download your PSA Certified Level 1 questionnaire to share with your evaluation laboratory of choice.</p>
                <p>Draft questionnaires will be available in your dashboard for edits based on feedback from the evaluation laboratory.</p>
            </Supplement>
        </Grid>
    );
};

export default Finalise;
