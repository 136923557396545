import React from 'react';
import { Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";

interface Props {
    onNextClick: () => void;
    onPrevClick: () => void;
}

const DevicePreamblePage = (props: Props): JSX.Element => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card raised={true} style={{marginTop: '10px'}}>
                    <CardHeader
                        title='Device Evaluation'
                        titleTypographyProps={{variant: 'h1'}}
                    />
                    <CardContent>
                        <p style={{marginBottom: '1em'}}>
                            This section has 19 questions and applies to a device built on the system software that is built on a chip PSA-RoT.
                        </p>
                        <p style={{marginBottom: '1em'}}>
                            All questions require a justification. Some questions are optional and are marked in the question title.
                        </p>
                        <p style={{marginBottom: '1em'}}>
                            Details on alignment to security requirements from NIST and ETSI, notes to provide additional information, and example answers are provided.
                        </p>

                        <Grid container direction="row-reverse" spacing={3} style={{marginTop: '15px'}}>
                            <Grid item style={{flexGrow: 1}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{float: 'right'}}
                                    onClick={props.onNextClick}
                                >
                                    Begin Device Assessment
                                </Button>
                            </Grid>
                            <Grid item style={{flexGrow: 1}}>
                                <Button
                                    variant="contained"
                                    onClick={props.onPrevClick}
                                >
                                    Previous
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default DevicePreamblePage;
