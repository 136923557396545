import Question from "../../../DataObjects/Question";
import {TextField} from "@material-ui/core";
import {eanFieldIds, emailFieldIds, textareaFieldIds, urlFieldIds} from "../../../Config/IntroFieldTypes";
import React, {ChangeEventHandler, useState, FocusEvent, InvalidEvent, ChangeEvent} from "react";

interface Props {
    question: Question,
    id: string,
    value: string,
    onChange: ChangeEventHandler,
    tabFocusRef?: React.RefObject<HTMLInputElement>,
}

const TextQuestion = (props: Props): JSX.Element => {
    const [error, setError] = useState<string>('');

    const question = props.question;

    let textFieldType = 'text';

    if (emailFieldIds.includes(question.getId())) {
        textFieldType = 'email';
    } else if (urlFieldIds.includes(question.getId())) {
        textFieldType = 'url';
    }

    let maxLength = 65535;

    if (eanFieldIds.includes(question.getId())) {
        maxLength = 13;
    }

    const multiline = (textareaFieldIds.includes(props.question.getId()));

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setError('');
        props.onChange(e);
    };

    const onBlur = (event: FocusEvent<HTMLInputElement>): void => {
        event.target.checkValidity();
    };

    const onInvalid = (e: InvalidEvent<HTMLInputElement>): void => {
        if (e.target.type === 'url') {
            setError('Please enter a URL (including http(s)://)');
            return;
        }

        setError(e.target.validationMessage);
    };

    return (
        <TextField
            fullWidth
            margin="normal"
            label={question.present().getTitle()}
            type={textFieldType}
            {...(multiline && {multiline: true, minRows: 3, maxRows: 6})}
            id={props.id}
            value={props.value}
            onChange={onChange}
            onBlur={onBlur}
            onInvalid={onInvalid}
            error={error !== ''}
            helperText={error}
            inputProps={{maxLength: maxLength}}
            inputRef={props.tabFocusRef}
            required={true}
        />
    );
};

export default TextQuestion;
