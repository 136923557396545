import Answer from "../DataObjects/Answer";
import AnswerText from "../DataObjects/AnswerText";
import AnswerMulti, {ResponseType} from "../DataObjects/AnswerMulti";
import InvalidAnswerTypeError from "../Errors/InvalidAnswerTypeError";

interface AnswerData {
    type: "MULTI" | "TEXT",
    response?: string,
    body?: string,
}

export default class AnswerFactory {
    public static make(answerData: AnswerData): Answer {
        if (answerData.type === 'MULTI') {
            return AnswerMulti.make({
                response: answerData.response as ResponseType,
                body: answerData.body,
            });
        } else if (answerData['type'] === 'TEXT') {
            return AnswerText.make({
                response: answerData.response,
            });
        } else {
            throw new InvalidAnswerTypeError(answerData.type);
        }
    }
}