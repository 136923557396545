import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";
import QuestionCertificationPresenter from "../Presenters/QuestionCertificationPresenter";

export enum PassRequirementType {
    Yes = 'YES',
    NotPartial = 'NOT PARTIAL',
    Any = 'ANY',
}

interface FactoryProps {
    certName?: string;
    certId?: number;
    notes?: string|null;
    passRequirement?: PassRequirementType;
}

export default class QuestionCertification {
    protected presenter: QuestionCertificationPresenter;
    protected certName?: string;
    protected certId?: number;
    protected notes?: string|null;
    protected passRequirement?: PassRequirementType;

    constructor() {
        this.presenter = new QuestionCertificationPresenter(this);
    }

    present(): QuestionCertificationPresenter {
        return this.presenter;
    }

    static make(certData: FactoryProps): QuestionCertification {
        const certification = new this();

        certification.certName = certData.certName;
        certification.certId = certData.certId;
        certification.notes = certData.notes;
        certification.passRequirement = certData.passRequirement;

        return certification;
    }

    getCertId(): number {
        if (typeof this.certId === 'undefined') {
            throw new FieldNotDefinedError('certId');
        }

        return this.certId;
    }

    getCertName(): string {
        if (typeof this.certName === 'undefined') {
            throw new FieldNotDefinedError('certName');
        }

        return this.certName;
    }

    getNotes(): string|null {
        if (typeof this.notes === 'undefined') {
            throw new FieldNotDefinedError('notes');
        }

        return this.notes;
    }

    getPassRequirement(): string {
        if (typeof this.passRequirement === 'undefined') {
            throw new FieldNotDefinedError('passRequirement');
        }

        return this.passRequirement;
    }
}
