import Modal from "@material-ui/core/Modal";
import {Button, Card, CardContent, Grid, InputLabel, Radio, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import RadioGroup from "@material-ui/core/RadioGroup";
import CreateQuestionnaireButton from "../Questionnaire/partials/CreateQuestionnaireButton";
import React, {ChangeEvent, useState} from "react";
import {SubmissionType} from "../../DataObjects/Submission";
import routes from "../../routes";

interface Props {
    open: boolean;
    onClose?: () => void,
    onError: (errors: string[]) => void,
    onSuccess: (submissionId: number) => void,
}

export default function CreateQuestionnaireModal(props: Props): JSX.Element {
    const [page, setPage] = useState(0);
    const [type, setType] = useState<SubmissionType | null>(null);

    function renderPage1(): JSX.Element {
        return (
            <Card elevation={3} style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '800px',
                maxHeight: '80%',
                transform: 'translate(-50%, -50%)',
                overflow: 'auto',
                padding: '15px'
            }}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h2">Start New Questionnaire</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <h2 style={{marginTop: 0}}>About PSA Certified Level 1</h2>
                            <p>
                                The PSA Certified Level 1 requirements are derived from research done into IoT threat models, the PSA Certified 10 Security Goals and important regional security requirements such as NIST 8259A and EN 303 645. The questions focus on device level security requirements rather than operational aspects.
                            </p>

                            <h2>Using the PSA Certified Level 1 Online Questionnaire</h2>
                            <p>
                                Once you have started your questionnaire, you can save and continue later or can download a draft questionnaire for review with your evaluation lab of choice before creating your final questionnaire. Your chosen evaluation lab will use this questionnaire to examine your responses to the security requirements and may ask for clarifications and further information if it is needed. All ‘in progress’ questionnaires can be found in the dashboard.
                            </p>
                            <p>
                                When your product has achieved PSA Certified Level 1, a digital certificate is issued by the PSA Certified Certification Body for the specific product and is published on the PSA Certified website. A certificate number, with a globally unique EAN-13 number, is provided. Products that support an IETF Entity Attestation Token can include the EAN-13 to inform relying parties that the device has been evaluated and is PSA Certified.
                            </p>
                            <p>
                                We will not be sharing any information that is entered into the questionnaire. Instead, you should download your questionnaire to share with your evaluation lab of choice.
                            </p>
                            <h2>Useful Resources</h2>
                            <p>
                                Prior to beginning the online PSA Certified Level 1 questionnaire, we recommend reading the following documentation that can be accessed via the <Link to={routes.RESOURCE_INDEX}>Resources section</Link>.
                                <ul>
                                    <li>Overview including: Scope of the security evaluation, Roles, Options for Evaluation & Composition, Process</li>
                                    <li>Glossary of Terminology</li>
                                </ul>
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div style={{float: 'right'}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="medium"
                                    onClick={(): void => setPage(1)}
                                >
                                    Continue
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    function renderPage2(): JSX.Element {
        return (
            <Card elevation={3} style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '800px',
                maxHeight: '80%',
                transform: 'translate(-50%, -50%)',
                overflow: 'auto',
                padding: '15px'
            }}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h2">Start New Questionnaire</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <p style={{marginTop: 0}}>
                                PSA Certified Level 1 is a cybersecurity baseline assessment with questions based on security principles and best practice. The certification scheme is organized in three layers that support composite certification: at the bottom of the stack is “Chip/PSA-RoT”, in the middle is “System Software” and at the top “Device”.
                            </p>
                            <p>
                                The certificate for a given layer is only applicable if the lower layers have either been separately evaluated and hold a PSA Certified Level 1 certificate or, if not, are covered in the evaluation that leads to the considered certificate. <a href="https://www.psacertified.org/getting-certified/silicon-vendor/overview/level-1/questionnaire-composition/" target="_blank" rel="noreferrer">Learn more about the questionnaire composition.</a>
                            </p>

                            <p>
                                What is the highest level of this stack that you wish to complete in this PSA Certified Level 1 questionnaire?
                            </p>
                            <small>
                                Note: if you are using a pre-certified chip or system software for your evaluation, you will be prompted to enter the EAN-13 certificate number when completing the questionnaire.
                            </small>
                        </Grid>

                        <RadioGroup
                            value={type}
                            onChange={onChooseType}
                        >
                            <Grid item xs={12}>
                                <Grid container style={{margin: '20px 0'}} alignItems={'center'}>
                                    <Grid item xs={1}>
                                        <Radio id="submissionType-device" color="secondary" value={SubmissionType.Device}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputLabel htmlFor="submissionType-device">Device:</InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <strong>An IoT gadget / connected device</strong><br/>
                                        <small>Questions on: Integrity, Communication, Hardening, Passwords, Privacy</small>
                                    </Grid>
                                </Grid>
                                <Grid container style={{margin: '20px 0'}} alignItems={'center'}>
                                    <Grid item xs={1}>
                                        <Radio id="submissionType-software" color="secondary" value={SubmissionType.Software}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputLabel htmlFor="submissionType-software">System Software:</InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <strong>OS or RTOS based software platform</strong><br/>
                                        <small>Questions on: Integrity, Data Assets, Communication, Hardening, Configuration, Passwords/CSP</small>
                                    </Grid>
                                </Grid>
                                <Grid container style={{margin: '20px 0'}} alignItems={'center'}>
                                    <Grid item xs={1}>
                                        <Radio id="submissionType-chip" color="secondary" value={SubmissionType.Chip}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputLabel htmlFor="submissionType-chip">Chip:</InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <strong>Chip's Root of Trust</strong><br/>
                                        <small>Questions on: RoT (Immutable), PSA-RoT</small>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </Grid>


                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div style={{float: 'right'}}>
                                <CreateQuestionnaireButton
                                    type={type}
                                    onError={props.onError}
                                    onSuccess={props.onSuccess}
                                >
                                    Begin Questionnaire
                                </CreateQuestionnaireButton>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    function onChooseType(e: ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;

        setType(value as SubmissionType);
    }

    function onClose(): void {
        setPage(0);

        if (props.onClose) {
            props.onClose();
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={onClose}
        >
            {page === 0 ? renderPage1() : renderPage2()}
        </Modal>
    );
}
