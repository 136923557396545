import UndefinedEnvironmentVariableError from "../Errors/UndefinedEnvironmentVariableError";
import InvalidEnvironmentVariableError from "../Errors/InvalidEnvironmentVariableError";

export function getStringEnv(env: string): string {
    const value = process.env[env];

    if (typeof value === 'undefined') {
        throw new UndefinedEnvironmentVariableError(env);
    }

    return value;
}

export function getIntEnv(env: string): number {
    const value = Number.parseInt(getStringEnv(env));

    if (Number.isNaN(value)) {
        throw new InvalidEnvironmentVariableError(env);
    }

    return value;
}
