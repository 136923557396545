import React, {MouseEvent, KeyboardEvent, useState} from "react";
import Question from "../../../DataObjects/Question";
import {Button, Link, makeStyles, Popover, Typography} from "@material-ui/core";
import QuestionnaireService from "../../../Services/QuestionnaireService";
import Questionnaire from "../../../DataObjects/Questionnaire";
import QuestionnaireItemInterface from "../../../DataObjects/QuestionnaireItemInterface";
import routes, {makeUri} from "../../../routes";

interface Props {
    question: Question;
    questionnaire: Questionnaire;
    items: QuestionnaireItemInterface[];
    children?: React.ReactNode;
    onNavigateToQuestionClick: (question: Question) => void;
}

const useStyles = makeStyles((theme) => ({
    popoverRoot: {
        pointerEvents: 'none',
    },
    popoverPaper: {
        width: 400,
        pointerEvents: 'auto',
    },
    disabledLink: {
        color: 'inherit',
        cursor: 'default',
        textDecorationStyle: 'dotted',
        textDecorationThickness: '1px',
    }
}));

export default function QuestionLink(props: Props): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<Element|null>(null);
    const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout|null>(null);
    const classes = useStyles();

    const questionnaireService = new QuestionnaireService();
    const shouldBeLinkedTo = questionnaireService.itemShouldBeShown(props.question, props.items, props.questionnaire);
    const itemId = questionnaireService.getPageIndex(props.question, props.items, props.questionnaire);

    const onClick = (e: MouseEvent): void => {
        e.preventDefault();

        if (!shouldBeLinkedTo) {
            return;
        }

        props.onNavigateToQuestionClick(props.question);
    };

    const onMouseOverLink = (e: MouseEvent): void => {
        if (timeoutRef !== null) {
            clearTimeout(timeoutRef);
        }
        if (anchorEl === null) {
            setAnchorEl(e.currentTarget);
        }
    };

    const onMouseOverPopover = (e: MouseEvent): void => {
        if (timeoutRef !== null) {
            clearTimeout(timeoutRef);
        }
    };

    const onMouseLeave = (e: MouseEvent): void => {
        const ref = setTimeout(() => {
            setAnchorEl(null);
            setTimeoutRef(null);
        }, 250);

        setTimeoutRef(ref);
    };

    const onLinkKeyPress = (e: KeyboardEvent): void => {
        if (e.key === 'Enter' || e.key === ' ') {
            setAnchorEl(e.currentTarget as Element);
        }
    };

    const onPopoverKeyDown = (e: KeyboardEvent): void => {
        if (e.key === 'Escape') {
            setAnchorEl(null);
        }
    };

    return (
        <>
            <Link
                className={classes.disabledLink}
                underline="always"
                onMouseOver={onMouseOverLink}
                onMouseLeave={onMouseLeave}
                aria-haspopup="true"
                onKeyPress={onLinkKeyPress}
                tabIndex={0}
            >
                {props.children}
            </Link>
            <Popover
                onMouseOver={onMouseOverPopover}
                onMouseLeave={onMouseLeave}
                onKeyDown={onPopoverKeyDown}
                classes={{
                    root: classes.popoverRoot,
                    paper: classes.popoverPaper
                }}
                open={anchorEl !== null}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ padding: 16 }}>
                    <Typography variant="h4">
                        {props.question.present().getTitle()}
                    </Typography>
                </div>
                <div style={{
                    maxHeight: 250,
                    overflowY: 'auto',
                    padding: '0 16px',
                }}>
                    {
                        props.question.getBody()
                            .split("\n")
                            .map((paragraph: string, index: number): JSX.Element => (
                                <Typography component="p" key={index}>
                                    {paragraph}
                                </Typography>
                            ))
                    }
                </div>
                <div style={{ textAlign: 'right', padding: 16 }}>
                    {shouldBeLinkedTo && (
                        <Button
                            color="secondary"
                            onClick={onClick}
                            size="small"
                            component={Link}
                            href={makeUri(routes.QUESTIONNAIRE, {
                                id: props.questionnaire.getSubmission().getId(),
                                itemId: itemId + 1,
                            })}
                        >
                            Go to question
                        </Button>
                    )}
                </div>
            </Popover>
        </>
    );
}
