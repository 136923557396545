import React, {ChangeEvent, Component, FormEvent, FocusEvent} from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    TextField,
    Typography,
} from "@material-ui/core";
import {Link, Redirect} from "react-router-dom";
import routes from "../../routes";
import {endpoints, apiPost} from "../../api";
import zxcvbn from 'zxcvbn';
import {User} from "../../userState";
import userContext from "../../userContext";
import {withAxiosErrorChecks} from "../../Hooks/useAxiosErrorChecks";
import {AxiosError} from "axios";

interface Props {
    onError: (errors: string[]) => void,
    onSuccess: (message: string) => void,
    handleAxiosErrors?: (error: AxiosError) => void,
}

interface Errors {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmPassword: string,
    acceptedTcs: string,
}

interface State {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmPassword: string,
    marketingOptIn: boolean,
    acceptedTcs: boolean,
    submitted: boolean,
    submitLoading: boolean,
    errors: Errors,
}

class Register extends Component<Props, State> {
    static contextType = userContext;
    private confirmPasswordRef: React.RefObject<HTMLInputElement>;

    constructor(props: any) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            marketingOptIn: false,
            acceptedTcs: false,
            submitted: false,
            submitLoading: false,
            errors: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                acceptedTcs: '',
            },
        };

        this.confirmPasswordRef = React.createRef();

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onFieldBlur = this.onFieldBlur.bind(this);
        this.onFieldInvalid = this.onFieldInvalid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();

        this.setState({submitLoading: true});

        let response;

        try {
            response = await apiPost(endpoints.REGISTER, undefined, {
                'firstName': this.state.firstName,
                'lastName': this.state.lastName,
                'email': this.state.email,
                'password': this.state.password,
                'password_confirmation': this.state.confirmPassword,
                'marketing_opt_in': this.state.marketingOptIn,
            });
        } catch (error: any) {
            if (this.props.handleAxiosErrors) {
                this.props.handleAxiosErrors(error);
            }
            return;
        } finally {
            this.setState({ submitLoading: false });
        }

        const user: User = {
            ...this.context.user,
            email: response.data.user.email,
            firstName: response.data.user.first_name,
            lastName: response.data.user.last_name,
            token: response.data.token,
        };
        this.context.setUser(user);

        this.props.onSuccess('You have been sent an email to confirm your email address');

        this.setState({
            submitted: true,
        });
    }

    onFieldChange(e: ChangeEvent<HTMLInputElement>): void {
        const element = e.currentTarget;
        const newState: State = {
            ...this.state
        };

        switch (element.id) {
            case 'firstName':
                newState.firstName = element.value;
                newState.errors.firstName = '';
                break;
            case 'lastName':
                newState.lastName = element.value;
                newState.errors.lastName = '';
                break;
            case 'email':
                newState.email = element.value;
                newState.errors.email = '';
                break;
            case 'password':
                newState.password = element.value;
                newState.errors.password = '';
                newState.errors.confirmPassword = '';
                break;
            case 'confirmPassword':
                newState.confirmPassword = element.value;
                newState.errors.confirmPassword = '';
                break;
            case 'marketingOptIn':
                newState.marketingOptIn = element.checked;
                break;
            case 'acceptedTcs':
                newState.acceptedTcs = element.checked;
                newState.errors.acceptedTcs = '';
                break;
            default:
                throw new Error(`Unknown field: ${element.id}`);
        }

        this.setState(
            newState,
            (): void => {
                this.validateField(element);
            });
    }

    onFieldBlur(e: FocusEvent<HTMLInputElement>): void {
        this.validateField(e.target);
    }

    validateField(inputField: HTMLInputElement): void {
        if (inputField.id === 'password') {
            this.validatePasswordStrength(inputField);

            const confirmField = this.confirmPasswordRef.current?.querySelector('input[type=password]') as HTMLInputElement;
            if (confirmField && this.state.confirmPassword !== '') {
                this.validateField(confirmField);
            }
        }

        if (inputField.id === 'confirmPassword') {
            this.validatePasswordsMatch(inputField);
        }

        inputField.checkValidity();
    }

    validatePasswordStrength(inputField: HTMLInputElement): void {
        if (this.state.password === '') {
            inputField.setCustomValidity('');
            return;
        }

        const result = zxcvbn(this.state.password);
        if (result.score < 3) {
            let error = "Password too weak";

            if (result.feedback.warning !== '') {
                error += ': ' + result.feedback.warning;
            } else if (result.feedback.suggestions.length !== 0) {
                error += ': ' + result.feedback.suggestions[0];
            }

            inputField.setCustomValidity(error);
        } else {
            inputField.setCustomValidity('');
        }
    }

    validatePasswordsMatch(confirmField: HTMLInputElement): void {
        if (this.state.confirmPassword !== this.state.password) {
            confirmField.setCustomValidity("Passwords don't match");
        } else {
            confirmField.setCustomValidity('');
        }
    }

    onFieldInvalid(e: FormEvent<HTMLInputElement>): void {
        e.preventDefault();

        const errors = this.state.errors;

        // @ts-ignore
        errors[e.target.id] = e.target.validationMessage;

        this.setState(oldState => {
            return {
                ...oldState,
                errors: errors,
            };
        });
    }

    render(): JSX.Element {
        if (this.state.submitted === true) {
            return (
                <Redirect to={routes.HOME}/>
            );
        }

        const submitButton = (this.state.submitLoading) ? (
            <Button type="submit" variant="contained" color="secondary" size="medium" disabled>
                Registering..
                <CircularProgress size={20} color="inherit" style={{marginLeft: 10}}></CircularProgress>
            </Button>
        ) : (
            <Button type="submit" variant="contained" color="secondary" size="medium">
                Register
            </Button>
        );

        return (
            <Container maxWidth="sm">
                <form onSubmit={this.onSubmit}>
                    <Typography variant="h2">Register</Typography>

                    <TextField
                        required
                        id="firstName"
                        label="First Name"
                        type="text"
                        fullWidth
                        margin="normal"
                        value={this.state.firstName}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        onInvalid={this.onFieldInvalid}
                        error={this.state.errors.firstName !== ''}
                        helperText={this.state.errors.firstName}
                        inputProps={{maxLength: 255}}
                    />

                    <TextField
                        required
                        id="lastName"
                        label="Last Name"
                        type="text"
                        fullWidth
                        margin="normal"
                        value={this.state.lastName}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        onInvalid={this.onFieldInvalid}
                        error={this.state.errors.lastName !== ''}
                        helperText={this.state.errors.lastName}
                        inputProps={{maxLength: 255}}
                    />

                    <TextField
                        required
                        id="email"
                        label="Business Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        value={this.state.email}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        onInvalid={this.onFieldInvalid}
                        error={this.state.errors.email !== ''}
                        helperText={this.state.errors.email}
                        inputProps={{maxLength: 255}}
                    />

                    <TextField
                        required
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={this.state.password}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        onInvalid={this.onFieldInvalid}
                        error={this.state.errors.password !== ''}
                        helperText={this.state.errors.password}
                    />

                    <TextField
                        required
                        id="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        ref={this.confirmPasswordRef}
                        fullWidth
                        margin="normal"
                        value={this.state.confirmPassword}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        onInvalid={this.onFieldInvalid}
                        error={this.state.errors.confirmPassword !== ''}
                        helperText={this.state.errors.confirmPassword}
                        />

                    <p>We will process your data in accordance with our <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.psacertified.org/about/legal/privacy-policy/"
                        >
                        Privacy Policy
                    </a>.</p>

                    <FormControl
                        fullWidth
                        margin="normal"
                        onBlur={this.onFieldBlur}
                    >
                        <FormControlLabel
                            control={<Checkbox
                                id="marketingOptIn"
                                checked={this.state.marketingOptIn}
                                onChange={this.onFieldChange}
                            />}
                            label={(
                                <span>
                                    By ticking this box you indicate your consent to receiving marketing
                                    communications from PSA Certified and you confirm you have read and accept
                                    our <a
                                        target="_blank"
                                        rel="noreferrer"
                                        onBlur={(e: FocusEvent): void => e.stopPropagation()}
                                        href="https://www.psacertified.org/about/legal/privacy-policy/"
                                    >
                                        Privacy Policy</a>.
                                    The data captured by PSA Certified is currently owned by Arm Ltd.
                                </span>
                            )}
                            />
                    </FormControl>

                    <FormControl
                        fullWidth
                        margin="normal"
                        error={this.state.errors.acceptedTcs !== ''}
                        onBlur={this.onFieldBlur}
                        onInvalid={this.onFieldInvalid}
                    >
                        <FormControlLabel
                            control={<Checkbox
                                id="acceptedTcs"
                                checked={this.state.acceptedTcs}
                                onChange={this.onFieldChange}
                                required
                            />}
                            label={(
                                <span>I agree to the <a
                                        target="_blank"
                                        rel="noreferrer"
                                        onBlur={(e: FocusEvent): void => e.stopPropagation()}
                                        href="https://www.psacertified.org/about/legal/terms/"
                                    >
                                        terms and conditions of use
                                    </a>
                                    *
                                </span>
                                )}
                            />
                        <FormHelperText>{this.state.errors.acceptedTcs}</FormHelperText>
                    </FormControl>

                    {submitButton}
                </form>
                <p>
                    Already registered? <Link to={routes.LOGIN}>Sign in</Link>
                </p>
            </Container>
        );
    }
}

export default withAxiosErrorChecks(Register);
