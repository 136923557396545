import React, {ChangeEvent, Component} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from "@material-ui/core";
import QuestionObj from '../../DataObjects/Question';
import QuestionSupplement from "./partials/QuestionSupplement";
import QuestionCertifications from "./partials/QuestionCertifications";
import Submission from "../../DataObjects/Submission";
import AnswerMulti, {ResponseType} from "../../DataObjects/AnswerMulti";
import QuestionBody from "./partials/QuestionBody";
import Questionnaire from "../../DataObjects/Questionnaire";
import QuestionnaireItemInterface from "../../DataObjects/QuestionnaireItemInterface";
import QuestionCertification from "../../DataObjects/QuestionCertification";
import {getIntEnv} from "../../Config/ConfigUtils";
import {SectionType} from "../../DataObjects/Section";
import Supplement from "./partials/Supplement";

interface Props {
    question: QuestionObj;
    submission: Submission;
    questionnaire: Questionnaire;
    items: QuestionnaireItemInterface[];
    firstQuestion?: boolean;
    onSave: (newQuestions: QuestionObj[]) => void;
    onNextClick: () => void;
    onPrevClick: () => void;
    onNavigateToQuestionClick: (question: QuestionObj) => void;
}

interface State {
    response: string|null,
    body: string,
    dirty: boolean;
}

class Question extends Component<Props, State> {
    private tabFocusRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            response: null,
            body: '',
            dirty: false,
        };

        this.tabFocusRef = React.createRef();

        this.onCloseApp = this.onCloseApp.bind(this);
    }

    componentDidMount(): void {
        this.initialiseState();
        if (this.tabFocusRef.current !== null) {
            this.tabFocusRef.current.focus();
        }
        window.addEventListener("beforeunload", this.onCloseApp);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.question !== this.props.question) {
            if (this.state.dirty) {
                this.save(prevProps.question, prevState);
            }
            this.initialiseState();
            if (this.tabFocusRef.current !== null) {
                this.tabFocusRef.current.focus();
            }
        }
    }

    componentWillUnmount(): void {
        if (this.state.dirty) {
            this.save(this.props.question, this.state);
        }
        window.removeEventListener("beforeunload", this.onCloseApp);
    }

    onCloseApp(e: Event): string {
        if (!this.state.dirty) {
            return '';
        }

        const message = 'Are you sure you want to leave with unsaved changes?';

        if (e) {
            e.returnValue = false;
        }

        return message;
    }

    initialiseState(): void {
        const newState: State = {
            response: null,
            body: '',
            dirty: false,
        };

        if (this.props.question.hasAnswer()) {
            const answer = this.props.question.getAnswer() as AnswerMulti;
            newState.response = answer.hasResponse() ? answer.getResponse() : null;
            newState.body = answer.getBody();
        }

        this.setState(newState);
    }

    getAnswerDataFromState(state: State): AnswerMulti {
        return AnswerMulti.make({
            response: state.response as ResponseType ?? undefined,
            body: state.body,
        });
    }

    save(question: QuestionObj, state: State): void {
        const newAnswer = this.getAnswerDataFromState(state);
        const newQuestion = QuestionObj.make({
            id: question.getId(),
            number: question.getNumber(),
            name: question.getName(),
            body: question.getBody(),
            type: question.getType(),
            sort: question.getSort(),
            notes: question.getNotes(),
            examples: question.getExamples(),
            certifications: question.getCertifications(),
            section: question.getSection(),
            answer: newAnswer,
        });

        this.props.onSave([newQuestion]);
    }

    render(): JSX.Element {
        const question = this.props.question;

        const psaL1Cert: QuestionCertification|undefined = question.getCertifications()
            .find((certification: QuestionCertification): boolean => certification.getCertId() === getIntEnv('REACT_APP_CERTIFICATION_PSA_L1'));

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card raised={true} style={{marginTop: '10px'}}>
                        <CardHeader
                            title={question.present().getTitle()}
                            titleTypographyProps={{variant: 'h1'}}
                        />
                        <CardContent>
                            <QuestionCertifications
                                question={this.props.question}
                            />
                            <QuestionBody
                                question={this.props.question}
                                questionnaire={this.props.questionnaire}
                                items={this.props.items}
                                onNavigateToQuestionClick={this.props.onNavigateToQuestionClick}
                            />
                            <form style={{marginTop: '15px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <FormControl component="fieldset">
                                            <FormLabel
                                                component="legend"
                                                required={psaL1Cert !== undefined}
                                            >
                                                <strong>Answer</strong>
                                            </FormLabel>
                                            <RadioGroup
                                                aria-label="answer"
                                                name="answer"
                                                value={this.state.response}
                                                onChange={(e: ChangeEvent<HTMLInputElement>): void => this.setState({response: e.currentTarget.value, dirty: true})}
                                            >
                                                <FormControlLabel value="YES" control={<Radio/>} label="Yes" inputRef={this.tabFocusRef}/>
                                                <FormControlLabel value="PARTIAL" control={<Radio/>} label="Partial"/>
                                                <FormControlLabel value="N/A" control={<Radio/>} label="N/A"/>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}
                                    >
                                        {
                                            psaL1Cert !== undefined && (
                                                <div style={{
                                                    marginBottom: '1rem'
                                                }}>
                                                    <strong>PSA Certified Level 1 Expected Answer:</strong><br/>
                                                    {
                                                        psaL1Cert
                                                            .present()
                                                            .getPassRequirement()
                                                    }
                                                </div>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                                <div>
                                    <TextField
                                        required={psaL1Cert !== undefined}
                                        id="standard-multiline-flexible"
                                        label={(psaL1Cert !== undefined) ? (<strong>Justification (required for PSA Certified Level 1)</strong>) : (<strong>Justification</strong>)}
                                        multiline
                                        minRows={4}
                                        value={this.state.body}
                                        onChange={(e: ChangeEvent<HTMLInputElement>): void => this.setState({body: e.currentTarget.value, dirty: true})}
                                        variant="outlined"
                                        style={{width: '100%'}}
                                        inputProps={{maxLength: 65535}}
                                    />
                                </div>
                            </form>
                            <Grid container direction="row-reverse" spacing={3} style={{marginTop: '15px'}}>
                                <Grid item style={{flexGrow: 1}}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{float: 'right'}}
                                        onClick={this.props.onNextClick}
                                    >
                                        Next Question
                                    </Button>
                                </Grid>
                                <Grid item style={{flexGrow: 1}}>
                                    <Button
                                        variant="contained"
                                        onClick={this.props.onPrevClick}
                                        disabled={this.props.firstQuestion === true}
                                    >
                                        Previous Question
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {this.props.question.getSection().getType() !== SectionType.BestPractice && (
                    <QuestionSupplement
                        question={this.props.question}
                    />
                )}

                {this.props.question.getSection().getType() === SectionType.BestPractice && (
                    <Supplement>
                        <p><strong>Organizational Best Practices</strong></p>
                        <p>In addition to the technical security measures that are in the scope of PSA Certified Level 1 covered in the chip, software and device sections, this appendix lists organizational best practices that contribute to comprehensive device security. These are collated from EN 303 645, NIST 8259A, SB-327 and UK DCMS.</p>
                        <p>The organizational best practices in this section reflect common requirements that appear in many standards and are, or are likely to become, legal requirements in many territories. Verification of compliance to these organizational best practices by the evaluation laboratory during a PSA Certified Level 1 evaluation is optional but recommended.</p>
                    </Supplement>
                )}
            </Grid>
        );
    }
}

export default Question;
