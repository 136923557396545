import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import routes from "../../routes";
import userContext from "../../userContext";

interface Props {
    onSuccess: (message: string) => void,
}

class Logout extends Component<Props, {}> {
    static contextType = userContext;

    componentDidMount(): void {
        this.context.logOut();

        this.props.onSuccess('Logged out');
    }

    render(): JSX.Element {
        return (
            <Redirect to={routes.LOGIN}></Redirect>
        );
    }
}

export default Logout;
