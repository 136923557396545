import Version from "./Version";
import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";
import SubmissionPresenter from "../Presenters/SubmissionPresenter";

export enum SubmissionType {
    Device = 'DEVICE',
    Software = 'SOFTWARE',
    Chip = 'CHIP',
}

interface FactoryProps {
    id?: number;
    type?: SubmissionType;
    finalised?: boolean;
    name?: string;
    version?: Version;
}

export default class Submission {
    protected presenter: SubmissionPresenter;
    protected id?: number;
    protected type?: SubmissionType;
    protected finalised?: boolean;
    protected name?: string;
    protected version?: Version;

    constructor() {
        this.presenter = new SubmissionPresenter(this);
    }

    present(): SubmissionPresenter {
        return this.presenter;
    }

    static make(submissionData: FactoryProps): Submission {
        const submission = new this();
        submission.id = submissionData.id;
        submission.type = (submissionData.type as SubmissionType);
        submission.finalised = submissionData.finalised;
        submission.name = submissionData.name;
        submission.version = submissionData.version;

        return submission;
    }

    getId(): number {
        if (typeof this.id === 'undefined') {
            throw new FieldNotDefinedError('id');
        }

        return this.id;
    }

    getType(): SubmissionType {
        if (typeof this.type === 'undefined') {
            throw new FieldNotDefinedError('type');
        }

        return this.type;
    }

    getVersion(): Version {
        if (typeof this.version === 'undefined') {
            throw new FieldNotDefinedError('version');
        }

        return this.version;
    }

    isFinalised(): boolean {
        if (typeof this.finalised === 'undefined') {
            throw new FieldNotDefinedError('finalised');
        }

        return this.finalised === true;
    }

    isInProgress(): boolean {
        return this.isFinalised() === false;
    }

    getName(): string {
        if (typeof this.name === 'undefined') {
            throw new FieldNotDefinedError('name');
        }

        return this.name;
    }

    hasName(): boolean {
        return typeof this.name !== 'undefined';
    }
}
