import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";

interface FactoryProps {
    id?: number;
    versionNumber?: string;
    isLatest?: boolean;
}

export default class Version {
    protected id?: number;
    protected versionNumber?: string;
    protected latest?: boolean;

    static make(versionData: FactoryProps): Version {
        const version = new this();

        version.id = versionData.id;
        version.versionNumber = versionData.versionNumber;
        version.latest = versionData.isLatest;

        return version;
    }

    getId(): number {
        if (typeof this.id === 'undefined') {
            throw new FieldNotDefinedError('id');
        }

        return this.id;
    }

    getVersionNumber(): string {
        if (typeof this.versionNumber === 'undefined') {
            throw new FieldNotDefinedError('versionNumber');
        }

        return this.versionNumber;
    }

    isLatest(): boolean {
        if (typeof this.latest === 'undefined') {
            throw new FieldNotDefinedError('latest');
        }

        return this.latest;
    }
}
