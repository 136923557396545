import {apiGet, endpoints} from "../api";
import Question, {QuestionType} from "../DataObjects/Question";
import Section, {AlreadyCertifiedState, SectionType} from "../DataObjects/Section";
import IntroQuestionGroup from "../DataObjects/IntroQuestionGroup";
import QuestionnaireItemInterface from "../DataObjects/QuestionnaireItemInterface";
import Questionnaire from "../DataObjects/Questionnaire";
import Version from "../DataObjects/Version";
import Submission from "../DataObjects/Submission";
import {alreadyCertifiedFieldIds, showIfAlreadyCertifiedFieldIds} from "../Config/IntroFieldTypes";
import {InvalidQuestionItemIndexError} from "../Errors/InvalidQuestionItemIndexError";
import {CannotAccessFirstQuestionError} from "../Errors/CannotAccessFirstQuestionError";
import {YesNoChoice} from "../DataObjects/YesNoChoice";
import SectionReview from "../DataObjects/SectionReview";
import {sectionsWithReviewIds} from "../Config/SectionConfig";
import FinalisePage from "../DataObjects/FinalisePage";
import AnswerMulti, {ResponseType} from "../DataObjects/AnswerMulti";
import {getIntEnv} from "../Config/ConfigUtils";
import QuestionNote from "../DataObjects/QuestionNote";
import QuestionExample from "../DataObjects/QuestionExample";
import QuestionCertification, {PassRequirementType} from "../DataObjects/QuestionCertification";
import AnswerFactory from "../Factories/AnswerFactory";
import SectionReport from "../DataObjects/SectionReport";
import AnswerCheck from "../DataObjects/AnswerCheck";
import {InvalidQuestionTypeError} from "../Errors/InvalidQuestionTypeError";
import Answer from "../DataObjects/Answer";
import ChipPreamblePage from "../DataObjects/ChipPreamblePage";
import SoftwarePreamblePage from "../DataObjects/SoftwarePreamblePage";
import DevicePreamblePage from "../DataObjects/DevicePreamblePage";
import {Page} from "../DataObjects/Page";
import BestPracticePreamblePage from "../DataObjects/BestPracticePreamblePage";

export default class QuestionnaireService {

    public async loadData(questionnaireId: number): Promise<Questionnaire> {
        const response = await apiGet(endpoints.QUESTIONNAIRE_SINGLE, {
            id: questionnaireId,
        });
        const data = response.data;

        const submission = Submission.make(data.submission);
        const version = Version.make(data.version);
        const questions: Question[] = data.questions.map((questionData: {[key: string]: any}): Question => {
            if (typeof questionData.notes !== 'undefined') {
                questionData.notes = questionData.notes.map((noteData: any) => QuestionNote.make(noteData));
            }
            if (typeof questionData.examples !== 'undefined') {
                questionData.examples = questionData.examples.map((exampleData: any) => QuestionExample.make(exampleData));
            }
            if (typeof questionData.certifications !== 'undefined') {
                questionData.certifications = questionData.certifications.map(
                    (certificationData: any) => QuestionCertification.make(certificationData)
                );
            }
            if (typeof questionData.section !== 'undefined') {
                questionData.section = Section.make(questionData.section);
            }
            if (typeof questionData.answer !== 'undefined') {
                questionData.answer = AnswerFactory.make(questionData.answer);
            }

            return Question.make(questionData);
        });

        let sections = this.extractSections(questions);
        sections.push(Section.make({
            id: 99,
            type: SectionType.Finalise,
            sort: Infinity,
        }));
        sections = this.markAlreadyCertifiedSections(sections, questions);
        sections = this.sortSections(sections);

        return new Questionnaire(
            submission,
            version,
            sections,
            questions,
        );
    }

    public generatePageItems(questionnaire: Questionnaire): QuestionnaireItemInterface[] {
        let items = this.groupIntroQuestions(questionnaire.getQuestions(), questionnaire.getSections());
        items = this.insertSectionReviewPages(items, questionnaire);
        items = this.insertSectionPreamblePages(items, questionnaire);
        items = this.insertFinalisePage(items, questionnaire);
        items = this.sortItems(items);

        return items;
    }

    public getApplicableQuestions(items: QuestionnaireItemInterface[], questionnaire: Questionnaire): Question[] {
        return questionnaire
            .getQuestions()
            .filter((item: Question): boolean => this.itemShouldBeShown(item, items, questionnaire));
    }

    public getNextPageIndex(currentIndex: number, items: QuestionnaireItemInterface[], questionnaire: Questionnaire): number {
        const remainingPageIndex = items
            .slice(currentIndex + 1)
            .findIndex((item: QuestionnaireItemInterface) => this.itemShouldBeShown(item, items, questionnaire));

        if (remainingPageIndex === -1) {
            throw new InvalidQuestionItemIndexError("Could not find a viable next question");
        }

        return currentIndex + remainingPageIndex + 1;
    }

    public getPreviousPageIndex(currentIndex: number, items: QuestionnaireItemInterface[], questionnaire: Questionnaire): number {
        const remainingPageIndex = items
            .slice(0, currentIndex)
            .reverse()
            .findIndex((item: QuestionnaireItemInterface) => this.itemShouldBeShown(item, items, questionnaire));

        if (remainingPageIndex === -1) {
            throw new InvalidQuestionItemIndexError("Could not find a viable previous question");
        }

        return currentIndex - remainingPageIndex - 1;
    }

    /**
     * Find the first viable page in the section
     */
    public getPageIndexBySection(section: Section, items: QuestionnaireItemInterface[], questionnaire: Questionnaire): number {
        const index = items.findIndex((item: QuestionnaireItemInterface) => {
            return item.getSection().getId() === section.getId() && this.itemShouldBeShown(item, items, questionnaire);
        });

        if (index === -1) {
            throw new InvalidQuestionItemIndexError("Cannot jump to section, as no viable page available");
        }

        return index;
    }

    public getReviewPageIndexBySection(section: Section, items: QuestionnaireItemInterface[], questionnaire: Questionnaire): number {
        const index = items.findIndex((item: QuestionnaireItemInterface) => {
            return item instanceof SectionReview
                && item.getSection().getId() === section.getId()
                && this.itemShouldBeShown(item, items, questionnaire);
        });

        if (index !== -1) {
            return index;
        }

        return this.getPageIndexBySection(section, items, questionnaire);
    }

    public getPageIndex(target: QuestionnaireItemInterface, items: QuestionnaireItemInterface[], questionnaire: Questionnaire): number {
        const index = items.findIndex((item: QuestionnaireItemInterface): boolean => {
            if (item instanceof Question && target instanceof Question) {
                return item.getId() === target.getId();
            }

            if (item instanceof IntroQuestionGroup && target instanceof Question) {
                return item
                    .getFields()
                    .some((field: Question): boolean => field.getId() === target.getId());
            }

            if (item === target) {
                return true;
            }

            return false;
        });

        if (index === -1) {
            throw new InvalidQuestionItemIndexError("Cannot jump to question, as no viable page available");
        }

        return index;
    }

    public canNavToPageIndex(index: number, items: QuestionnaireItemInterface[], questionnaire: Questionnaire): boolean {
        if (typeof items[index] === 'undefined') {
            return false;
        }

        const item: QuestionnaireItemInterface = items[index];

        const canNav = this.itemShouldBeShown(item, items, questionnaire);

        if (index === 0 && !canNav) {
            throw new CannotAccessFirstQuestionError();
        }

        return canNav;
    }

    public itemShouldBeShown(item: QuestionnaireItemInterface, items: QuestionnaireItemInterface[], questionnaire: Questionnaire): boolean {
        if (item instanceof SectionReview) {
            return true;
        }

        if (item instanceof Page) {
            return true;
        }

        const keyedSections = this.getKeyedSections(questionnaire.getSections());
        const section = keyedSections[item.getSection().getId()];

        if (item instanceof IntroQuestionGroup) {
            if (item.getSection().getAlreadyCertifiedState() !== AlreadyCertifiedState.Yes) {
                return item
                    .getFields()
                    .some((question: Question): boolean => this.introFieldShouldShowBasedOnSection(question, item.getSection()));
            }

            return item
                .getFields()
                .some((question: Question): boolean => alreadyCertifiedFieldIds.includes(question.getId()));
        }

        if (item instanceof FinalisePage) {
            return true;
        }

        if (item instanceof Question && item.getType() === QuestionType.Text) {
            return this.introFieldShouldShowBasedOnSection(item, section);
        }

        if (questionnaire.getVersion().getId() === getIntEnv('REACT_APP_VERSION_2.1_ID')) {
            if (item instanceof Question && getIntEnv('REACT_APP_VERSION_2.1_QUESTION_D3.5_ID') === item.getId()) {
                const questionD34 = items
                    .filter((item: QuestionnaireItemInterface): item is Question => item instanceof Question)
                    .find((item: Question): boolean => {
                        return item.getId() === getIntEnv('REACT_APP_VERSION_2.1_QUESTION_D3.4_ID');
                    });

                if (typeof questionD34 === 'undefined') {
                    throw new Error(`Can't find Question D3.4`);
                }

                if (questionD34.hasAnswer() && questionD34.getAnswer().hasResponse()) {
                    return questionD34.getAnswer().getResponse() !== ResponseType.NA;
                }
            }
        }

        if (section.getAlreadyCertifiedState() === AlreadyCertifiedState.NA) {
            return true;
        }

        if (section.getAlreadyCertifiedState() !== AlreadyCertifiedState.No) {
            return false;
        }

        return true;
    }

    public updateQuestionsData(questionnaire: Questionnaire, newQuestions: Question[]): Questionnaire {
        const questions = questionnaire.getQuestions();

        newQuestions.forEach((newQuestion: Question): void => {
            const index = questions.findIndex((i: Question): boolean => i.getId() === newQuestion.getId());

            if (typeof index === 'undefined') {
                return;
            }

            questions.splice(index, 1, newQuestion);
        });

        return new Questionnaire(
            questionnaire.getSubmission(),
            questionnaire.getVersion(),
            questionnaire.getSections(),
            questions,
        );
    }

    public updateSubmissionName(questionnaire: Questionnaire, newName: string): Questionnaire {
        const newSubmission = Submission.make({
            id: questionnaire.getSubmission().getId(),
            type: questionnaire.getSubmission().getType(),
            finalised: questionnaire.getSubmission().isFinalised(),
            name: newName,
            version: questionnaire.getSubmission().getVersion(),
        });

        return new Questionnaire(
            newSubmission,
            questionnaire.getVersion(),
            questionnaire.getSections(),
            questionnaire.getQuestions(),
        );
    }

    public introFieldShouldShowBasedOnSection(question: Question, section: Section): boolean {
        if (section.getAlreadyCertifiedState() === AlreadyCertifiedState.NA) {
            return !showIfAlreadyCertifiedFieldIds.includes(question.getId());
        }

        if (alreadyCertifiedFieldIds.includes(question.getId())) {
            return true;
        }

        if (section.getAlreadyCertifiedState() === AlreadyCertifiedState.Yes) {
            return showIfAlreadyCertifiedFieldIds.includes(question.getId());
        }

        if (section.getAlreadyCertifiedState() === AlreadyCertifiedState.Undecided) {
            return alreadyCertifiedFieldIds.includes(question.getId());
        }

        return section.getAlreadyCertifiedState() === AlreadyCertifiedState.No
            && !showIfAlreadyCertifiedFieldIds.includes(question.getId());
    }

    public checkAnswer(question: Question): AnswerCheck {
        if (question.getType() === QuestionType.Text) {
            if (question.hasAnswer() === false) {
                return new AnswerCheck(false, false, 'No answer given');
            }

            const valid = question.getAnswer().hasResponse() && question.getAnswer().getResponse().trim() !== '';
            return new AnswerCheck(valid, valid, valid ? undefined:'No answer given');
        }

        if (question.getType() === QuestionType.Multi) {
            // Not linked to any certifications
            if (!question.hasCertifications()) {
                return new AnswerCheck(question.hasAnswer(), true);
            }

            // Not linked to PSA certification
            if (question.isRequiredForPsaL1() === false) {
                return new AnswerCheck(question.hasAnswer(), true);
            }

            if (question.hasAnswer() === false) {
                return new AnswerCheck(false, !question.isRequiredForPsaL1(), 'No answer given');
            }

            const answer = question.getAnswer() as AnswerMulti;

            // Check for partial responses
            if (!answer.hasResponse()) {
                return new AnswerCheck(true, false, 'No multiple choice answer given');
            } else if (!answer.hasBody() || answer.getBody().trim() === '') {
                return new AnswerCheck(true, false, 'No justification given');
            }

            // Check for PSA L1 validity
            const psaL1 = question
                .getCertifications()
                .find((cert: QuestionCertification): boolean => cert.getCertId() === getIntEnv('REACT_APP_CERTIFICATION_PSA_L1'));

            if (typeof psaL1 === 'undefined') {
                throw new Error('Unable to find PSA L1 certification');
            }

            return this.checkPassRequirement(psaL1, answer);
        }

        throw new InvalidQuestionTypeError(question.getType());
    }

    public checkPassRequirement(questionCertification: QuestionCertification, answer: Answer): AnswerCheck {
        const friendlyAnswer: {[key: string]: string} = {
            'YES': 'Yes',
            'PARTIAL': 'Partial',
            'N/A': 'N/A',
        };

        if (questionCertification.getPassRequirement() === PassRequirementType.Yes && answer.getResponse() !== ResponseType.Yes) {
            return new AnswerCheck(
                true,
                false,
                `Expected ${questionCertification.getCertName()} Answer: Yes. Given: ${friendlyAnswer[answer.getResponse()]}`
            );
        } else if (questionCertification.getPassRequirement() === PassRequirementType.NotPartial && answer.getResponse() === ResponseType.Partial) {
            return new AnswerCheck(
                true,
                false,
                `Expected ${questionCertification.getCertName()} Answer: Yes or N/A. Given: ${friendlyAnswer[answer.getResponse()]}`
            );
        }

        return new AnswerCheck(true,true);
    }

    protected getKeyedSections(sections: Section[]): {[key: number]: Section} {
        return sections.reduce((keyed: {[key: number]: Section}, section: Section): {[key: number]: Section} => {
            keyed[section.getId()] = section;

            return keyed;
        }, {});
    }

    protected extractSections(questions: Question[]): Section[] {
        return questions.reduce((sections: Section[], question: Question): Section[] => {
            if (sections.find((section) => section.getId() === question.getSection().getId())) {
                return sections;
            }

            sections.push(question.getSection());
            return sections;
        }, []);
    }

    protected groupIntroQuestions(questions: Question[], sections: Section[]): QuestionnaireItemInterface[] {
        const keyedSections = this.getKeyedSections(sections);

        let keyedGroups: {[key: string]: IntroQuestionGroup} = questions
            .sort((a: Question, b: Question): number => a.getSort() - b.getSort())
            .filter((question: Question): boolean => question.getType() !== QuestionType.Multi)
            .reduce((keyedGroups: {[key: string]: IntroQuestionGroup}, question: Question): {[key: string]: IntroQuestionGroup} => {
                const section = keyedSections[question.getSection().getId()];
                let group = keyedGroups[question.getSection().getId() + '-' + question.getNumber()];

                let groupQuestions = (typeof group !== 'undefined') ? group.getFields() : [];
                groupQuestions.push(question);

                keyedGroups[question.getSection().getId() + '-' + question.getNumber()] = new IntroQuestionGroup(section, groupQuestions);

                return keyedGroups;
            }, {});

        const introQuestionsGroups = Object.values(keyedGroups);

        let items: QuestionnaireItemInterface[] = questions.filter(
            (question: Question): boolean => question.getType() === QuestionType.Multi
        );

        return items.concat(introQuestionsGroups);
    }

    protected markAlreadyCertifiedSections(sections: Section[], questions: Question[]): Section[] {
        return questions
            .filter((question: Question): boolean => alreadyCertifiedFieldIds.includes(question.getId()))
            .reduce((sections: Section[], question: Question) => {
                const section = sections.find((i: Section) => i.getId() === question.getSection().getId());
                if (typeof section === 'undefined') {
                    return sections;
                }
                const sectionIndex = sections.findIndex((i: Section): boolean => i.getId() === section.getId());

                let newAlreadyCertifiedState;

                if (!question.hasAnswer()) {
                    newAlreadyCertifiedState = AlreadyCertifiedState.Undecided;
                } else if (question.getAnswer().getResponse() === YesNoChoice.Yes) {
                    newAlreadyCertifiedState = AlreadyCertifiedState.Yes;
                } else {
                    newAlreadyCertifiedState = AlreadyCertifiedState.No;
                }

                const newSection = Section.make({
                    id: section.getId(),
                    type: section.getType(),
                    description: section.getDescription(),
                    sort: section.getSort(),
                    alreadyCertifiedState: newAlreadyCertifiedState,
                });

                sections.splice(sectionIndex, 1, newSection);

                return sections;
            }, sections);
    }

    protected sortItems(items: QuestionnaireItemInterface[]): QuestionnaireItemInterface[] {
        return items.sort((a: QuestionnaireItemInterface, b: QuestionnaireItemInterface): number => {
            if (a.getSection().getId() !== b.getSection().getId()) {
                return a.getSection().getSort() - b.getSection().getSort();
            }

            return a.getSort() - b.getSort();
        });
    }

    protected sortSections(sections: Section[]): Section[] {
        return sections.sort((a: Section, b: Section): number =>  a.getSort() - b.getSort());
    }

    protected insertSectionReviewPages(items: QuestionnaireItemInterface[], questionnaire: Questionnaire): QuestionnaireItemInterface[] {
        questionnaire.getSections()
            .filter((section: Section): boolean => sectionsWithReviewIds.includes(section.getId()))
            .forEach((section: Section): void => {
                const reviewQuestions = questionnaire.getQuestions()
                    .filter((question: Question): boolean => question.getSection().getId() === section.getId())
                    .filter((question: Question): boolean => this.itemShouldBeShown(question, items, questionnaire))
                    .filter((question: Question): boolean => {
                        return question.isRequiredForPsaL1() && (!this.checkAnswer(question).isAnswered() || !this.checkAnswer(question).isValid());
                    })
                    .sort((a: Question, b: Question): number => a.getSort() - b.getSort());
                const reviewItems = this.groupIntroQuestions(reviewQuestions, questionnaire.getSections());

                const sectionReview = new SectionReview(section, reviewItems);

                items.push(sectionReview);
            });

        return items;
    }

    protected insertSectionPreamblePages(items: QuestionnaireItemInterface[], questionnaire: Questionnaire): QuestionnaireItemInterface[] {
        questionnaire.getSections()
            .forEach((section: Section): void => {
                if (section.getType() === SectionType.Software) {
                    items.push(new SoftwarePreamblePage(section));
                    return;
                }

                if (section.getType() === SectionType.Device) {
                    items.push(new DevicePreamblePage(section));
                    return;
                }

                if (section.getType() === SectionType.Chip) {
                    items.push(new ChipPreamblePage(section));
                    return;
                }

                if (section.getType() === SectionType.BestPractice) {
                    items.push(new BestPracticePreamblePage(section));
                    return;
                }
            });

        return items;
    }

    protected insertFinalisePage(items: QuestionnaireItemInterface[], questionnaire: Questionnaire): QuestionnaireItemInterface[] {
        const finaliseSection = questionnaire.getSections()
            .find((section: Section): boolean => section.getType() === SectionType.Finalise);

        if (typeof finaliseSection === 'undefined') {
            return items;
        }

        const questions = this.getApplicableQuestions(items, questionnaire);
        const sectionReports = questionnaire.getSections()
            .filter((section: Section): boolean => section.getType() !== SectionType.Finalise)
            .map((section: Section): SectionReport => {
                const sectionQuestions = questions.filter((question: Question): boolean => question.getSection().getId() === section.getId());
                const unansweredQuestions = sectionQuestions
                    .filter((question: Question): boolean => question.isRequiredForPsaL1() && this.checkAnswer(question).isAnswered() === false);
                const invalidQuestions = sectionQuestions
                    .filter((question: Question): boolean => question.isRequiredForPsaL1() && this.checkAnswer(question).isValid() === false);

                return new SectionReport(
                    section,
                    unansweredQuestions,
                    invalidQuestions,
                );
            })
            .sort((a: SectionReport, b: SectionReport): number => a.getSection().getSort() - b.getSection().getSort());

        const finalisePage = new FinalisePage(
            sectionReports,
            finaliseSection,
        );
        items.push(finalisePage);

        return items;
    }
}
