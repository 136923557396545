import QuestionnaireItemInterface from "./QuestionnaireItemInterface";
import Section from "./Section";

export abstract class Page implements QuestionnaireItemInterface {
    constructor(
        protected section: Section,
    ) {}

    getSection(): Section {
        return this.section;
    }

    getSort(): number {
        return -1;
    }

    abstract getNavTitle(): string;
}