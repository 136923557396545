export interface User {
    email?: string,
    firstName?: string,
    lastName?: string,
    token?: string,
    verified?: boolean,
}

export interface UserState {
    user: User|null,
    loggedIn: () => boolean,
    setUser: (user: User) => void,
    logOut: () => void,
    isEmailVerified: () => boolean,
}

export const defaults: UserState = {
    user: null,
    loggedIn: function(): boolean {
        return this.user !== null && (typeof this.user.token !== 'undefined' && this.user.token !== null);
    },
    setUser: function(user: User): void { },
    logOut: function(): void { },
    isEmailVerified: function(): boolean {
        return this.user?.verified === true;
    }
};
