import React from "react";
import QuestionNote from "../DataObjects/QuestionNote";

export default class QuestionNotePresenter {
    protected note: QuestionNote;

    constructor(note: QuestionNote) {
        this.note = note;
    }

    getBody(): JSX.Element[] {
        const body = this.note.getBody();

        return body.split("\n\n").map((paragraph: string) => {
            const lines = paragraph
                .split("\n")
                .map((line: string): JSX.Element => (<>{line}<br/></>));

            return <p>{lines}</p>;
        });
    }
}
