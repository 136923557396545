import React, {Component} from 'react';
import {
    Button,
    Card, CardContent,
    CircularProgress,
    Container,
    Grid,
    List,
    Paper,
    Typography
} from "@material-ui/core";
import userContext from "../userContext";
import { RouteComponentProps} from "react-router-dom";
import routes, {makeUri} from "../routes";
import {ErrorOutline} from "@material-ui/icons";
import {apiGet, endpoints} from "../api";
import Submission from "../DataObjects/Submission";
import {withAxiosErrorChecks} from "../Hooks/useAxiosErrorChecks";
import {AxiosError} from "axios";
import CreateQuestionnaireModal from "./Modals/CreateQuestionnaireModal";
import {LoadingState} from "../Enums/LoadingState";
import SubmissionListItem from "./partials/SubmissionListItem";
import Version from "../DataObjects/Version";

interface Props {
    history: RouteComponentProps["history"],
    onError: (errors: string[]) => void,
    handleAxiosErrors?: (error: AxiosError) => void,
}

interface State {
    submissions: Submission[];
    loading: LoadingState;
    introModalOpen: boolean;
}

class Dashboard extends Component<Props, State> {
    static contextType = userContext;

    constructor(props: Props) {
        super(props);

        this.state = {
            submissions: [],
            loading: LoadingState.LOADING,
            introModalOpen: false,
        };

        this.onSubmissionCreate = this.onSubmissionCreate.bind(this);
    }

    async componentDidMount(): Promise<any> {
        let response;

        try {
            response = await apiGet(endpoints.SUBMISSIONS, {}, {with: 'Version'});
            this.setState({ loading: LoadingState.COMPLETE });
        } catch (error: any) {
            this.setState({ loading: LoadingState.FAILED });

            if (this.props.handleAxiosErrors) {
                this.props.handleAxiosErrors(error);
            }

            return;
        }

        this.setState({
            submissions: response.data.map((submissionData: { [key: string]: any }): Submission => {
                if (typeof submissionData.version !== 'undefined') {
                    submissionData.version = Version.make(submissionData.version);
                }

                return Submission.make(submissionData);
            }),
        });
    }

    removeItem(id: number): void {
        const submissions = this.state.submissions
            .filter((submission: Submission): boolean => submission.getId() !== id);

        this.setState({
            submissions: submissions,
        });
    }

    onSubmissionCreate(submissionId: number): void {
        this.props.history.push(
            makeUri(routes.QUESTIONNAIRE, {
                id: submissionId,
            }), {
                from: routes.HOME
            }
        );
    }

    renderListItems(): JSX.Element[] | string {
        if (this.state.submissions.length === 0) {
            return `There are no in progress submissions`;
        }

        return this.state.submissions
            .map((submission: Submission, index: number): JSX.Element => {
                return <SubmissionListItem
                    key={submission.getId()}
                    submission={submission}
                    onDelete={(submission: Submission): void => this.removeItem(submission.getId())}
                    onError={this.props.onError}
                />;
            });
    }

    render(): JSX.Element {
        return (
            <Container>
                <Paper elevation={7} style={{marginBottom: '55px', padding: '25px'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={9}>
                            <Typography variant="h2">Start A New Questionnaire</Typography>
                            <p>
                                PSA Certified Level 1 is a cybersecurity questionnaire that aims to ensure connected products are built using a security-by-design approach. There are 45 cybersecurity baseline questions split into three sections: chip (RoT), system software and device. The assessment is carried out through a questionnaire and laboratory review. An evaluation lab audits the responses and when satisfied that a “Pass” has been achieved sends the PSA Certified Level 1 application to the Certification Body for final review and awarding of the PSA Certified Level 1 certificate.
                            </p>
                            <p>
                                The online questionnaire guides you through the PSA Certified Level 1 process and results in a draft or final version of the questionnaire that can be shared with a PSA Certified evaluation laboratory.
                            </p>
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'center', 'margin': 'auto'}}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="medium"
                                onClick={(): void => this.setState({introModalOpen: true})}
                            >
                                New Questionnaire
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Your Questionnaires</Typography>
                                <List component="nav" aria-label="main mailbox folders">
                                    { this.state.loading === LoadingState.LOADING &&
                                        <div style={{ display: 'grid', placeContent: 'center' }}>
                                            <CircularProgress color="inherit" />
                                        </div>
                                    }
                                    { this.state.loading === LoadingState.FAILED &&
                                        <div style={{ display: 'grid', placeContent: 'center' }}>
                                            <ErrorOutline color="error" style={{ fontSize: 50 }} />
                                        </div>
                                    }
                                    { this.state.loading === LoadingState.COMPLETE && this.renderListItems() }
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <CreateQuestionnaireModal
                    open={this.state.introModalOpen}
                    onClose={(): void => this.setState({introModalOpen: false})}
                    onError={this.props.onError}
                    onSuccess={this.onSubmissionCreate}
                />
            </Container>
        );
    }
}

export default withAxiosErrorChecks(Dashboard);
