import Submission, {SubmissionType} from "../DataObjects/Submission";
import {InvalidSubmissionTypeError} from "../Errors/InvalidSubmissionTypeError";

export default class SubmissionPresenter {
    protected submission: Submission;

    constructor(submission: Submission) {
        this.submission = submission;
    }

    getType(): string {
        const type = this.submission.getType();

        switch (type) {
            case SubmissionType.Device:
                return 'Device';
            case SubmissionType.Software:
                return 'Software';
            case SubmissionType.Chip:
                return 'Chip';
            default:
                throw new InvalidSubmissionTypeError(type);
        }
    }

    getName(): string {
        return this.submission.hasName() ? this.submission.getName() : 'Untitled Questionnaire ' + this.submission.getId();
    }
}
