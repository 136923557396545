import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";
import QuestionNotePresenter from "../Presenters/QuestionNotePresenter";

interface FactoryProps {
    body?: string;
}

export default class QuestionNote {
    protected presenter: QuestionNotePresenter;
    protected body?: string;

    constructor() {
        this.presenter = new QuestionNotePresenter(this);
    }

    present(): QuestionNotePresenter {
        return this.presenter;
    }

    static make(noteData: FactoryProps): QuestionNote {
        const note = new this();

        if (typeof noteData.body !== 'undefined') {
            note.body = noteData.body;
        }

        return note;
    }

    getBody(): string {
        if (typeof this.body === 'undefined') {
            throw new FieldNotDefinedError('body');
        }

        return this.body;
    }
}
