import QuestionExample from "./QuestionExample";
import QuestionNote from "./QuestionNote";
import QuestionCertification from "./QuestionCertification";
import QuestionnaireItemInterface from "./QuestionnaireItemInterface";
import Section from "./Section";
import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";
import QuestionPresenter from "../Presenters/QuestionPresenter";
import Answer from "./Answer";
import {getIntEnv} from "../Config/ConfigUtils";

export enum QuestionType {
    Multi = 'MULTI',
    Text = 'TEXT',
}

interface FactoryProps {
    id?: number;
    number?: string;
    name?: string | null;
    body?: string;
    type?: QuestionType;
    sort?: number;
    notes?: QuestionNote[];
    examples?: QuestionExample[];
    certifications?: QuestionCertification[];
    section?: Section;
    answer?: Answer;
}

export default class Question implements QuestionnaireItemInterface {
    protected presenter: QuestionPresenter;
    protected id?: number;
    protected number?: string;
    protected name?: string | null;
    protected body?: string;
    protected type?: QuestionType;
    protected sort?: number;
    protected notes?: QuestionNote[];
    protected examples?: QuestionExample[];
    protected certifications?: QuestionCertification[];
    protected section?: Section;
    protected answer?: Answer;

    constructor() {
        this.presenter = new QuestionPresenter(this);
    }

    present(): QuestionPresenter {
        return this.presenter;
    }

    static make(questionData: FactoryProps): Question {
        const question = new this();

        question.id = questionData.id;
        question.number = questionData.number;
        question.name = questionData.name;
        question.body = questionData.body;
        question.type = questionData.type;
        question.sort = questionData.sort;
        question.notes = questionData.notes;
        question.examples = questionData.examples;
        question.certifications = questionData.certifications;
        question.section = questionData.section;
        question.answer = questionData.answer;

        return question;
    }

    getId(): number {
        if (typeof this.id === 'undefined') {
            throw new FieldNotDefinedError('id');
        }

        return this.id;
    }

    getNumber(): string {
        if (typeof this.number === 'undefined') {
            throw new FieldNotDefinedError('number');
        }

        return this.number;
    }

    getName(): string | null {
        if (typeof this.name === 'undefined') {
            throw new FieldNotDefinedError('name');
        }

        return this.name;
    }

    getBody(): string {
        if (typeof this.body === 'undefined') {
            throw new FieldNotDefinedError('body');
        }

        return this.body;
    }

    getType(): QuestionType {
        if (typeof this.type === 'undefined') {
            throw new FieldNotDefinedError('type');
        }

        return this.type;
    }

    getSort(): number {
        if (typeof this.sort === 'undefined') {
            throw new FieldNotDefinedError('sort');
        }

        return this.sort;
    }

    getNotes(): QuestionNote[] {
        if (typeof this.notes === 'undefined') {
            throw new FieldNotDefinedError('notes');
        }

        return this.notes;
    }

    getExamples(): QuestionExample[] {
        if (typeof this.examples === 'undefined') {
            throw new FieldNotDefinedError('examples');
        }

        return this.examples;
    }

    getCertifications(): QuestionCertification[] {
        if (typeof this.certifications === 'undefined') {
            throw new FieldNotDefinedError('certifications');
        }

        return this.certifications;
    }

    hasCertifications(): boolean {
        return typeof this.certifications !== 'undefined';
    }

    getSection(): Section {
        if (typeof this.section === 'undefined') {
            throw new FieldNotDefinedError('section');
        }

        return this.section;
    }

    hasAnswer(): boolean {
        return typeof this.answer !== 'undefined';
    }

    getAnswer(): Answer {
        if (typeof this.answer === 'undefined') {
            throw new FieldNotDefinedError('answer');
        }

        return this.answer;
    }

    isRequiredForPsaL1(): boolean {
        return this
            .getCertifications()
            .some((cert: QuestionCertification): boolean => cert.getCertId() === getIntEnv('REACT_APP_CERTIFICATION_PSA_L1'));
    }
}
