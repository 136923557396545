import QuestionGroup from "../DataObjects/IntroQuestionGroup";
import {getStringEnv} from "../Config/ConfigUtils";
import {SectionType} from "../DataObjects/Section";

export default class IntroQuestionGroupPresenter {
    protected questionGroup: QuestionGroup;

    constructor(questionGroup: QuestionGroup) {
        this.questionGroup = questionGroup;
    }

    getTitle(): string {
        if (this.questionGroup.getFields()[0].getNumber() === getStringEnv('REACT_APP_VERSION_2.1_QUESTION_CHIP_ROT_NUMBER')) {
            return 'PSA Root of Trust (PSA-RoT) Implementation';
        }

        if (this.questionGroup.getSection().getType() === SectionType.Intro) {
            return 'Assessment Information';
        }

        return `${this.questionGroup.getSection().present().getName()} Assessment Information`;
    }

    getShortTitle(): string {
        if (this.questionGroup.getFields()[0].getNumber() === getStringEnv('REACT_APP_VERSION_2.1_QUESTION_CHIP_ROT_NUMBER')) {
            return 'PSA Root of Trust';
        }

        if (this.questionGroup.getSection().getType() === SectionType.Intro) {
            return 'Assessment Information';
        }

        return `${this.questionGroup.getSection().present().getName()} Assessment Information`;
    }
}
