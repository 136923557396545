import React, {ChangeEvent, Component, FocusEvent, FormEvent} from 'react';
import {
    Button,
    CircularProgress,
    Container,
    TextField,
    Typography
} from "@material-ui/core";
import {apiPost, endpoints} from "../../api";
import {Link, Redirect} from "react-router-dom";
import routes from "../../routes";
import zxcvbn from "zxcvbn";
import {withAxiosErrorChecks} from "../../Hooks/useAxiosErrorChecks";
import {AxiosError} from "axios";

interface Props {
    onError: (errors: string[]) => void,
    onSuccess: (message: string) => void,
    token?: string,
    handleAxiosErrors?: (error: AxiosError) => void,
}

interface Errors {
    email: string,
    password: string,
    confirmPassword: string,
}

interface State {
    email: string,
    password: string,
    confirmPassword: string,
    errors: Errors,
    submitLoading: boolean,
    submitted: boolean,
}

class ResetPassword extends Component<Props, State> {
    private confirmPasswordRef: React.RefObject<HTMLInputElement>;
    constructor(props: Props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            errors: {
                email: '',
                password: '',
                confirmPassword: '',
            },
            submitLoading: false,
            submitted: false,
        };

        this.confirmPasswordRef = React.createRef();

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onFieldBlur = this.onFieldBlur.bind(this);
        this.onFieldInvalid = this.onFieldInvalid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();

        this.setState({submitLoading: true});

        try {
            await apiPost(endpoints.RESET_PASSWORD, undefined, {
                'email': this.state.email,
                'password': this.state.password,
                'password_confirmation': this.state.confirmPassword,
                'token': this.props.token,
            });
        } catch (error: any) {
            if (this.props.handleAxiosErrors) {
                this.props.handleAxiosErrors(error);
            }
            return;
        } finally {
            this.setState({submitLoading: false});
        }

        this.props.onSuccess('Password reset');

        this.setState({submitted: true});
    }

    onFieldChange(e: ChangeEvent<HTMLInputElement>): void {
        const element = e.currentTarget;
        const newState: State = {
            ...this.state
        };

        switch (element.id) {
            case 'email':
                newState.email = element.value;
                newState.errors.email = '';
                break;
            case 'password':
                newState.password = element.value;
                newState.errors.password = '';
                newState.errors.confirmPassword = '';
                break;
            case 'confirmPassword':
                newState.confirmPassword = element.value;
                newState.errors.confirmPassword = '';
                break;
            default:
                throw new Error(`Unknown field: ${element.id}`);
        }

        this.setState(
            newState,
            () => {
                this.validateField(element);
            });
    }

    onFieldBlur(e: FocusEvent<HTMLInputElement>): void {
        this.validateField(e.target);
    }

    validateField(inputField: HTMLInputElement): void {
        if (inputField.id === 'password') {
            this.validatePasswordStrength(inputField);

            const confirmField = this.confirmPasswordRef.current?.querySelector('input[type=password]') as HTMLInputElement;
            if (confirmField && this.state.confirmPassword !== '') {
                this.validateField(confirmField);
            }
        }

        if (inputField.id === 'confirmPassword') {
            this.validatePasswordsMatch(inputField);
        }

        inputField.checkValidity();
    }

    validatePasswordStrength(inputField: HTMLInputElement): void {
        if (this.state.password === '') {
            inputField.setCustomValidity('');
            return;
        }

        const result = zxcvbn(this.state.password);
        if (result.score < 3) {
            let error = "Password too weak";
            error += (result.feedback.warning) ? ': ' + result.feedback.warning : '';
            inputField.setCustomValidity(error);
        } else {
            inputField.setCustomValidity('');
        }
    }

    validatePasswordsMatch(confirmField: HTMLInputElement): void {
        if (this.state.confirmPassword !== this.state.password) {
            confirmField.setCustomValidity("Passwords don't match");
        } else {
            confirmField.setCustomValidity('');
        }
    }

    onFieldInvalid(e: FormEvent<HTMLInputElement>): void {
        e.preventDefault();

        const errors = this.state.errors;

        // @ts-ignore
        errors[e.target.id] = e.target.validationMessage;

        this.setState(oldState => {
            return {
                ...oldState,
                errors: errors,
            };
        });
    }

    render(): JSX.Element {
        if (this.state.submitted === true) {
            return (
                <Redirect to={routes.LOGIN}/>
            );
        }

        const submitButton = (this.state.submitLoading) ? (
            <Button type="submit" variant="contained" color="secondary" size="medium" disabled>
                Updating..
                <CircularProgress size={20} color="inherit" style={{marginLeft: 10}}></CircularProgress>
            </Button>
        ) : (
            <Button type="submit" variant="contained" color="secondary" size="medium">
                Update
            </Button>
        );

        return (
            <Container maxWidth="sm">
                <form onSubmit={this.onSubmit}>
                    <Typography variant="h2">Reset Password</Typography>

                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        id="email"
                        label="Email Address"
                        type="email"
                        value={this.state.email}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        onInvalid={this.onFieldInvalid}
                        error={this.state.errors.email !== ''}
                        helperText={this.state.errors.email}
                    />

                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        id="password"
                        label="New Password"
                        type="password"
                        value={this.state.password}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        onInvalid={this.onFieldInvalid}
                        error={this.state.errors.password !== ''}
                        helperText={this.state.errors.password}
                    />

                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        id="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        ref={this.confirmPasswordRef}
                        value={this.state.confirmPassword}
                        onChange={this.onFieldChange}
                        onBlur={this.onFieldBlur}
                        onInvalid={this.onFieldInvalid}
                        error={this.state.errors.confirmPassword !== ''}
                        helperText={this.state.errors.confirmPassword}
                    />

                    { submitButton }
                </form>

                <p>
                    <Link to={routes.LOGIN}>Back to login</Link>
                </p>
            </Container>
        );
    }
}

export default withAxiosErrorChecks(ResetPassword);
