import QuestionnaireItemInterface from "./QuestionnaireItemInterface";
import Section from "./Section";
import SectionReviewPresenter from "../Presenters/SectionReviewPresenter";

export default class SectionReview implements QuestionnaireItemInterface {
    protected presenter: SectionReviewPresenter;
    protected section: Section;
    protected items: QuestionnaireItemInterface[];

    constructor(section: Section, items: QuestionnaireItemInterface[]) {
        this.section = section;
        this.items = items;
        this.presenter = new SectionReviewPresenter(this);
    }

    present(): SectionReviewPresenter {
        return this.presenter;
    }

    getSection(): Section {
        return this.section;
    }

    getItems(): QuestionnaireItemInterface[] {
        return this.items;
    }

    getSort(): number {
        return Infinity;
    }
}
