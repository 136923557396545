import React, {useContext, useEffect, useState} from 'react';
import {Button, CircularProgress, Container, Paper, Typography} from "@material-ui/core";
import {apiPost, endpoints} from "../../api";
import userContext from "../../userContext";
import {useHistory} from "react-router-dom";
import useAxiosErrorChecks from "../../Hooks/useAxiosErrorChecks";
import routes, {makeUri} from "../../routes";

interface Props {
    onError: (errors: string[]) => void,
    onSuccess: (message: string) => void,
    data?: string,
}

export default function VerifyEmail(props: Props): JSX.Element {
    const context = useContext(userContext);
    const {handleAxiosErrors} = useAxiosErrorChecks(props.onError);
    const [resendLoading, setResendLoading] = useState(false);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [expired, setExpired] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!props.data) {
            return;
        }

        verify(props.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function verify(data: string): Promise<void> {
        setVerifyLoading(true);

        try {
            await apiPost(endpoints.VERIFY_EMAIL, undefined, {
                data: data,
            });
        } catch (error: any) {
            if (
                error.response?.status === 422
                && error.response?.data?.expired === true
            ) {
                setExpired(true);
            }

            handleAxiosErrors(error);
            return;
        } finally {
            setVerifyLoading(false);
        }

        props.onSuccess('Email verified! Please log-in');
        context.logOut();
        history.push(makeUri(routes.LOGIN));
    }

    async function onResendClick(): Promise<void> {
        setResendLoading(true);

        try {
            await apiPost(endpoints.VERIFY_EMAIL_RESEND);
        } catch (error: any) {
            handleAxiosErrors(error);
            return;
        } finally {
            setResendLoading(false);
        }

        props.onSuccess('Email resent!');
    }

    if (verifyLoading) {
        return (
            <Container maxWidth="sm">
                Verifying email..
                <CircularProgress size={20} color="inherit" style={{marginLeft: 10}}></CircularProgress>
            </Container>
        );
    }

    return (
        <Container>
            <Paper elevation={7} style={{marginBottom: '55px', padding: '25px'}}>
                {(context.loggedIn()) && (
                    <>
                        <Typography variant="h2">Welcome {context.user?.firstName}</Typography>
                        <p>You are now logged in, however you will need to confirm your email address before continuing</p>
                    </>
                )}

                {(expired) ? (
                    <>
                        <Typography variant="h3">Confirm Email Link Expired</Typography>
                        <p>
                            The confirmation email used has expired. We have sent you a new one.
                        </p>
                    </>
                ) : (
                    <>
                        <Typography variant="h3">Please confirm Your Email Address</Typography>
                        <p>
                            A confirmation email has been sent to your email address.<br/>
                            Please click the link in the email to continue.
                        </p>
                    </>
                )}

                <Typography variant="h3">Not received the email?</Typography>
                <p>
                    If you have not received the confirmation email, you can try to send another:
                    <br/>
                    <br/>
                    {(resendLoading) ? (
                        <Button type="submit" variant="contained" color="secondary" size="medium" disabled>
                            Sending email..
                            <CircularProgress size={20} color="inherit" style={{marginLeft: 10}}></CircularProgress>
                        </Button>
                    ) : (
                        <Button variant="contained" color="secondary" size="medium" onClick={onResendClick}>
                            Re-send confirmation email
                        </Button>
                    )}
                </p>
            </Paper>
        </Container>
    );
}
