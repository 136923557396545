import Answer from "./Answer";
import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";

interface FactoryProps {
    response?: string;
}

export default class AnswerText extends Answer {
    protected response?: string;

    static make(data: FactoryProps): AnswerText {
        const answer = new this();

        answer.response = data.response;

        return answer;
    }

    getResponse(): string {
        if (typeof this.response === 'undefined') {
            throw new FieldNotDefinedError('response');
        }

        return this.response;
    }

    hasResponse(): boolean {
        return typeof this.response !== 'undefined';
    }

    toObject(): { [key: string]: any } {
        return {
            ...(this.hasResponse() && {response: this.getResponse()}),
        };
    }
}
