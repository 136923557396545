import {FieldNotDefinedError} from "../Errors/FieldNotDefinedError";
import SectionPresenter from "../Presenters/SectionPresenter";

export enum SectionType {
    Intro = 'INTRO',
    Chip = 'CHIP',
    Software = 'SOFTWARE',
    Device = 'DEVICE',
    BestPractice = 'BEST_PRACTICE',
    Finalise = 'FINALISE',
}

export enum AlreadyCertifiedState {
    NA,
    Undecided,
    Yes,
    No,
}

interface FactoryProps {
    id?: number;
    type?: SectionType;
    description?: string;
    sort?: number;
    alreadyCertifiedState?: AlreadyCertifiedState;
}

export default class Section {
    protected presenter: SectionPresenter;
    protected id?: number;
    protected type?: SectionType;
    protected description?: string;
    protected sort?: number;
    protected alreadyCertifiedState: AlreadyCertifiedState = AlreadyCertifiedState.NA;

    constructor() {
        this.presenter = new SectionPresenter(this);
    }

    present(): SectionPresenter {
        return this.presenter;
    }

    static make(sectionData: FactoryProps): Section {
        let section = new this();

        section.id = sectionData.id;
        section.type = (sectionData.type as SectionType);
        section.description = sectionData.description;
        section.sort = sectionData.sort;
        if (typeof sectionData.alreadyCertifiedState !== 'undefined') {
            section.alreadyCertifiedState = sectionData.alreadyCertifiedState;
        }

        return section;
    }

    getId(): number {
        if (typeof this.id === 'undefined') {
            throw new FieldNotDefinedError('id');
        }

        return this.id;
    }

    getType(): SectionType {
        if (typeof this.type === 'undefined') {
            throw new FieldNotDefinedError('type');
        }

        return this.type;
    }

    getDescription(): string {
        if (typeof this.description === 'undefined') {
            throw new FieldNotDefinedError('description');
        }

        return this.description;
    }

    getSort(): number {
        if (typeof this.sort === 'undefined') {
            throw new FieldNotDefinedError('sort');
        }

        return this.sort;
    }

    getAlreadyCertifiedState(): AlreadyCertifiedState {
        return this.alreadyCertifiedState;
    }
}
