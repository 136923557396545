import React, {ChangeEvent, Component, FormEvent, ReactNode} from 'react';
import {Button, Card, CardContent, CardHeader, Grid,} from "@material-ui/core";
import QuestionObj, {QuestionType} from '../../DataObjects/Question';
import IntroQuestionGroup from "../../DataObjects/IntroQuestionGroup";
import IntroQuestion from "./IntroQuestions/IntroQuestion";
import Submission from "../../DataObjects/Submission";
import {InvalidQuestionTypeError} from "../../Errors/InvalidQuestionTypeError";
import AnswerText from "../../DataObjects/AnswerText";
import {alreadyCertifiedFieldIds} from "../../Config/IntroFieldTypes";
import {YesNoChoice} from "../../DataObjects/YesNoChoice";
import Section, {AlreadyCertifiedState, SectionType} from "../../DataObjects/Section";
import QuestionnaireService from "../../Services/QuestionnaireService";
import Supplement from "./partials/Supplement";
import {getStringEnv} from "../../Config/ConfigUtils";

interface Props {
    questionGroup: IntroQuestionGroup;
    submission: Submission;
    firstQuestion?: boolean;
    onSave: (newQuestions: QuestionObj[]) => void;
    onNextClick: () => void;
    onPrevClick: () => void;
    onAlreadyCertifiedChange: (section: Section, alreadyCertified: boolean) => void;
}

interface State {
    fields: {[key: string]: string};
    dirty: boolean;
}

class IntroQuestions extends Component<Props, State> {
    private formRef: React.RefObject<HTMLFormElement>;
    private tabFocusRef: React.RefObject<HTMLInputElement>;
    private questionnaireService: QuestionnaireService;

    constructor(props: Props) {
        super(props);

        this.state = {
            fields: {},
            dirty: false,
        };

        this.formRef = React.createRef();
        this.tabFocusRef = React.createRef();

        this.questionnaireService = new QuestionnaireService();

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onPrevClick = this.onPrevClick.bind(this);
        this.onCloseApp = this.onCloseApp.bind(this);
    }

    componentDidMount(): void {
        this.initialiseState();
        if (this.tabFocusRef.current !== null) {
            this.tabFocusRef.current.focus();
        }
        window.addEventListener("beforeunload", this.onCloseApp);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.questionGroup.getFields()[0].getId() !== this.props.questionGroup.getFields()[0].getId()) {
            if (this.state.dirty) {
                this.save(prevProps.questionGroup.getFields(), prevState);
            }
            this.initialiseState();
            if (this.tabFocusRef.current !== null) {
                this.tabFocusRef.current.focus();
            }
        }
    }

    componentWillUnmount(): void {
        if (this.state.dirty) {
            this.save(this.props.questionGroup.getFields(), this.state);
        }
        window.removeEventListener("beforeunload", this.onCloseApp);
    }

    onCloseApp(e: Event): string {
        if (!this.state.dirty) {
            return '';
        }

        const message = 'Are you sure you want to leave with unsaved changes?';

        if (e) {
            e.returnValue = false;
        }

        return message;
    }

    initialiseState(): void {
        const fields: {[key: string]: string} = {};
        this.props.questionGroup.getFields().forEach((field: QuestionObj): void => {
            fields[field.getId().toString()] = (field.hasAnswer() && field.getAnswer().hasResponse()) ? field.getAnswer().getResponse() : '';
        });

        this.setState({
            fields: fields,
            dirty: false,
        });
    }

    onFieldChange(e: ChangeEvent<HTMLInputElement>): void {
        const id = e.currentTarget.id || e.currentTarget.name,
            value = e.currentTarget.value;

        this.setState(oldState => {
            return {
                fields: {
                    ...oldState.fields,
                    [id]: value,
                },
                dirty: true,
            };
        }, () => {
            if (alreadyCertifiedFieldIds.includes(parseInt(id))) {
                const section = this.props.questionGroup.getSection();
                this.props.onAlreadyCertifiedChange(section, value === YesNoChoice.Yes);
                const question = this.props.questionGroup.getFields()
                    .filter((question: QuestionObj): boolean => question.getId().toString() === id);
                this.save(question, this.state);
            }
        });
    }

    onSubmit(e: FormEvent): void {
        e.preventDefault();

        if (!this.passesValidation()) {
            return;
        }

        this.props.onNextClick();
    }

    getAnswerDataFromState(question: QuestionObj, state: State): AnswerText {
        return AnswerText.make({
            response: state.fields[question.getId().toString()]
        });
    }

    save(questions: QuestionObj[], state: State): void {
        const newQuestions = questions.map((question: QuestionObj): QuestionObj => {
            const newAnswer = this.getAnswerDataFromState(question, state);

            return QuestionObj.make({
                id: question.getId(),
                number: question.getNumber(),
                name: question.getName(),
                body: question.getBody(),
                type: question.getType(),
                sort: question.getSort(),
                notes: question.getNotes(),
                examples: question.getExamples(),
                certifications: question.getCertifications(),
                section: question.getSection(),
                answer: newAnswer,
            });
        });

        this.props.onSave(newQuestions);
    }

    passesValidation(): boolean {
        const form = this.formRef.current;

        if (form === null) {
            return false;
        }

        return form.checkValidity();
    }

    onNextClick(): void {
        if (!this.passesValidation()) {
            return;
        }

        this.props.onNextClick();
    }

    onPrevClick(): void {
        if (!this.passesValidation()) {
            return;
        }

        this.props.onPrevClick();
    }

    renderQuestions(): ReactNode {
        const fields = this.props.questionGroup
            .getFields()
            .filter((question: QuestionObj): boolean => this.questionnaireService.introFieldShouldShowBasedOnSection(question, this.props.questionGroup.getSection()));

        return fields.map((question: QuestionObj, index: number): ReactNode => {
            switch (question.getType()) {
                case QuestionType.Text:
                    return (
                        <IntroQuestion
                            key={index}
                            question={question}
                            id={question.getId().toString()}
                            value={this.state.fields[question.getId().toString()] ?? ''}
                            onChange={this.onFieldChange}
                            tabFocusRef={(index === 0) ? this.tabFocusRef : undefined}
                        />
                    );
                default:
                    throw new InvalidQuestionTypeError(question.getType());
            }
        });
    }

    render(): JSX.Element {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card raised={true} style={{marginTop: '10px'}}>
                        <CardHeader
                            title={`${this.props.questionGroup.present().getTitle()}`}
                            titleTypographyProps={{variant: 'h1'}}
                        />
                        <CardContent>
                            <form
                                style={{marginTop: '15px'}}
                                ref={this.formRef}
                                onSubmit={this.onSubmit}
                            >
                                {this.renderQuestions()}
                                <input type="submit" hidden aria-hidden="true"/>
                            </form>
                            <Grid container direction="row-reverse" spacing={3} style={{marginTop: '15px'}}>
                                <Grid item style={{flexGrow: 1}}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{float: 'right'}}
                                        onClick={this.onNextClick}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                                <Grid item style={{flexGrow: 1}}>
                                    <Button
                                        variant="contained"
                                        onClick={this.onPrevClick}
                                        disabled={this.props.firstQuestion === true}
                                    >
                                        Previous
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Supplement>
                    <p>These intro questions will be supplied along with the regular questions, and are required</p>

                    {
                        this.props.questionGroup.getSection().getType() === SectionType.Chip
                        && this.props.questionGroup.getFields()[0].getNumber() !== getStringEnv('REACT_APP_VERSION_2.1_QUESTION_CHIP_ROT_NUMBER')
                        && this.props.questionGroup.getSection().getAlreadyCertifiedState() === AlreadyCertifiedState.Yes
                        && (
                            <>
                                <p><strong>Response Guidance:</strong></p>
                                <p>
                                    The EAN-13 number can be found in the <a href="https://www.psacertified.org/certified-products" target="_blank" rel="noreferrer">Certified Products database</a>.
                                    Find the chip certificate within the database and enter the first 13 digits of the certificate number.
                                </p>
                            </>
                        )
                    }

                    {
                        this.props.questionGroup.getSection().getType() === SectionType.Chip
                        && this.props.questionGroup.getFields()[0].getNumber() !== getStringEnv('REACT_APP_VERSION_2.1_QUESTION_CHIP_ROT_NUMBER')
                        && this.props.questionGroup.getSection().getAlreadyCertifiedState() === AlreadyCertifiedState.No
                        && (
                            <>
                                <p><strong>Response Guidance:</strong></p>
                                <ul>
                                    <li>Commercial Name: Product family name</li>
                                    <li>Chip Part Number</li>
                                    <li>Chip Version: Chip silicon revision</li>
                                    <li>Secure Processing Environment (SPE) Name: example Firmware Framework-M or OP-TEE</li>
                                    <li>SPE Version</li>
                                    <li>Chip Reference Documentation: Identification of the reference documentation used to fill the questionnaire, such as chip datasheet, detailed fact sheet or reference manual. If this is unavailable online, please share with the evaluation upon completion of your questionnaire.</li>
                                    <li>Vulnerability Disclosure Policy URL: If a vulnerability disclosure policy is available for this pct, provide the URL it can be retrieved</li>
                                </ul>
                            </>
                        )
                    }

                    {
                        this.props.questionGroup.getSection().getType() === SectionType.Chip
                        && this.props.questionGroup.getFields()[0].getNumber() === getStringEnv('REACT_APP_VERSION_2.1_QUESTION_CHIP_ROT_NUMBER')
                        && (
                            <>
                                <p><strong>Response Guidance:</strong></p>
                                <ul>
                                    <li>
                                        PSA Functional API Certification Reports: Has the chip passed PSA Functional API Certification?
                                        If yes, please supply the log files to the evaluation lab.
                                    </li>
                                    <li>
                                        Isolation of the Secure Processing Environment (SPE) from the Non-secure Processing Environment (NSPE) is a mandatory PSA Certified requirement.
                                        The PSA Certified Security Model defines two incremental isolation boundaries. Please indicate if these are deployed.
                                    </li>
                                    <li>
                                        Root of Trust Services Implementation: Describe PSA-RoT services implementation.
                                    </li>
                                    <li>
                                        Trusted Subsystem: Describe any trusted subsystems relied upon for operation of PSA-RoT, such as a security subsystem or a Secure Element, and how they are used.
                                        Declare ‘non’ if no trusted subsystems are used.
                                    </li>
                                    <li>
                                        Entropy Source: List any applied random number specification or conformance tests of the entropy source.
                                        This information will be included in the certificate.
                                    </li>
                                </ul>
                            </>
                        )
                    }

                    {
                        this.props.questionGroup.getSection().getType() === SectionType.Software
                        && this.props.questionGroup.getSection().getAlreadyCertifiedState() === AlreadyCertifiedState.Yes
                        && (
                            <>
                                <p><strong>Response Guidance:</strong></p>
                                <p>
                                    The EAN-13 number can be found in the <a href="https://www.psacertified.org/certified-products" target="_blank" rel="noreferrer">Certified Products database</a>.
                                    Find the chip certificate within the database and enter the first 13 digits of the certificate number.
                                </p>
                            </>
                        )
                    }

                    {
                        this.props.questionGroup.getSection().getType() === SectionType.Software
                        && this.props.questionGroup.getSection().getAlreadyCertifiedState() === AlreadyCertifiedState.No
                        && (
                            <>
                                <p><strong>Response Guidance:</strong></p>
                                <ul>
                                    <li>System Software Name: Commercial name</li>
                                    <li>System Software Version: The version number or an identifier for the build of the system software</li>
                                    <li>System Software Use of Chip Security Features: Indicate what use, if any, is made of chip-level security functionality in addition to that required for the PSA-RoT</li>
                                    <li>System Software Reference Documentation: Provide a URL to the reference documentation used to fill the system software questionnaire. This may be requested by the evaluation laboratory</li>
                                </ul>
                            </>
                        )
                    }

                    {
                        this.props.questionGroup.getSection().getType() === SectionType.Device
                        && (
                            <>
                                <p><strong>Response Guidance:</strong></p>

                                <ul>
                                    <li>Expected Usage: Please provide an explanation or example</li>
                                    <li>Features: Describe the functional and security features marketed for the product</li>
                                    <li>Expected Operational Environment: Describe any actors and external resources required for operation of the product, and the related security assumptions</li>
                                </ul>
                            </>
                        )
                    }
                </Supplement>
            </Grid>
        );
    }
}

export default IntroQuestions;
