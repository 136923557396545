import React, {useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Icon,
    IconButton
} from "@material-ui/core";
import {apiDelete, endpoints} from "../../../api";
import useAxiosErrorChecks from "../../../Hooks/useAxiosErrorChecks";
import {AxiosError} from "axios";
import {DeleteOutline, ErrorOutline} from "@material-ui/icons";

interface Props {
    id: number,
    onError: (errors: string[]) => void,
    onSuccess: () => void,
    handleAxiosErrors?: (error: AxiosError) => void,
}

const DestroyQuestionnaireButton = function(props: Props): JSX.Element {
    const [loading, setLoading] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const {handleAxiosErrors} = useAxiosErrorChecks(props.onError);

    async function destroySubmission(): Promise<void> {
        setLoading(true);
        setFailed(false);

        try {
            await apiDelete(endpoints.SUBMISSION_DESTROY, {id: props.id});
        } catch (error: any) {
            setFailed(true);
            handleAxiosErrors(error);
            return;
        } finally {
            setLoading(false);
        }

        props.onSuccess();
    }

    function showModal(): JSX.Element {
        return <Dialog
            open={confirmModalOpen}
            onClose={(): void => setConfirmModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Permanently Delete Questionnaire"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you wish to delete this questionnaire. This is permanent and can not be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(): void => setConfirmModalOpen(false)} color="secondary">
                    Cancel
                </Button>
                <Button onClick={(): void => {setConfirmModalOpen(false); destroySubmission();}} color="secondary" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>;
    }

    if (loading) {
        return <CircularProgress size={20} color="inherit" style={{marginLeft: 10}}></CircularProgress>;
    }

    return (
        <div>
            <IconButton
                edge="end"
                aria-label="delete"
                onClick={(): void => setConfirmModalOpen(true)}
                disabled={loading}
            >

                { failed ?  <Icon color={'error'}><ErrorOutline/></Icon> : <DeleteOutline/>}
            </IconButton>
            { confirmModalOpen && showModal() }
        </div>
    );
};

export default DestroyQuestionnaireButton;
